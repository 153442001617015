"use strict";

function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _sortBy2 = _interopRequireDefault(require("ramda/src/sortBy"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _mergeRight2 = _interopRequireDefault(require("ramda/src/mergeRight"));
var _toPairs2 = _interopRequireDefault(require("ramda/src/toPairs"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _range2 = _interopRequireDefault(require("ramda/src/range"));
var _reduce2 = _interopRequireDefault(require("ramda/src/reduce"));
var _evolve2 = _interopRequireDefault(require("ramda/src/evolve"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _is2 = _interopRequireDefault(require("ramda/src/is"));
var _toString2 = _interopRequireDefault(require("ramda/src/toString"));
var _unless2 = _interopRequireDefault(require("ramda/src/unless"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : String(i);
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var getBucketsPropFrom = function getBucketsPropFrom(propName) {
  return (0, _pathOr2["default"])([], [propName, 'buckets']);
};
var propAsString = function propAsString(propName) {
  return (0, _pipe2["default"])((0, _prop2["default"])(propName), (0, _unless2["default"])((0, _is2["default"])(String), _toString2["default"]));
};
var arrayToObjects = function arrayToObjects(spec) {
  return (0, _map2["default"])((0, _applySpec2["default"])(spec));
};
var formatBuckets = arrayToObjects({
  title: propAsString('key'),
  value: (0, _prop2["default"])('doc_count')
});
var getMetrics = (0, _pipe2["default"])((0, _prop2["default"])('aggregations'), getBucketsPropFrom('metrics'), _head2["default"]);
var sumPaidTransactions = (0, _pipe2["default"])(getMetrics, (0, _pathOr2["default"])(0, ['paidTransactions', 'value']));
var getTotalTransactions = (0, _path2["default"])(['hits', 'total']);
var getLocalizedWeekdayName = function getLocalizedWeekdayName(date) {
  return date.format('ddd');
};
var getNamedWeekdays = (0, _map2["default"])((0, _evolve2["default"])({
  key: function key(weekdayNumber) {
    return getLocalizedWeekdayName((0, _momentTimezone["default"])().day(weekdayNumber));
  }
}));
var defaultWeekdays = (0, _reduce2["default"])(function (acc, weekdayNumber) {
  return _extends({}, acc, _defineProperty({}, weekdayNumber, 0));
}, {}, (0, _range2["default"])(0, 7));
var aggregateWeekdaysVolume = (0, _reduce2["default"])(function (acc, _ref) {
  var volume = _ref.doc_count,
    key = _ref.key;
  return _extends({}, acc, _defineProperty({}, key, (0, _propOr2["default"])(0, key, acc) + volume));
}, {});
var getWeekdayNumberFromTimestamp = (0, _pipe2["default"])(_momentTimezone["default"].utc, function (date) {
  return date.day();
});
var getNumberedWeekdays = arrayToObjects({
  key: (0, _pipe2["default"])((0, _prop2["default"])('key'), getWeekdayNumberFromTimestamp),
  doc_count: (0, _path2["default"])(['volume', 'value'])
});
var buildWeekdays = (0, _pipe2["default"])(getNumberedWeekdays, aggregateWeekdaysVolume, (0, _mergeRight2["default"])(defaultWeekdays), _toPairs2["default"], arrayToObjects({
  doc_count: _last2["default"],
  key: _head2["default"]
}), (0, _sortBy2["default"])((0, _prop2["default"])('key')), getNamedWeekdays, formatBuckets);
var getVolumeByWeekday = (0, _pipe2["default"])((0, _path2["default"])(['aggregations', 'weekdayVolume']), getBucketsPropFrom('weekdays'), buildWeekdays);
var getAverageAmount = (0, _pipe2["default"])(getMetrics, (0, _pathOr2["default"])(0, ['averageAmount', 'value']), Math.round);
var getInstallments = (0, _pipe2["default"])(getMetrics, getBucketsPropFrom('creditCardTransactions'), _head2["default"], getBucketsPropFrom('installments'), (0, _sortBy2["default"])((0, _prop2["default"])('key')), formatBuckets);
var buildBucketsFrom = function buildBucketsFrom(propName) {
  return (0, _pipe2["default"])(getBucketsPropFrom(propName), formatBuckets);
};
var buildAggregationBuckets = function buildAggregationBuckets(propName) {
  return (0, _pipe2["default"])(getMetrics, buildBucketsFrom(propName));
};
var buildResult = (0, _applySpec2["default"])({
  averageAmount: getAverageAmount,
  cardBrands: buildAggregationBuckets('card_brand'),
  installments: getInstallments,
  paymentMethods: buildAggregationBuckets('payment_method'),
  refuseReasons: buildAggregationBuckets('refuse_reason'),
  status: buildAggregationBuckets('status'),
  totalAmount: sumPaidTransactions,
  totalTransactions: getTotalTransactions,
  volumeByWeekday: getVolumeByWeekday
});
var _default = exports["default"] = buildResult;
module.exports = exports["default"];