"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var acquirerNames = {
  cielo: 'Cielo',
  "default": '-',
  development: 'Ambiente de Teste',
  mundipagg: 'Pagar.me',
  pagarme: 'Pagar.me',
  rede: 'Rede',
  stone: 'Stone'
};
var _default = exports["default"] = acquirerNames;
module.exports = exports["default"];