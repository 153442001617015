"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatAnticipationData = formatAnticipationData;
exports.formatBankAccountData = formatBankAccountData;
exports.formatHeaderData = formatHeaderData;
exports.formatTransferData = formatTransferData;
function formatHeaderData(data) {
  var headerData = {
    createDate: data.date_created,
    hash: data.id,
    id: data.bank_account.id.toString(),
    name: data.bank_account.legal_name,
    status: data.status
  };
  var register = data.register_information;
  if (register) {
    if (register.type === 'individual') {
      headerData.name = register.name;
    }
    if (register.type === 'corporation') {
      headerData.name = register.company_name;
    }
  }
  return headerData;
}
function formatAnticipationData(data) {
  var anticipationDays = Array.isArray(data.automatic_anticipation_days) ? data.automatic_anticipation_days : JSON.parse(data.automatic_anticipation_days || '[]');
  var anticipation = {
    anticipationDays: anticipationDays,
    anticipationModel: '',
    anticipationVolumePercentage: data.anticipatable_volume_percentage.toString(),
    anticipationDelay: data.automatic_anticipation_1025_delay.toString() || ''
  };
  if (data.automatic_anticipation_enabled === false) {
    anticipation.anticipationModel = 'manual';
  } else if (data.automatic_anticipation_type === 'full' && data.automatic_anticipation_enabled) {
    anticipation.anticipationModel = 'automatic_volume';
  } else if (data.automatic_anticipation_type === '1025' && data.automatic_anticipation_1025_delay === 15 && anticipation.anticipationDays.length === 2) {
    anticipation.anticipationModel = 'automatic_1025';
  } else if (data.automatic_anticipation_type === '1025' && data.automatic_anticipation_enabled && anticipation.anticipationDays.length === 31) {
    anticipation.anticipationModel = 'automatic_dx';
  } else {
    anticipation.anticipationModel = 'custom';
  }
  anticipation.anticipationDays = anticipation.anticipationDays.join(',');
  return anticipation;
}
function formatTransferData(data) {
  var transfer = {
    transferDay: data.transfer_day.toString(),
    transferEnabled: data.transfer_enabled,
    transferInterval: data.transfer_interval
  };
  if (data.transfer_interval === 'daily') {
    transfer.transferDay = '0';
  }
  if (data.transfer_interval === 'weekly') {
    transfer.transferDay = data.transfer_day.toString();
  }
  return transfer;
}
function formatBankAccountData(data) {
  var accountFormat = {
    agency: data.agencia,
    bank: data.bank_code,
    id: data.id.toString(),
    name: data.legal_name,
    number: data.conta,
    type: data.type
  };
  return accountFormat;
}