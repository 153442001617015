"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _pick2 = _interopRequireDefault(require("ramda/src/pick"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _values2 = _interopRequireDefault(require("ramda/src/values"));
var _pluck2 = _interopRequireDefault(require("ramda/src/pluck"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _props2 = _interopRequireDefault(require("ramda/src/props"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _append2 = _interopRequireDefault(require("ramda/src/append"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _splitAt2 = _interopRequireDefault(require("ramda/src/splitAt"));
var _join2 = _interopRequireDefault(require("ramda/src/join"));
var _length2 = _interopRequireDefault(require("ramda/src/length"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _statusName = _interopRequireDefault(require("../../utils/statusName"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var LIMITER = '-';
var isEmptyOrNill = (0, _either2["default"])(_isNil2["default"], _isEmpty2["default"]);
var formatPhoneNumber = function formatPhoneNumber(number) {
  if (!number) return '';
  var len = (0, _length2["default"])(number) - 4;
  return (0, _join2["default"])('-', (0, _splitAt2["default"])(len, number));
};
var getStatusName = (0, _pipe2["default"])((0, _prop2["default"])('status'), (0, _prop2["default"])(_2["default"], _statusName["default"]));
var isStatusRefused = (0, _propEq2["default"])('status', 'refused');
var getRefuseReason = (0, _pipe2["default"])((0, _prop2["default"])('status_reason'), (0, _append2["default"])(_2["default"], ['refused']), (0, _path2["default"])(_2["default"], _statusName["default"]));
var getStatus = (0, _ifElse2["default"])(isStatusRefused, getRefuseReason, getStatusName);
var formatPhone = (0, _pipe2["default"])((0, _props2["default"])(['ddd', 'number']), (0, _juxt2["default"])([(0, _pipe2["default"])(_head2["default"], function (ddd) {
  return "(".concat(ddd, ")");
}), (0, _pipe2["default"])(_last2["default"], formatPhoneNumber)]), (0, _join2["default"])(' '));
var getPhone = (0, _pipe2["default"])((0, _prop2["default"])('phone'), (0, _ifElse2["default"])(isEmptyOrNill, (0, _always2["default"])(LIMITER), formatPhone));
var formatDate = function formatDate(date) {
  return (0, _momentTimezone["default"])(date).format('DD/MM/YYYY HH:mm');
};
var getCreatedDate = (0, _pipe2["default"])((0, _prop2["default"])('date_created'), formatDate);
var getUpdateData = (0, _pipe2["default"])((0, _prop2["default"])('date_updated'), formatDate);
var paymentMethodNames = {
  boleto: 'Boleto',
  credit_card: 'Cartão de Crédito'
};
var getPaymentMethod = (0, _pipe2["default"])((0, _prop2["default"])('payment_method'), (0, _prop2["default"])(_2["default"], paymentMethodNames));
var getCurrentPeriodStart = (0, _pipe2["default"])((0, _prop2["default"])('current_period_start'), formatDate);
var getCurrentPeriodEnd = (0, _pipe2["default"])((0, _prop2["default"])('current_period_end'), formatDate);
var exportSubscriptionSpec = {
  id: (0, _prop2["default"])('id'),
  status: getStatus,
  plan_name: (0, _path2["default"])(['plan', 'name']),
  date_created: getCreatedDate,
  date_updated: getUpdateData,
  payment_method: getPaymentMethod,
  current_period_start: getCurrentPeriodStart,
  current_period_end: getCurrentPeriodEnd,
  customer_email: (0, _pathOr2["default"])(null, ['customer', 'email']),
  customer_name: (0, _pathOr2["default"])(null, ['customer', 'name']),
  customer_document_number: (0, _pathOr2["default"])(null, ['customer', 'document_number']),
  phone: getPhone,
  address: (0, _pathOr2["default"])(null, ['address', 'street']),
  address_street_number: (0, _pathOr2["default"])(null, ['address', 'street_number']),
  address_complementary: (0, _pathOr2["default"])(null, ['address', 'complementary']),
  address_neighborhood: (0, _pathOr2["default"])(null, ['address', 'neighborhood']),
  address_city: (0, _pathOr2["default"])(null, ['address', 'city']),
  address_state: (0, _pathOr2["default"])(null, ['address', 'state']),
  address_country: (0, _pathOr2["default"])(null, ['address', 'country']),
  address_zipcode: (0, _pathOr2["default"])(null, ['address', 'zipcode'])
};
var getKeys = (0, _pluck2["default"])('key');
var getValues = (0, _pluck2["default"])('value');
var exportKeysCSV = function exportKeysCSV(columns) {
  return getValues(columns).map(function (value) {
    return "\"".concat(value, "\"");
  }).join(',');
};
var format = function format(exportType) {
  return function (exportData) {
    if (exportType === 'csv') {
      return (0, _values2["default"])(exportData).map(function (value) {
        return "\"".concat(value, "\"");
      }).join(',');
    }
    return (0, _values2["default"])(exportData);
  };
};
var filterProps = function filterProps(columns) {
  return (0, _pipe2["default"])((0, _applySpec2["default"])(exportSubscriptionSpec), (0, _pick2["default"])(getKeys(columns)));
};
var formatLines = function formatLines(exportType, columns) {
  return (0, _map2["default"])((0, _pipe2["default"])((0, _prop2["default"])('_source'), filterProps(columns), format(exportType)));
};
var mountHeaderWithLimiter = function mountHeaderWithLimiter(headers, limiter) {
  var message = ["\"# Exibindo apenas os primeiros ".concat(limiter.limit, " resultados de um total de ").concat(limiter.total, " encontrado\"")];
  var limit = limiter.limit,
    total = limiter.total;
  if (total > limit) {
    return [headers].concat([message]);
  }
  return [headers];
};
var buildData = function buildData(exportType, columns, limiter) {
  return function (exportData) {
    if (exportType === 'csv') {
      var _header = exportKeysCSV(columns);
      var _lines = formatLines(exportType, columns);
      var csvFile = mountHeaderWithLimiter(_header, limiter);
      return csvFile.concat(_lines(exportData)).join('\r\n');
    }
    var lines = formatLines(exportType, columns);
    var header = getValues(columns);
    var file = mountHeaderWithLimiter(header, limiter);
    return file.concat(lines(exportData));
  };
};
var buildResult = function buildResult(exportType, columns, limiter) {
  return (0, _pipe2["default"])((0, _path2["default"])(['hits', 'hits']), buildData(exportType, columns, limiter));
};
var _default = exports["default"] = buildResult;
module.exports = exports["default"];