"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var _default = exports["default"] = (0, _applySpec2["default"])({
  name: (0, _propOr2["default"])('', 'name'),
  phoneNumber: (0, _propOr2["default"])('', 'phone_number'),
  siteUrl: (0, _propOr2["default"])('', 'site_url'),
  statementDescriptor: (0, _propOr2["default"])('', 'statement_descriptor'),
  fullName: (0, _propOr2["default"])('', 'full_name'),
  cnpj: (0, _propOr2["default"])('', 'cnpj'),
  max_chargeback_index: (0, _propOr2["default"])(2, 'max_chargeback_index')
});
module.exports = exports["default"];