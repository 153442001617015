import getApiResponseStatus from './getApiResponseStatus'

const getStatusAndReason = (kycs, status) => {
  const kyc = kycs ? kycs.filter(item => item.kyc_status === status) : []

  if (kyc[0]) {
    const statusObject = {
      count: kyc.length,
      status: kyc[0].kyc_status,
      statusReason: kyc[0].kyc_status_reason,
    }

    return statusObject
  }

  return null
}

const getKycStatus = (kycs, error) => {
  const errorStatus = getApiResponseStatus(error)
  const hasAKnownFreePassError = [404, 403].includes(errorStatus)
  const hasAnUnknownError = !!errorStatus && !hasAKnownFreePassError

  if (hasAnUnknownError) {
    return {
      status: 'denied',
      statusReason: 'fully_denied',
    }
  }

  if (!kycs || kycs?.length === 0 || hasAKnownFreePassError) {
    return {
      status: 'approved',
      statusReason: 'ok',
    }
  }

  const denied = getStatusAndReason(kycs, 'denied')
  const deniedByRisk = getStatusAndReason(kycs, 'denied_by_risk')
  const partiallyDenied = getStatusAndReason(kycs, 'partially_denied')
  const pending = getStatusAndReason(kycs, 'pending')
  const approved = getStatusAndReason(kycs, 'approved')

  const isApproved = approved ? approved.count >= 2 : false
  const notApprovedStatus = pending || partiallyDenied || deniedByRisk || denied

  return isApproved ? approved : notApprovedStatus
}

export default getKycStatus
