"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _concat2 = _interopRequireDefault(require("ramda/src/concat"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var momentToUnixTimestamp = function momentToUnixTimestamp(momentDate) {
  return momentDate.format('x');
};
var buildQuery = (0, _pipe2["default"])((0, _ifElse2["default"])(_isNil2["default"], (0, _always2["default"])({}), (0, _applySpec2["default"])({
  payment_date: (0, _juxt2["default"])([(0, _pipe2["default"])((0, _path2["default"])(['dates', 'end']), (0, _ifElse2["default"])(_isNil2["default"], (0, _always2["default"])(undefined), (0, _pipe2["default"])(momentToUnixTimestamp, (0, _concat2["default"])('<')))), (0, _pipe2["default"])((0, _path2["default"])(['dates', 'start']), (0, _ifElse2["default"])(_isNil2["default"], (0, _always2["default"])(undefined), (0, _pipe2["default"])(momentToUnixTimestamp, (0, _concat2["default"])('>'))))]),
  count: (0, _prop2["default"])('numberOfItemsPerPage'),
  id: (0, _ifElse2["default"])((0, _propEq2["default"])('search', ''), (0, _always2["default"])(undefined), (0, _prop2["default"])('search')),
  page: (0, _prop2["default"])('selectedPageNumber'),
  recipientId: (0, _prop2["default"])('recipientId')
})));
var _default = exports["default"] = buildQuery;
module.exports = exports["default"];