"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setInitialFlags = exports.groupRetries = exports.getMasterKey = exports["default"] = void 0;
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _T2 = _interopRequireDefault(require("ramda/src/T"));
var _hasPath2 = _interopRequireDefault(require("ramda/src/hasPath"));
var _cond2 = _interopRequireDefault(require("ramda/src/cond"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _reduce2 = _interopRequireDefault(require("ramda/src/reduce"));
var _sort2 = _interopRequireDefault(require("ramda/src/sort"));
var _assoc2 = _interopRequireDefault(require("ramda/src/assoc"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _anyPass2 = _interopRequireDefault(require("ramda/src/anyPass"));
var _pluck2 = _interopRequireDefault(require("ramda/src/pluck"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
var getTransactions = (0, _pipe2["default"])((0, _path2["default"])(['hits', 'hits']), (0, _pluck2["default"])('_source'));
var hasTransactionBeenPaid = (0, _anyPass2["default"])([(0, _propEq2["default"])('status', 'paid'), (0, _propEq2["default"])('status', 'refunded'), (0, _propEq2["default"])('status', 'chargedback'), (0, _propEq2["default"])('status', 'authorized')]);
var setInitialFlags = exports.setInitialFlags = (0, _ifElse2["default"])(hasTransactionBeenPaid, (0, _assoc2["default"])('conversion', 'success'), (0, _assoc2["default"])('conversion', 'fail'));
var flagLatestRetry = function flagLatestRetry(transactions, current) {
  if (transactions.length === 0) {
    return [current];
  }
  var previous = transactions[transactions.length - 1];
  var isSameDay = (0, _moment["default"])(current.date_created).isSame((0, _moment["default"])(previous.date_created), 'day');
  var isRetry = previous.masterKey === current.masterKey && isSameDay;
  if (isRetry) {
    if (previous.conversion !== current.conversion && current.conversion === 'success') {
      return [].concat(_toConsumableArray(transactions.slice(0, transactions.length - 1)), [_extends({}, previous, {
        conversion: 'success'
      })]);
    }
    if (previous.conversion !== current.conversion && current.conversion === 'fail' || previous.conversion === current.conversion && current.conversion === 'success') {
      return [].concat(_toConsumableArray(transactions), [current]);
    }
    return transactions;
  }
  return [].concat(_toConsumableArray(transactions), [current]);
};
var sortByMasterKeyAndDateCreated = function sortByMasterKeyAndDateCreated(a, b) {
  if (a.masterKey === b.masterKey) {
    var aCreatedAt = (0, _moment["default"])(a.date_created).valueOf();
    var bCreatedAt = (0, _moment["default"])(b.date_created).valueOf();
    return aCreatedAt - bCreatedAt;
  }
  return a.masterKey > b.masterKey ? 1 : -1;
};
var groupRetries = exports.groupRetries = (0, _pipe2["default"])((0, _sort2["default"])(sortByMasterKeyAndDateCreated), (0, _reduce2["default"])(flagLatestRetry, []));
var getDocumentNumber = (0, _either2["default"])((0, _path2["default"])(['customer', 'document_number']), (0, _path2["default"])(['customer', 'documents', 0, 'number']));
var prefix = function prefix(str) {
  return function (value) {
    return "".concat(str).concat(value);
  };
};
var getMasterKey = exports.getMasterKey = (0, _cond2["default"])([[(0, _hasPath2["default"])(['customer', 'id']), (0, _pipe2["default"])(getDocumentNumber, prefix('c_'))], [(0, _hasPath2["default"])(['card', 'fingerprint']), (0, _path2["default"])(['card', 'fingerprint'])], [_T2["default"], (0, _pipe2["default"])((0, _prop2["default"])('id'), prefix('tx_'))]]);
var addMasterKey = function addMasterKey(transaction) {
  return _extends({}, transaction, {
    masterKey: getMasterKey(transaction)
  });
};
var calculateConversion = function calculateConversion(transactions) {
  var paids = transactions.filter(function (_ref) {
    var conversion = _ref.conversion;
    return conversion === 'success';
  });
  if (transactions.length === 0) return 100;
  var conversion = paids.length / transactions.length * 100;
  return Math.round(conversion);
};
var formatTransactions = (0, _map2["default"])((0, _pipe2["default"])(addMasterKey, setInitialFlags));
var buildResult = (0, _applySpec2["default"])({
  conversion: (0, _pipe2["default"])(getTransactions, formatTransactions, groupRetries, calculateConversion),
  paid: (0, _pathOr2["default"])(0, ['aggregations', 'paid', 'doc_count']),
  total: (0, _pathOr2["default"])(0, ['hits', 'total']),
  withoutRetries: (0, _pipe2["default"])(getTransactions, formatTransactions, calculateConversion)
});
var _default = exports["default"] = buildResult;