"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var buildResult = (0, _applySpec2["default"])({
  count: (0, _path2["default"])(['hits', 'total'])
});
var _default = exports["default"] = buildResult;
module.exports = exports["default"];