"use strict";

function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _pathOr3 = _interopRequireDefault(require("ramda/src/pathOr"));
var _formatRecipient = require("./formatRecipient");
var _formatBankAccount = require("../../account/formatBankAccount");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : String(i);
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var mountPartners = function mountPartners(previousState, partner, index) {
  return _extends({}, previousState, _defineProperty({}, "partner".concat(index), {
    cpf: partner.document_number,
    name: partner.name,
    email: partner.email
  }));
};
var getPartnersData = function getPartnersData(partners) {
  return partners.reduce(mountPartners, {});
};
function formatAntecipationAndTransferConfiguration(data) {
  var companyData = (0, _formatRecipient.formatHeaderData)(data);
  var identification = {
    cnpj: '',
    cnpjEmail: '',
    cnpjInformation: false,
    cnpjName: '',
    cnpjPhone: '',
    cnpjUrl: '',
    cpf: '',
    cpfEmail: '',
    cpfInformation: false,
    cpfName: '',
    cpfPhone: '',
    cpfUrl: '',
    documentType: data.bank_account.document_type,
    statusReason: data.status_reason
  };
  var phoneNumber;
  var register = data.register_information;
  var _pathOr = (0, _pathOr3["default"])({}, ['phone_numbers', 0], register),
    ddd = _pathOr.ddd,
    number = _pathOr.number;
  if (register !== null) {
    phoneNumber = ddd && number ? "(".concat(ddd, ") ").concat(number) : '';
  }
  if (data.bank_account.document_type === 'cpf') {
    if (register) {
      identification = _extends({}, identification, {
        cpf: data.bank_account.document_number,
        cpfEmail: register.email,
        cpfInformation: true,
        cpfName: register.name,
        cpfPhone: phoneNumber,
        cpfUrl: register.site_url,
        documentType: 'cpf',
        cnpj: ''
      });
    } else {
      identification = _extends({}, identification, {
        cpf: data.bank_account.document_number,
        cpfInformation: false,
        cnpj: ''
      });
    }
  } else if (data.bank_account.document_type === 'cnpj') {
    if (register) {
      var partners = data.register_information.managing_partners || [];
      var partnersData = getPartnersData(partners);
      identification = _extends({}, identification, {
        cnpj: data.bank_account.document_number,
        cnpjEmail: register.email,
        cnpjInformation: true,
        cnpjName: register.company_name,
        cnpjPhone: phoneNumber,
        cnpjUrl: register.site_url,
        documentType: 'cnpj',
        partnerNumber: partners.length.toString()
      }, partnersData, {
        cpf: ''
      });
    } else {
      identification = _extends({}, identification, {
        cnpj: data.bank_account.document_number,
        cnpjInformation: false,
        cpf: ''
      });
    }
  }
  var transferDay = data.transfer_day ? data.transfer_day.toString() : '';
  var configuration = {
    anticipationModel: data.automatic_anticipation_type,
    anticipationVolumePercentage: data.anticipatable_volume_percentage.toString(),
    anticipationDays: data.automatic_anticipation_days,
    transferEnabled: data.transfer_enabled,
    transferInterval: data.transfer_interval,
    transferDay: transferDay
  };
  var transfer = {
    transferDay: transferDay,
    transferEnabled: data.transfer_enabled,
    transferInterval: data.transfer_interval
  };
  if (data.transfer_interval === 'daily') {
    configuration.transferDay = '0';
    transfer.transferDay = '0';
  }
  if (data.transfer_interval === 'weekly') {
    configuration.transferDay = transferDay;
    transfer.transferDay = transferDay;
  }
  var informationData = {
    identification: identification,
    configuration: configuration,
    bankAccount: {
      agency_digit: data.bank_account.agencia_dv,
      agency: data.bank_account.agencia,
      bank: data.bank_account.bank_code,
      name: data.bank_account.legal_name,
      number_digit: data.bank_account.conta_dv,
      number: data.bank_account.conta,
      type: data.bank_account.type
    },
    metadata: data.metadata
  };
  var anticipation = (0, _formatRecipient.formatAnticipationData)(data);
  var configurationData = {
    anticipation: anticipation,
    transfer: transfer,
    bankAccount: (0, _formatBankAccount.formatBankAccount)(data.bank_account)
  };
  var formattedData = {
    companyData: companyData,
    informationData: informationData,
    configurationData: configurationData,
    kyc_details: data.kyc_details
  };
  return formattedData;
}
var _default = exports["default"] = formatAntecipationAndTransferConfiguration;
module.exports = exports["default"];