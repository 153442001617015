"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _isomorphicFetch = _interopRequireDefault(require("isomorphic-fetch"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var requestBusinessCalendar = function requestBusinessCalendar(year) {
  return (0, _isomorphicFetch["default"])("https://pagarme.github.io/business-calendar/data/brazil/".concat(year, ".json")).then(function (res) {
    return res.json();
  });
};
var _default = exports["default"] = requestBusinessCalendar;
module.exports = exports["default"];