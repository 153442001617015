"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _multiply2 = _interopRequireDefault(require("ramda/src/multiply"));
var _divide2 = _interopRequireDefault(require("ramda/src/divide"));
var _apply2 = _interopRequireDefault(require("ramda/src/apply"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _any2 = _interopRequireDefault(require("ramda/src/any"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var getTotal = function getTotal(model) {
  return (0, _path2["default"])([model, 'hits', 'total']);
};
var calculateChargebackRate = (0, _pipe2["default"])((0, _juxt2["default"])([getTotal('chargebacks'), getTotal('transactions')]), (0, _ifElse2["default"])((0, _any2["default"])((0, _equals2["default"])(0)), (0, _always2["default"])(0), (0, _pipe2["default"])((0, _apply2["default"])(_divide2["default"]), (0, _multiply2["default"])(100))));
var buildChargebackRate = (0, _applySpec2["default"])({
  chargebacks: getTotal('chargebacks'),
  transactions: getTotal('transactions'),
  chargebackRate: calculateChargebackRate
});
var _default = exports["default"] = buildChargebackRate;
module.exports = exports["default"];