"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var deleteUser = function deleteUser(client) {
  return function (id) {
    return client.user.destroy({
      id: id
    });
  };
};
var _default = exports["default"] = deleteUser;
module.exports = exports["default"];