import i18next from '../initializeI18n'

const feeFormatter = value => value.toLocaleString('pt-BR')

// eslint-disable-next-line import/prefer-default-export
export const fromAnticipationOptionsToQuestion = options => ({
  label: 'anticipationType',
  options: [
    ...options.map(opt => ({
      description: i18next.t('pages.onboarding.anticipation_type.per_installment', { installment: feeFormatter(opt.fee) }),
      label: i18next.t('pages.onboarding.anticipation_type.days', { days: opt.delay }),
      value: opt.delay.toString(),
    })),
    {
      description: i18next.t('pages.onboarding.anticipation_type.dont_anticipate_answer.description'),
      label: i18next.t('pages.onboarding.anticipation_type.dont_anticipate_answer.label'),
      value: 'DONT_ANTICIPATE',
    },
  ],
  others: [],
  subtitle: i18next.t('pages.onboarding.anticipation_type.subtitle'),
  title: i18next.t('pages.onboarding.anticipation_type.title'),
})
