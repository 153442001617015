import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { compose } from 'ramda'
import { Button } from '@stone-payments/jade'
import { withError } from '../../../ErrorBoundary'
import CardDefault from '../../components/CardDefault'
import CustomizationSVG from '../../assets/customization.svg'

import styles from './styles.css'

const enhanced = compose(
  translate(),
  withError
)

const Customization = ({
  t,
}) => {
  const history = useHistory()
  const tPrefix = 'pages.kyc.pages.customization'

  const handleGoToOnboarding = () => {
    history.push('/onboarding')
  }

  return (
    <div className={styles.pageWrapper}>
      <CardDefault
        title={t(`${tPrefix}.title`)}
        subTitle={t(`${tPrefix}.subtitle`)}
        icon={<CustomizationSVG />}
      >
        <Button
          size="large"
          icon="shop"
          variant="primary-solid"
          onClick={handleGoToOnboarding}
        >
          {t(`${tPrefix}.button`)}
        </Button>
      </CardDefault>
    </div>
  )
}

Customization.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(Customization)
