var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
import axios from "axios";
import { clone } from "ramda";
import { formatISO } from "date-fns";
import { removeUTCMinusThree } from "../..";
import { baseUrl } from "..";
import removeNull from "../../../helpers/removeNull";
function prepareUrl(options) {
  let url = baseUrl;
  url = url.replace(":company_id", options.companyId);
  return url;
}
export function setParams(params, axiosConfig) {
  const {
      created_since,
      created_until
    } = params,
    restOfParams = __rest(params, ["created_since", "created_until"]);
  const parsedParams = removeNull(restOfParams);
  axiosConfig.params = Object.assign(Object.assign({}, axiosConfig.params), parsedParams);
  axiosConfig.params.created_since = removeUTCMinusThree(formatISO(created_since ? new Date(created_since) : new Date()));
  axiosConfig.params.created_until = removeUTCMinusThree(formatISO(created_until ? new Date(created_until) : new Date()));
}
export default ((axiosConfig, options) => {
  return params => {
    const config = clone(axiosConfig);
    config.url = prepareUrl(options);
    setParams(params, config);
    config.method = "GET";
    return axios(config);
  };
});