import React from 'react'
import PropTypes from 'prop-types'

function Description ({ children }) {
  return (
    <>
      {children}
    </>
  )
}

Description.propTypes = {
  children: PropTypes.node,
}

Description.defaultProps = {
  children: null,
}

export default Description
