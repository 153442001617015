const getApiResponseStatus = (error) => {
  if (error) {
    if (error?.response?.status) {
      return error.response.status
    }
    return 500
  }

  return null
}

export default getApiResponseStatus
