import { useCallback, useMemo, useState } from 'react'
import ApiClientSingleton from '../../../../../utils/helpers/ApiClientSingleton'

const usePostCompanyKey = () => {
  const [isLoading, setIsLoading] = useState(false)

  const apiClient = ApiClientSingleton.get()

  const handleRequest = useCallback(async ({
    isPostbackDerived, name, password, scope,
  }) => {
    setIsLoading(true)

    try {
      const { data } = await apiClient.keys.create({
        is_legacy_mode: true,
        is_postback_derived: isPostbackDerived,
        name,
        password,
        scope,
      })

      return { data }
    } catch (error) {
      return { error }
    } finally {
      setIsLoading(false)
    }
  }, [apiClient.keys])

  return useMemo(
    () => ({
      doPostRequest: handleRequest,
      isLoading,
    }),
    [isLoading, handleRequest]
  )
}

export default usePostCompanyKey
