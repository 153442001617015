import React from 'react'
import PropTypes from 'prop-types'

import { Text, RadioGroup } from '@stone-payments/jade'

import styles from './style.css'

const tPrefix = 'pages.settings.company.add_key.permissions'

const PermissionOptions = ({
  name, onChange, selected, t,
}) => {
  const options = [
    {
      'data-testId': 'option-read',
      id: `${name}-read`,
      label: t(`${tPrefix}.read`),
      name,
      value: 'r',
    },
    {
      'data-testId': 'option-read_write',
      id: `${name}-read_write`,
      label: t(`${tPrefix}.read_write`),
      name,
      value: 'rw',
    },
  ]

  return (
    <div className={styles.permissionsWrapper} data-testid="div-permissions-options">
      <Text className={styles.title} color="medium" variant="overline">
        {t(`${tPrefix}.title`)}
      </Text>
      <RadioGroup
        items={options}
        onChange={e => onChange(e.target.value)}
        value={selected}
      />
    </div>
  )
}

PermissionOptions.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  t: PropTypes.func.isRequired,
}

PermissionOptions.defaultProps = {
  selected: '',
}

export default PermissionOptions
