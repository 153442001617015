"use strict";

function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _defaultTo2 = _interopRequireDefault(require("ramda/src/defaultTo"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : String(i);
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
var defaultSize = (0, _defaultTo2["default"])(15);
var isNotEmpty = (0, _complement2["default"])(_isEmpty2["default"]);
var buildFrom = function buildFrom(_ref) {
  var count = _ref.count,
    page = _ref.page;
  if (page < 1) return count;
  return count * (page - 1);
};
var buildSort = function buildSort(_ref2) {
  var sortField = _ref2.sortField,
    sortOrder = _ref2.sortOrder;
  var parsedOrder = sortOrder === 'descending' ? 'desc' : 'asc';
  return [_defineProperty({}, sortField, parsedOrder)];
};
var buildDateCreatedFilter = function buildDateCreatedFilter(_ref4) {
  var dates = _ref4.dates;
  return {
    range: {
      date_created: {
        gte: dates.start.toISOString(),
        lte: dates.end.toISOString()
      }
    }
  };
};
var buildStatusTerms = function buildStatusTerms(_ref5) {
  var active = _ref5.active,
    inactive = _ref5.inactive;
  var statusTerms = [];
  if (active) statusTerms.push('active');
  if (inactive) statusTerms.push('canceled', 'expired');
  return {
    terms: {
      status: statusTerms
    }
  };
};
var buildQuery = function buildQuery(data) {
  var query = {
    bool: {
      must: [buildDateCreatedFilter(data), buildStatusTerms(data)]
    }
  };
  var parsedName = data.name.trim().toLowerCase();
  if (parsedName) {
    var _query$bool$must;
    var wildCardQueries = parsedName.split(' ').filter(isNotEmpty).map(function (item) {
      return {
        wildcard: {
          name: "*".concat(item, "*")
        }
      };
    });
    (_query$bool$must = query.bool.must).push.apply(_query$bool$must, _toConsumableArray(wildCardQueries));
  }
  return query;
};
var _default = exports["default"] = (0, _applySpec2["default"])({
  from: buildFrom,
  size: (0, _pipe2["default"])((0, _prop2["default"])('count'), defaultSize),
  sort: buildSort,
  query: buildQuery
});
module.exports = exports["default"];