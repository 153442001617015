"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var cardBrandNames = {
  amex: 'American Express',
  "default": '-',
  diners: 'Diners Club',
  discover: 'Discover',
  elo: 'Elo',
  hipercard: 'Hipercard',
  jcb: 'JCB',
  mastercard: 'Mastercard',
  visa: 'Visa'
};
var _default = exports["default"] = cardBrandNames;
module.exports = exports["default"];