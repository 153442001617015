import { PageContainer } from '@stone-payments/jade'
import { Flexbox } from 'former-kit'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { translate } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { requestLogout } from '../../../Account/actions/actions'
import CreatedKeyCard from '../AddApiKey/components/CreatedKeyCard'
import CustomPermissionsCard from '../AddApiKey/components/CustomPermissionsCard'
import KeyInfoCard from '../AddApiKey/components/KeyInfoCard'
import ModalAddKey from '../AddApiKey/components/ModalAddKey'
import TotalPermissionsCard from '../AddApiKey/components/TotalPermissionsCard'
import usePostCompanyKey from '../ApiData/hooks/usePostCompanyKey'

const AddApiKey = ({ setShowCreateKey, t }) => {
  const dispatch = useDispatch()

  const [step, setStep] = useState('info')
  const [info, setInfo] = useState()
  const [permissions, setPermissions] = useState([])
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [apiKey, setApiKey] = useState(null)

  const { doPostRequest, isLoading } = usePostCompanyKey()

  const handleSubmitInfo = (newInfo) => {
    setInfo(newInfo)

    const { isPostbackDerived, type } = newInfo

    if (isPostbackDerived) {
      setShowPasswordModal(true)
    } else {
      setStep(`permissions-${type}`)
    }
  }

  const handleSubmitPermission = (type, permission) => {
    const list = type === 'total' ? [`payments.u2s/all:${permission}`] : permission
    setPermissions(list)

    setShowPasswordModal(true)
  }

  const handleSubmit = async (password) => {
    setHasError(false)

    const { isPostbackDerived, name } = info

    const { data: newData, error } = await doPostRequest({
      isPostbackDerived,
      name,
      password,
      scope: isPostbackDerived ? ['payments.u2s/all:rw'] : permissions,
    })

    if (newData) {
      setApiKey(newData.key)
      setShowPasswordModal(false)
      setStep('created')
    } else if (error) {
      if (error?.response?.data?.status === 401) {
        dispatch(requestLogout())
      }
      setHasError(true)
    }
  }

  return (
    <Flexbox justifyContent="center">
      <PageContainer size="narrow">
        {step === 'info' && (
          <KeyInfoCard
            t={t}
            keyName={info?.name}
            selectedType={info?.type}
            onSubmit={handleSubmitInfo}
          />
        )}
        {step === 'permissions-total' && (
          <TotalPermissionsCard
            t={t}
            onSubmit={e => handleSubmitPermission('total', e)}
            onBack={() => setStep('info')}
          />
        )}
        {step === 'permissions-custom' && (
          <CustomPermissionsCard
            t={t}
            onSubmit={e => handleSubmitPermission('custom', e)}
            onBack={() => setStep('info')}
          />
        )}
        {step === 'created' && (
          <CreatedKeyCard
            t={t}
            apiKey={apiKey}
            onSubmit={() => setShowCreateKey(false)}
          />
        )}
        <ModalAddKey
          isLoading={isLoading}
          isOpen={showPasswordModal}
          hasError={hasError}
          onClose={() => setShowPasswordModal(false)}
          onSubmit={e => handleSubmit(e)}
          t={t}
        />
      </PageContainer>
    </Flexbox>
  )
}

AddApiKey.propTypes = {
  setShowCreateKey: PropTypes.func,
  t: PropTypes.func.isRequired,
}

AddApiKey.defaultProps = {
  setShowCreateKey: false,
}

export default translate()(AddApiKey)
