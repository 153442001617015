"use strict";

function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _isomorphicFetch = _interopRequireDefault(require("isomorphic-fetch"));
var _qs = _interopRequireDefault(require("qs"));
var _environment = _interopRequireWildcard(require("../../environment"));
function _getRequireWildcardCache(e) {
  if ("function" != typeof WeakMap) return null;
  var r = new WeakMap(),
    t = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(e) {
    return e ? t : r;
  })(e);
}
function _interopRequireWildcard(e, r) {
  if (!r && e && e.__esModule) return e;
  if (null === e || "object" != _typeof(e) && "function" != typeof e) return {
    "default": e
  };
  var t = _getRequireWildcardCache(r);
  if (t && t.has(e)) return t.get(e);
  var n = {
      __proto__: null
    },
    a = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) {
    var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
    i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
  }
  return n["default"] = e, t && t.set(e, n), n;
}
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var updateCompany = function updateCompany(client) {
  return function (data) {
    return client.company.update(data);
  };
};
var updateBrandingLogo = function updateBrandingLogo(client) {
  return function (body) {
    var queryString = '';
    var headers;
    if (client.authentication.jwt) {
      headers = {
        authorization: "Bearer ".concat(client.authentication.jwt),
        'x-live': _environment["default"] === 'live' ? '1' : '0'
      };
      queryString = "?company_id=".concat(client.authentication.company_id);
    } else {
      queryString = "?".concat(_qs["default"].stringify(client.authentication));
    }
    return (0, _isomorphicFetch["default"])("".concat(_environment.apiUrl, "company/branding/logo").concat(queryString), {
      method: 'PUT',
      headers: headers,
      body: body
    }).then(function (res) {
      return res.json();
    });
  };
};
var updateSubscriptionPostback = function updateSubscriptionPostback(client) {
  return function (_ref) {
    var subscriptionStatusChanged = _ref.subscriptionStatusChanged,
      transactionCreated = _ref.transactionCreated;
    var data = {
      events: {
        subscription: {
          subscription_status_changed: {
            postback: subscriptionStatusChanged
          },
          transaction_created: {
            postback: transactionCreated
          }
        }
      }
    };
    return client.company.update(data);
  };
};
var updateSubscription = function updateSubscription(client) {
  return function (_ref2) {
    var cancelAfterFailedCharges = _ref2.cancelAfterFailedCharges,
      chargeAttempts = _ref2.chargeAttempts,
      chargesIntervalInDays = _ref2.chargesIntervalInDays,
      emailCustomers = _ref2.emailCustomers,
      paymentDeadline = _ref2.paymentDeadline,
      useProportionalAmountOnDowngrade = _ref2.useProportionalAmountOnDowngrade;
    var data = {
      subscriptions: {
        cancel_after_failed_charges: cancelAfterFailedCharges,
        charge_attempts: chargeAttempts,
        charges_interval_in_days: chargesIntervalInDays,
        email_customers: emailCustomers,
        payment_deadline: paymentDeadline,
        use_proportional_amount_on_downgrade: useProportionalAmountOnDowngrade
      }
    };
    return client.company.update(data);
  };
};
var _default = exports["default"] = {
  updateCompany: updateCompany,
  updateBrandingLogo: updateBrandingLogo,
  updateSubscriptionPostback: updateSubscriptionPostback,
  updateSubscription: updateSubscription
};
module.exports = exports["default"];