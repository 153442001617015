import { Icon } from '@stone-payments/jade'
import PropTypes from 'prop-types'
import { propEq } from 'ramda'
import React, { useState } from 'react'
import { translate } from 'react-i18next'
import { useSelector } from 'react-redux'
import Loader from '../../../../components/Loader'
import { impersonate } from '../../../../environment'
import AlertWarning from '../ApiData/components/AlertWarning'
import ModalDeleteKey from '../ApiData/components/ModalDeleteKey'
import useDeleteCompanyKey from '../ApiData/hooks/useDeleteCompanyKey'
import useGetCompanyKeys from '../ApiData/hooks/useGetCompanyKeys'
import getErrorMessage from '../ApiData/utils/getErrorMessage'
import AddApiKey from './addOldKeysPage'
import TableOldApiKeys from './components/TableOldApiKeys'
import style from './style.css'

const numberOrStringShape = PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
]).isRequired

const isAdmin = propEq('permission', 'admin')

const OldKeysPage = ({
  onDeleteKey, outdatedKeys, t,
}) => {
  const { user } = useSelector(state => state.account)

  const userIsAdmin = isAdmin(user)
  const isImpersonate = impersonate

  const {
    data, error: errorGetCompanyKeys, isLoading, refetch,
  } = useGetCompanyKeys({
    page: 1,
    size: 10,
  })

  const { doDeleteRequest, isLoading: isDeleting } = useDeleteCompanyKey()
  const [isOpenModalDeleteKey, setisOpenModalDeleteKey] = useState(false)
  const [errorToDeleteKey, setErrorToDeleteKey] = useState(null)
  const [selectedKey, setSelectedKey] = useState(null)
  const [showCreateKey, setShowCreateKey] = useState(false)

  const keys = data.items || []
  const hasTheLimitOfKeys = keys.length === 10

  const errorMessageToGetCompanyKeys = getErrorMessage({
    error: errorGetCompanyKeys, t,
  })

  const handleOpenDeleteModal = (keyId) => {
    const apiKey = keys.find(key => key.id === keyId)
    setSelectedKey(apiKey)
    setisOpenModalDeleteKey(true)
  }

  const handleDeleteKey = async ({ password }) => {
    setErrorToDeleteKey(null)
    const { error } = await doDeleteRequest({
      keyId: selectedKey.id,
      password,
    })

    if (error) {
      const errorMessage = getErrorMessage({ error, t })
      setErrorToDeleteKey(errorMessage)
    } else {
      setisOpenModalDeleteKey(false)
      setSelectedKey(null)
      onDeleteKey(selectedKey.id)
      refetch()
    }
  }

  if (isLoading) {
    return <Loader visible />
  }

  return (
    <>
      {!showCreateKey && (
        <div className={style.container}>
          {!!errorMessageToGetCompanyKeys && (
            <AlertWarning
              message={errorMessageToGetCompanyKeys}
              components={[
                <a href="https://dash.pagar.me" target="_blank" rel="noopener noreferrer">message</a>,
              ]}
            />
          )}

          <div className={style.alertOldKeys}>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <Icon size="small" use="warning" color="current-color" className={style.icon} />
              <strong>
                {t('pages.company_settings.api_data.outdated_keys_title')}
              </strong>
            </div>
            <p>
              {t('pages.company_settings.api_data.outdated_keys_message')}
            </p>
          </div>

          {!userIsAdmin && (
            <div className={style.alertOldKeys}>
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <Icon size="small" use="warning" color="current-color" className={style.icon} />
                <strong>
                  {t('pages.company_settings.api_data.outdated_keys_message_admin')}
                </strong>
              </div>
            </div>
          )}

          {!errorGetCompanyKeys && !isImpersonate && userIsAdmin && (
            <TableOldApiKeys
              isAdmin={userIsAdmin}
              disableNew={hasTheLimitOfKeys}
              onDelete={handleOpenDeleteModal}
              onClickAddNew={() => setShowCreateKey(true)}
              rows={outdatedKeys}
              outdatedKeys={outdatedKeys}
              t={t}
            />
          )}

          {!!selectedKey && (
            <ModalDeleteKey
              apiKey={selectedKey.masked_key}
              error={errorToDeleteKey}
              isOpen={isOpenModalDeleteKey}
              onClose={() => setisOpenModalDeleteKey(false)}
              onSubmit={handleDeleteKey}
              isLoading={isDeleting}
              t={t}
            />
          )}
        </div>
      )}

      {showCreateKey && (
        <div>
          <AddApiKey
            setShowCreateKey={setShowCreateKey}
            t={t}
          />
        </div>
      )}
    </>
  )
}

OldKeysPage.propTypes = {
  company: PropTypes.shape({
    apiVersion: PropTypes.string.isRequired,
    encryptionKey: PropTypes.shape({
      key: PropTypes.string.isRequired,
    }).isRequired,
    id: numberOrStringShape.isRequired,
  }).isRequired,
  onDeleteKey: PropTypes.func,
  outdatedKeys: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired,
}

OldKeysPage.defaultProps = {
  onDeleteKey: null,
  outdatedKeys: [],
}

export default translate()(OldKeysPage)
