"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _balance = _interopRequireDefault(require("./balance"));
var _bulkAnticipations = _interopRequireDefault(require("./bulkAnticipations"));
var _bulkAnticipationAndTransfersRequests = _interopRequireDefault(require("./bulkAnticipationAndTransfersRequests"));
var _business = _interopRequireDefault(require("./business"));
var _company = _interopRequireDefault(require("./company"));
var _ganon = _interopRequireDefault(require("./ganon"));
var _invites = _interopRequireDefault(require("./invites"));
var _kyc = _interopRequireDefault(require("./kyc"));
var _paymentLinks = _interopRequireDefault(require("./paymentLinks"));
var _plans = _interopRequireDefault(require("./plans"));
var _proxy = _interopRequireDefault(require("./proxy"));
var _recipient = _interopRequireDefault(require("./recipient"));
var _subscriptions = _interopRequireDefault(require("./subscriptions"));
var _transfers = _interopRequireDefault(require("./transfers"));
var _transactions = _interopRequireDefault(require("./transactions"));
var _user = _interopRequireDefault(require("./user"));
var _withdraw = _interopRequireDefault(require("./withdraw"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var cockpit = (0, _applySpec2["default"])({
  balance: _balance["default"],
  bulkAnticipations: _bulkAnticipations["default"],
  bulkAnticipationAndTransfersRequests: _bulkAnticipationAndTransfersRequests["default"],
  business: _business["default"],
  company: _company["default"],
  ganon: _ganon["default"],
  invites: _invites["default"],
  kyc: _kyc["default"],
  paymentLinks: _paymentLinks["default"],
  plans: _plans["default"],
  recipient: _recipient["default"],
  subscriptions: _subscriptions["default"],
  transfers: _transfers["default"],
  transactions: _transactions["default"],
  user: _user["default"],
  withdraw: _withdraw["default"]
});
var _default = exports["default"] = (0, _proxy["default"])(cockpit);
module.exports = exports["default"];