"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transactionsQuery = exports.chargebackQuery = void 0;
var transactionsQuery = exports.transactionsQuery = function transactionsQuery(_ref) {
  var from = _ref.from,
    to = _ref.to;
  return {
    query: {
      filtered: {
        filter: {
          and: [{
            term: {
              payment_method: 'credit_card'
            }
          }, {
            term: {
              acquirer_name: 'pagarme'
            }
          }, {
            terms: {
              status: ['paid', 'refunded', 'chargedback']
            }
          }, {
            range: {
              date_created: {
                gte: from,
                lte: to
              }
            }
          }]
        }
      }
    }
  };
};
var chargebackQuery = exports.chargebackQuery = function chargebackQuery(_ref2) {
  var from = _ref2.from,
    to = _ref2.to;
  return {
    query: {
      filtered: {
        filter: {
          and: [{
            term: {
              type: 'chargeback'
            }
          }, {
            term: {
              cycle: 1
            }
          }, {
            term: {
              installment: 1
            }
          }, {
            range: {
              accrual_date: {
                gte: from,
                lte: to
              }
            }
          }]
        }
      }
    }
  };
};