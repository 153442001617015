import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Flexbox,
  Modal,
  ModalTitle,
  ModalContent,
} from 'former-kit'
import IconClose from 'emblematic-icons/svg/ClearClose32.svg'

import PagarmeLogoSvg from '../../assets/pagarme.svg'
import StoneLogoSvg from '../../assets/stone.svg'

import style from './style.css'

const tPrefix = 'header.modal_redirect_by_vpn'

const RedirectByVpnModal = ({
  isOpen,
  onClose,
  onRedirectToDashAdmin,
  onRedirectToStoneAdmin,
  t,
}) => (
  <Modal
    isOpen={isOpen}
  >
    <ModalTitle
      title=""
      closeIcon={<IconClose width={12} height={12} />}
      onClose={onClose}
    />
    <ModalContent>
      <h2 className={style.modalTitle}>
        {t(`${tPrefix}.title`)}
      </h2>
      <Flexbox className={style.flexWrapper} justifyContent="center">
        <div className={style.buttonWrapper}>
          <PagarmeLogoSvg height="38px" />
          <Button onClick={onRedirectToDashAdmin}>
            <span className={style.buttonText}>
              {t(`${tPrefix}.button_pagarme`)}
            </span>
          </Button>
        </div>
        <div className={style.buttonWrapper}>
          <StoneLogoSvg />
          <Button onClick={onRedirectToStoneAdmin}>
            <span className={style.buttonText}>
              {t(`${tPrefix}.button_stone`)}
            </span>
          </Button>
        </div>
      </Flexbox>
    </ModalContent>
  </Modal>
)

RedirectByVpnModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRedirectToDashAdmin: PropTypes.func.isRequired,
  onRedirectToStoneAdmin: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

RedirectByVpnModal.defaultProps = {
  isOpen: false,
}

export default RedirectByVpnModal
