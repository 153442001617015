"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _omit2 = _interopRequireDefault(require("ramda/src/omit"));
var _buildRequest = _interopRequireDefault(require("./buildRequest"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var ignoredProps = ['status', 'recipientId'];
var operationsData = function operationsData(client) {
  return function (query) {
    var requestPromise = (0, _buildRequest["default"])(client, query);
    return requestPromise.then(function (data) {
      return {
        query: (0, _omit2["default"])(ignoredProps, query),
        result: {
          search: {
            operations: {
              count: 1000,
              offset: 0,
              rows: data,
              total: 0
            }
          }
        }
      };
    });
  };
};
var _default = exports["default"] = operationsData;
module.exports = exports["default"];