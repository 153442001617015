"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _format = _interopRequireDefault(require("./format"));
var _recipientBuilders = require("../add/recipientBuilders");
var _formatBankAccount = _interopRequireDefault(require("../account/formatBankAccount"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var DetailRecipient = function DetailRecipient(client) {
  return function (recipientId) {
    return client.recipients.find({
      id: recipientId
    }).then(function (recipient) {
      return (0, _format["default"])(recipient);
    });
  };
};
var formatRecipient = function formatRecipient(recipient) {
  return _extends({}, recipient, {
    bank_account: (0, _formatBankAccount["default"])([recipient.bank_account]).accounts[0]
  });
};
var UpdateDetailRecipient = function UpdateDetailRecipient(client) {
  return function (recipientId, data, options) {
    if (data.configuration.anticipationModel) {
      return client.recipients.update(_extends({
        id: recipientId,
        otp: data.otp
      }, (0, _recipientBuilders.formatToAnticipation)(data, options)));
    }
    if (data.configuration.transferInterval) {
      return client.recipients.update(_extends({
        id: recipientId,
        otp: data.otp
      }, (0, _recipientBuilders.formatToTransfer)(data)));
    }
    if (data.configuration.id) {
      return client.recipients.update(_extends({
        id: recipientId,
        otp: data.otp
      }, (0, _recipientBuilders.formatToBankAccount)(data))).then(formatRecipient);
    }
    return client.recipients.update(_extends({
      id: recipientId,
      otp: data.otp
    }, (0, _recipientBuilders.formatToAnticipation)(data), (0, _recipientBuilders.formatToTransfer)(data), (0, _recipientBuilders.formatToBankAccount)(data))).then(formatRecipient);
  };
};
var AddNewBankAccount = function AddNewBankAccount(client) {
  return function (data) {
    var newAccount = (0, _recipientBuilders.formatToBankAccount)(data);
    return client.bankAccounts.create(_extends({}, newAccount.bank_account, {
      otp: data.otp
    })).then(function (bankAccountCreated) {
      return (0, _formatBankAccount["default"])([bankAccountCreated]).accounts[0];
    }); // eslint-disable-line
  };
};
var _default = exports["default"] = {
  detail: DetailRecipient,
  update: UpdateDetailRecipient,
  createNewAccount: AddNewBankAccount
};
module.exports = exports["default"];