import React from 'react'

import IconError from 'emblematic-icons/svg/CloseCircle32.svg'
import IconSuccess from 'emblematic-icons/svg/CheckCircle32.svg'

import STATUS_RESPONSE from './status-response.const'

const Icon = (status) => {
  const IconStatus = status === STATUS_RESPONSE.success
    ? IconSuccess
    : IconError
  return <IconStatus width={16} height={16} />
}

export default Icon
