"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _unless2 = _interopRequireDefault(require("ramda/src/unless"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var formarDate = function formarDate(date) {
  return (0, _momentTimezone["default"])(date).format('DD/MM/YYYY');
};
var formatUser = (0, _applySpec2["default"])({
  id: (0, _propOr2["default"])('', 'id'),
  email: (0, _propOr2["default"])('', 'email'),
  name: (0, _propOr2["default"])('', 'name'),
  role: (0, _propOr2["default"])('', 'permission'),
  date_created: (0, _pipe2["default"])((0, _propOr2["default"])(null, 'date_created'), (0, _unless2["default"])(_isNil2["default"], formarDate))
});
var _default = exports["default"] = (0, _pipe2["default"])((0, _propOr2["default"])([], 'users'), (0, _map2["default"])(formatUser));
module.exports = exports["default"];