"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _dissocPath2 = _interopRequireDefault(require("ramda/src/dissocPath"));
var _set2 = _interopRequireDefault(require("ramda/src/set"));
var _lensPath2 = _interopRequireDefault(require("ramda/src/lensPath"));
var _pick2 = _interopRequireDefault(require("ramda/src/pick"));
var _mergeDeepLeft2 = _interopRequireDefault(require("ramda/src/mergeDeepLeft"));
var _mergeAll2 = _interopRequireDefault(require("ramda/src/mergeAll"));
var _values2 = _interopRequireDefault(require("ramda/src/values"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _objOf2 = _interopRequireDefault(require("ramda/src/objOf"));
var _assoc2 = _interopRequireDefault(require("ramda/src/assoc"));
var _identity2 = _interopRequireDefault(require("ramda/src/identity"));
var _when2 = _interopRequireDefault(require("ramda/src/when"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _propSatisfies2 = _interopRequireDefault(require("ramda/src/propSatisfies"));
var _unless2 = _interopRequireDefault(require("ramda/src/unless"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _subtract2 = _interopRequireDefault(require("ramda/src/subtract"));
var _apply2 = _interopRequireDefault(require("ramda/src/apply"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _reject2 = _interopRequireDefault(require("ramda/src/reject"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _mergeWithKey2 = _interopRequireDefault(require("ramda/src/mergeWithKey"));
var _is2 = _interopRequireDefault(require("ramda/src/is"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _props2 = _interopRequireDefault(require("ramda/src/props"));
var _pluck2 = _interopRequireDefault(require("ramda/src/pluck"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _sum2 = _interopRequireDefault(require("ramda/src/sum"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _has2 = _interopRequireDefault(require("ramda/src/has"));
var _sort2 = _interopRequireDefault(require("ramda/src/sort"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _isReprocessable = _interopRequireDefault(require("./isReprocessable"));
var _isRefundable = _interopRequireDefault(require("./isRefundable"));
var _isCapturable = _interopRequireDefault(require("./isCapturable"));
var _groupPayablesByInstallments = _interopRequireDefault(require("./groupPayablesByInstallments"));
var _shared = require("../shared");
var _operations = _interopRequireDefault(require("./operations"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var isNilOrEmpty = (0, _either2["default"])(_isNil2["default"], _isEmpty2["default"]);
var sortByCreatedAt = (0, _sort2["default"])(function (left, right) {
  if ((0, _has2["default"])('created_at', right)) {
    return (0, _momentTimezone["default"])(right.created_at).diff((0, _momentTimezone["default"])(left.created_at), 'milliseconds');
  }
  return (0, _momentTimezone["default"])(right.date_created).diff((0, _momentTimezone["default"])(left.date_created), 'milliseconds');
});
var sumInstallmentsAmount = (0, _pipe2["default"])((0, _prop2["default"])('installments'), (0, _pluck2["default"])('amount'), _sum2["default"]);
var sumAllPayableFees = (0, _pipe2["default"])((0, _props2["default"])(['fee', 'anticipation_fee']), _sum2["default"]);
var payablesAllFeesTotalAmount = (0, _pipe2["default"])((0, _map2["default"])(sumAllPayableFees), _sum2["default"]);
var sumInstallmentsCostAmount = (0, _pipe2["default"])((0, _prop2["default"])('installments'), payablesAllFeesTotalAmount);
var mergeInstallment = function mergeInstallment(key, left, right) {
  switch (key) {
    case 'amount':
    case 'net_amount':
    case 'mdr':
    case 'taxes':
    case 'anticipation':
      return left + right;
    default:
      break;
  }
  if ((0, _is2["default"])(_momentTimezone["default"], right) && (0, _is2["default"])(_momentTimezone["default"], left)) {
    return right;
  }
  if ((0, _is2["default"])(Array, left) && (0, _is2["default"])(Array, right)) {
    return right;
  }
  if ((0, _is2["default"])(Object, left) && (0, _is2["default"])(Object, right)) {
    return (0, _mergeWithKey2["default"])(mergeInstallment, left, right);
  }
  return right;
};
var mapRecipients = (0, _map2["default"])((0, _applySpec2["default"])({
  amount: sumInstallmentsAmount,
  created_at: (0, _prop2["default"])('date_created'),
  id: (0, _prop2["default"])('recipient_id'),
  charge_remainder: (0, _prop2["default"])('charge_remainder'),
  installments: (0, _pipe2["default"])((0, _prop2["default"])('installments'), _groupPayablesByInstallments["default"]),
  liabilities: (0, _pipe2["default"])((0, _juxt2["default"])([(0, _ifElse2["default"])((0, _propEq2["default"])('charge_processing_fee', true), (0, _always2["default"])('mdr'), (0, _always2["default"])(null)), (0, _ifElse2["default"])((0, _propEq2["default"])('liable', true), (0, _always2["default"])('chargeback'), (0, _always2["default"])(null))]), (0, _reject2["default"])(_isNil2["default"])),
  name: (0, _path2["default"])(['recipient', 'bank_account', 'legal_name']),
  net_amount: (0, _pipe2["default"])((0, _juxt2["default"])([sumInstallmentsAmount, sumInstallmentsCostAmount]), (0, _apply2["default"])(_subtract2["default"])),
  percentage: (0, _pipe2["default"])((0, _prop2["default"])('percentage'), String),
  split_rule_id: (0, _prop2["default"])('id'),
  status: (0, _pipe2["default"])((0, _prop2["default"])('installments'), sortByCreatedAt, _last2["default"], (0, _prop2["default"])('status')),
  updated_at: (0, _prop2["default"])('date_updated')
}));
var buildRecipients = (0, _applySpec2["default"])({
  recipients: (0, _unless2["default"])(_isEmpty2["default"], mapRecipients)
});
var hasSplitRules = (0, _propSatisfies2["default"])((0, _complement2["default"])(_isEmpty2["default"]), 'split_rules');
var groupInstallments = function groupInstallments(_ref) {
  var payables = _ref.payables,
    splitRules = _ref.split_rules;
  return splitRules.map(function (rule) {
    return _extends({}, rule, {
      installments: payables.filter((0, _propEq2["default"])('recipient_id', rule.recipient_id))
    });
  });
};
var buildNewSplitRules = (0, _when2["default"])(hasSplitRules, (0, _pipe2["default"])((0, _juxt2["default"])([groupInstallments, _identity2["default"]]), (0, _apply2["default"])((0, _assoc2["default"])('split_rules'))));
var buildReasonCode = (0, _pipe2["default"])((0, _ifElse2["default"])(isNilOrEmpty, (0, _always2["default"])(null), (0, _pipe2["default"])(_last2["default"], (0, _prop2["default"])('reason_code'))), (0, _objOf2["default"])('reason_code'));
var getOriginalId = (0, _either2["default"])((0, _path2["default"])(['reprocessed', 'original_transaction_id']), (0, _pipe2["default"])((0, _prop2["default"])('transaction'), _shared.getMetadataProp, (0, _propOr2["default"])(null, 'pagarme_original_transaction_id')));
var getReprocessedId = (0, _pathOr2["default"])(null, ['reprocessed', 'id']);
var getReprocessedTransactionIds = function getReprocessedTransactionIds(relatedIdGetter) {
  return (0, _juxt2["default"])([relatedIdGetter, (0, _path2["default"])(['transaction', 'id'])]);
};
var getRelatedTransactionId = function getRelatedTransactionId(relatedIdGetter) {
  return (0, _pipe2["default"])(getReprocessedTransactionIds(relatedIdGetter), (0, _ifElse2["default"])((0, _apply2["default"])(_equals2["default"]), (0, _always2["default"])(null), _head2["default"]));
};
var buildReprocessIds = (0, _applySpec2["default"])({
  nextId: getRelatedTransactionId(getReprocessedId),
  previousId: getRelatedTransactionId(getOriginalId)
});
var buildCapabilities = function buildCapabilities(_ref2) {
  var reprocessed = _ref2.reprocessed,
    transaction = _ref2.transaction;
  return {
    capabilities: {
      capturable: (0, _isCapturable["default"])(transaction),
      refundable: (0, _isRefundable["default"])(transaction),
      reprocessable: (0, _isReprocessable["default"])(transaction, reprocessed)
    }
  };
};
var getAnticipationFee = (0, _applySpec2["default"])({
  payment: {
    anticipation_fee: (0, _pipe2["default"])((0, _prop2["default"])('payables'), (0, _pluck2["default"])('anticipation_fee'), _values2["default"], _sum2["default"])
  }
});
var buildChargebackReason = (0, _pipe2["default"])((0, _ifElse2["default"])((0, _pipe2["default"])((0, _prop2["default"])('transaction'), (0, _propEq2["default"])('status', 'chargedback')), (0, _pathOr2["default"])(null, ['chargeback_reason', 'message']), (0, _always2["default"])(null)), (0, _objOf2["default"])('chargeback_reason'));
var mapTransactionToResult = (0, _applySpec2["default"])({
  transaction: (0, _pipe2["default"])((0, _juxt2["default"])([(0, _pipe2["default"])((0, _juxt2["default"])([getAnticipationFee, (0, _pipe2["default"])((0, _prop2["default"])('transaction'), (0, _applySpec2["default"])(_shared.transactionSpec))]), (0, _apply2["default"])(_mergeDeepLeft2["default"])), (0, _pipe2["default"])((0, _pick2["default"])(['gatewayOperations', 'chargebackOperations', 'antifraudAnalyses', 'transaction']), _operations["default"]), buildCapabilities, buildReprocessIds, (0, _pipe2["default"])((0, _prop2["default"])('split_rules'), function (splitRules) {
    return splitRules.reduce(function (acc, cur) {
      if (!acc || acc.length === 0) return [cur];
      if (!acc.some(function (newItem) {
        return newItem.recipient_id === cur.recipient_id;
      })) {
        return [].concat(_toConsumableArray(acc), [cur]);
      }
      return acc;
    }, []);
  }, buildRecipients), (0, _pipe2["default"])((0, _prop2["default"])('chargebacks'), buildReasonCode), (0, _pick2["default"])(['capabilities']), (0, _pick2["default"])(['payables']), buildChargebackReason]), _mergeAll2["default"])
});
var mdrLens = (0, _lensPath2["default"])(['transaction', 'payment', 'mdr_amount']);
var sumAllRecipientsMdrCost = (0, _pipe2["default"])((0, _pluck2["default"])('fee'), _sum2["default"]);
var fraudCoverageAmountLens = (0, _lensPath2["default"])(['transaction', 'payment', 'fraud_coverage_amount']);
var sumAllRecipientsFraudCoverageFee = (0, _pipe2["default"])((0, _path2["default"])(['transaction', 'payables']), (0, _pluck2["default"])('fraud_coverage_fee'), _sum2["default"]);
var netAmountLens = (0, _lensPath2["default"])(['transaction', 'payment', 'net_amount']);
var subtractMdrFromNetAmount = (0, _pipe2["default"])((0, _path2["default"])(['transaction', 'payment']), (0, _props2["default"])(['net_amount', 'mdr_amount']), (0, _apply2["default"])(_subtract2["default"]));
var _default = exports["default"] = (0, _pipe2["default"])(buildNewSplitRules, (0, _juxt2["default"])([(0, _pipe2["default"])((0, _prop2["default"])('payables'), sumAllRecipientsMdrCost), mapTransactionToResult]), (0, _apply2["default"])((0, _set2["default"])(mdrLens)), (0, _juxt2["default"])([subtractMdrFromNetAmount, _identity2["default"]]), (0, _apply2["default"])((0, _set2["default"])(netAmountLens)), (0, _juxt2["default"])([sumAllRecipientsFraudCoverageFee, _identity2["default"]]), (0, _apply2["default"])((0, _set2["default"])(fraudCoverageAmountLens)), (0, _dissocPath2["default"])(['transaction', 'payables']));
module.exports = exports["default"];