import {
  allPass,
  complement,
  pipe,
  prop,
  propOr,
  propEq,
} from 'ramda'
import { impersonate } from '../environment'

import isPaymentLink from './isPaymentLink'

const notInImpersonateMode = () => !impersonate

const isAdmin = propEq('permission', 'admin')

const isLinkmeSeller = allPass([
  pipe(prop('company'), isPaymentLink),
  pipe(propOr({}, 'user'), complement(isAdmin)),
  notInImpersonateMode,
])

export default isLinkmeSeller
