"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _result = _interopRequireDefault(require("./result"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var filterByPlan = function filterByPlan(client) {
  return function (_ref) {
    var _ref$count = _ref.count,
      count = _ref$count === void 0 ? 15 : _ref$count,
      _ref$page = _ref.page,
      page = _ref$page === void 0 ? 1 : _ref$page,
      planId = _ref.planId;
    var query = {
      from: (page - 1) * count,
      size: count,
      query: {
        filtered: {
          filter: {
            query: {
              match_phrase: {
                'plan.id': planId
              }
            }
          }
        }
      }
    };
    return client.search({
      type: 'subscription',
      query: JSON.stringify(query)
    }).then((0, _result["default"])({}));
  };
};
var _default = exports["default"] = filterByPlan;
module.exports = exports["default"];