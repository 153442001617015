"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var _default = exports["default"] = (0, _applySpec2["default"])({
  daysToAddInExpirationDate: (0, _path2["default"])(['boletos', 'days_to_add_in_expiration_date']),
  instrucoes: (0, _pathOr2["default"])('', ['boletos', 'instrucoes'])
});
module.exports = exports["default"];