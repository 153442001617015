import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { compose } from 'ramda'
import { Button } from '@stone-payments/jade'
import { useHistory } from 'react-router-dom'
import CardDefault from '../../components/CardDefault'
import ComputerSvg from '../../assets/computer.svg'

import styles from './styles.css'
import Header from '../../components/Header'
import Steps from '../../../../components/Steps'

const enhanced = compose(
  translate()
)

const Conclusion = ({ t }) => {
  const tPrefix = 'pages.kyc.pages.conclusion'
  const history = useHistory()

  const steps = [
    {
      icon: 'person-check',
      isFilled: true,
      title: t('pages.kyc.steps.validation'),
    },
    {
      icon: 'shop',
      isFilled: true,
      title: t('pages.kyc.steps.customization'),
    },
    {
      icon: 'circle-check',
      isFilled: true,
      title: t('pages.kyc.steps.conclusion'),
    },
  ]

  const handleHomeRedirect = () => {
    history.push('/')
  }

  return (
    <div className={styles.pageWrapper}>
      <Header t={t} onClose={handleHomeRedirect} />
      <div className={styles.pageForm}>
        <Steps steps={steps} />
        <CardDefault
          title={t(`${tPrefix}.title`)}
          subTitle={t(`${tPrefix}.subtitle`)}
          icon={<ComputerSvg />}
        >
          <Button
            onClick={handleHomeRedirect}
            size="large"
            className={styles.redirectButton}
            icon="play-tv"
            variant="primary-solid"
          >
            {t(`${tPrefix}.button`)}
          </Button>
        </CardDefault>
      </div>
    </div>
  )
}

Conclusion.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(Conclusion)
