import React from 'react'

import GenericError from '../pages/Errors/GenericError'

const genericInfo = {
  getComponent: localized => (
    <GenericError
      {...localized}
      showActions
      action={({ actions, history }) => {
        actions.clearErrors()

        history.replace('/credit')
      }}
    />
  ),
  method: /get/,
  name: /ApiError/,
  status: 404,
  type: /not_found/,
}

export default [
  {
    affectedRoutes: [/\/credit\/loan\/\w+/],
    localized: t => ({
      actionText: t('go_back'),
      message: t('pages.credit.loan.not_found_message'),
      title: t('pages.error.unauthorized_title'),
    }),
    message: /Empréstimo não encontrado/,
    ...genericInfo,
  },
  {
    affectedRoutes: [/\/credit\/\w+\/proposal\/\w+/],
    localized: t => ({
      actionText: t('go_back'),
      message: t('pages.credit.proposal.not_found_message'),
      title: t('pages.error.unauthorized_title'),
    }),
    message: /Proposta não encontrada/,
    ...genericInfo,
  },
]
