"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _unless2 = _interopRequireDefault(require("ramda/src/unless"));
var _has2 = _interopRequireDefault(require("ramda/src/has"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _pathEq2 = _interopRequireDefault(require("ramda/src/pathEq"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _any2 = _interopRequireDefault(require("ramda/src/any"));
var _both2 = _interopRequireDefault(require("ramda/src/both"));
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _contains2 = _interopRequireDefault(require("ramda/src/contains"));
var _propSatisfies2 = _interopRequireDefault(require("ramda/src/propSatisfies"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _reject2 = _interopRequireDefault(require("ramda/src/reject"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
var _find2 = _interopRequireDefault(require("ramda/src/find"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _filter2 = _interopRequireDefault(require("ramda/src/filter"));
var _reverse2 = _interopRequireDefault(require("ramda/src/reverse"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
var buildChargeBackOps = function buildChargeBackOps(chargebacks) {
  return chargebacks.map(function (chargeback) {
    return {
      created_at: chargeback.accrual_date,
      cycle: chargeback.cycle,
      id: chargeback.id,
      status: 'success',
      type: chargeback.type
    };
  });
};
var normalizeChargebackOps = (0, _pipe2["default"])((0, _filter2["default"])((0, _propEq2["default"])('installment', 1)), buildChargeBackOps, _reverse2["default"]);
var findPreviousOperation = function findPreviousOperation(groupId, operations) {
  return (0, _find2["default"])((0, _propEq2["default"])('next_group_id', groupId), operations);
};
var sortGatewayOperations = function sortGatewayOperations(operations) {
  var lastOperation = operations.find((0, _propEq2["default"])('next_group_id', null));
  var list = lastOperation ? [lastOperation] : [];
  operations.forEach(function () {
    var operation = findPreviousOperation((0, _head2["default"])(list).group_id, operations);
    if (operation) {
      list = [operation].concat(_toConsumableArray(list));
    }
  });
  return list;
};
var operationsTypesBlackList = ['conciliate'];
var operationsStatusBlackList = ['dropped', 'waiting'];
var rejectInvalidOperations = (0, _reject2["default"])((0, _either2["default"])((0, _propSatisfies2["default"])((0, _contains2["default"])(_2["default"], operationsTypesBlackList), 'type'), (0, _propSatisfies2["default"])((0, _contains2["default"])(_2["default"], operationsStatusBlackList), 'status')));
var manualReviewTimeout = (0, _both2["default"])((0, _propEq2["default"])('status', 'refused'), (0, _propEq2["default"])('refuse_reason', 'manual_review_timeout'));
var getStatus = function getStatus(transaction, manualReviewAnalysis) {
  if (transaction.status === 'pending_review') {
    return 'pending';
  }
  if (manualReviewTimeout(transaction)) {
    return 'timeout';
  }
  return manualReviewAnalysis.status;
};
var nameEqualPagarme = (0, _propEq2["default"])('name', 'pagarme');
var hasPagarmeAntifraudAnalysis = (0, _any2["default"])(nameEqualPagarme);
var findPagarmeAntifraudAnalysis = (0, _find2["default"])(nameEqualPagarme);
var getAntifraudAnalysis = (0, _ifElse2["default"])(hasPagarmeAntifraudAnalysis, findPagarmeAntifraudAnalysis, _head2["default"]);
var buildCreditCardOperations = function buildCreditCardOperations(_ref) {
  var antifraudAnalyses = _ref.antifraudAnalyses,
    chargebackOperations = _ref.chargebackOperations,
    gatewayOperations = _ref.gatewayOperations,
    transaction = _ref.transaction;
  var sortedGatewayOps = sortGatewayOperations(gatewayOperations);
  var filteredGatewayOps = rejectInvalidOperations(sortedGatewayOps);
  var filteredOps = filteredGatewayOps.filter(function (_ref2) {
    var internal = _ref2.internal;
    return !internal;
  });
  var normalizedChargebackOps = normalizeChargebackOps(chargebackOperations);
  var addAntifraudOperations = function addAntifraudOperations(operations, operation) {
    if (operation.type !== 'analyze') {
      return operations.concat([operation]);
    }
    var manualReviewAnalysis = (0, _find2["default"])((0, _propEq2["default"])('name', 'manual_review'), antifraudAnalyses);
    var hasManualReview = transaction.status === 'pending_review' || manualReviewTimeout(transaction) || manualReviewAnalysis;
    var analysis = getAntifraudAnalysis(antifraudAnalyses);
    var antifraudOperation = {
      created_at: operation.date_created,
      id: operation.id,
      status: analysis.status,
      type: operation.type
    };
    if (hasManualReview) {
      var manualReviewOperation = {
        created_at: operation.date_updated,
        id: operation.id,
        status: getStatus(transaction, manualReviewAnalysis),
        type: 'manual_review'
      };
      return operations.concat([antifraudOperation, manualReviewOperation]);
    }
    return operations.concat([antifraudOperation]);
  };
  return filteredOps.reduce(addAntifraudOperations, []).concat(normalizedChargebackOps);
};
var chooseOperations = (0, _pipe2["default"])((0, _ifElse2["default"])((0, _pathEq2["default"])(['transaction', 'payment_method'], 'boleto'), (0, _pipe2["default"])((0, _prop2["default"])('gatewayOperations'), sortGatewayOperations), buildCreditCardOperations), _reverse2["default"]);
var createOperationObj = (0, _applySpec2["default"])({
  id: (0, _prop2["default"])('id'),
  created_at: (0, _pipe2["default"])((0, _ifElse2["default"])((0, _has2["default"])('date_created'), (0, _prop2["default"])('date_created'), (0, _prop2["default"])('created_at')), (0, _unless2["default"])(_isNil2["default"], _moment["default"])),
  type: (0, _prop2["default"])('type'),
  status: (0, _ifElse2["default"])((0, _has2["default"])('status'), (0, _prop2["default"])('status'), (0, _always2["default"])('success')),
  cycle: (0, _propOr2["default"])(null, 'cycle')
});
var buildOperations = (0, _applySpec2["default"])({
  operations: (0, _pipe2["default"])(chooseOperations, (0, _map2["default"])(createOperationObj))
});
var _default = exports["default"] = buildOperations;
module.exports = exports["default"];