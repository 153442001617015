/**
 * wrapper addEventListener
 * {@link addEventListener|https://developer.mozilla.org/pt-BR/docs/Web/API/EventTarget/addEventListener}
 */
const createEvent = (eventName, handleEvent, options) => {
  if (!eventName) throw new Error('Argument eventName is required')
  if (!handleEvent) throw new Error('Argument handleEvent is required')
  if (typeof eventName !== 'string') throw new Error('eventName must be a string')
  if (typeof handleEvent !== 'function') throw new Error('handleEvent must be a function')
  if (options && typeof options !== 'object') throw new Error('options must be an object, if provided')

  try {
    document.addEventListener(eventName, handleEvent, options)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  const removeEvent = () => document.removeEventListener(eventName, handleEvent)

  return removeEvent
}

/**
 * wrapper dispatchEvent
 * @param eventName actionsEvents.enum.js - it's the name of event to handler
 * @param args args{} - arguments to use with handler event
 *
 * [CustomEvent] {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent}
 * [dispatchEvent] {@link https://developer.mozilla.org/pt-BR/docs/Web/API/EventTarget/dispatchEvent}
 */
const dispatchEvent = (eventName, args) => {
  if (!eventName) throw new Error('Argument eventName is required')
  if (typeof eventName !== 'string') throw new Error('eventName must be a string')
  if (args && typeof args !== 'object') throw new Error('args must be an object, if provided')

  const event = new CustomEvent(eventName, {
    detail: { ...args },
  })

  try {
    document.dispatchEvent(event)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

/**
 * This function create an custom events
 * @param {Object[]} events [{ eventName, handleEvent, options }]
 * @returns {Event} A Custom Event
 */
const createEvents = (events = []) => {
  const removeEvents = []
  events.forEach((event) => {
    const removeEventFn = createEvent(
      event.eventName,
      event.handleEvent,
      event.options
    )
    removeEvents.push(removeEventFn)
  })
  const removeAllEvents = () => (
    removeEvents.forEach(({ eventName, handleEvent }) => (
      document.removeEventListener(eventName, handleEvent)
    ))
  )
  return removeAllEvents
}

export { createEvent, createEvents, dispatchEvent }
