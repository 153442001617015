/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
import hasProperty from './hasProperty'

const MAX_DELAY = 64000

const delayIdentify = (userId, data, retryDelay) => (
  retryDelay <= MAX_DELAY && setTimeout(
    () => sendIdentify(userId, data, retryDelay * 2),
    retryDelay
  )
)

const sendIdentify = (userId, data, retryDelay) => {
  if (hasProperty(window.Appcues)) {
    window.Appcues.identify(userId, data)
  } else {
    delayIdentify(
      userId,
      data,
      retryDelay
    )
  }
}

/**
 * Identify User in Appcues
 *
 * @param {number} userId user id
 * @param {string} userEmail user email
 * @param {string} userName user name
 * @param {string} userDateCreated account created date
 * @param {string} userPermission user permission
 * @param {string} environment current environment
 */
export const identify = (
  userId,
  userEmail,
  userName,
  userDateCreated,
  userPermission,
  environment,
  retryDelay = 1000
) => {
  sendIdentify(
    userId,
    {
      environment,
      newUserDateCreated2: new Date(userDateCreated).toISOString(),
      userEmail,
      userName,
      userPermission,
    },
    retryDelay
  )
}

/**
 * Set Company data in Appcues session
 *
 * @param {string} companyId company id
 * @param {string} companyName company name
 * @param {string} companyDateCreated company created date
 * @param {string} companyStatus company status
 * @param {string} companyType company type
 * @param {number} userId user id
 *
 */
export const setCompany = (
  companyId,
  companyName,
  companyDateCreated,
  companyStatus,
  companyType,
  userId,
  retryDelay = 1000
) => {
  sendIdentify(
    userId,
    {
      companyId,
      companyName,
      companyStatus,
      companyType,
      newCompanyDateCreated: companyDateCreated,
    },
    retryDelay
  )
}

/**
 * Triggers appcues page change
 */
export const page = () => {
  if (hasProperty(window.Appcues)) {
    window.Appcues.page()
  }
}
