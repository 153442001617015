"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _length2 = _interopRequireDefault(require("ramda/src/length"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var subscriptionsSpec = {
  id: (0, _prop2["default"])('id'),
  status: (0, _prop2["default"])('status'),
  plan_name: (0, _path2["default"])(['plan', 'name']),
  date_created: (0, _prop2["default"])('date_created'),
  date_updated: (0, _prop2["default"])('date_updated'),
  payment_method: (0, _prop2["default"])('payment_method'),
  current_period_start: (0, _prop2["default"])('current_period_start'),
  current_period_end: (0, _prop2["default"])('current_period_end')
};
var mapSourceToTableRows = (0, _applySpec2["default"])(subscriptionsSpec);
var buildTableRows = function buildTableRows() {
  return function (subscriptions) {
    var formatted = (0, _map2["default"])((0, _pipe2["default"])((0, _prop2["default"])('_source'), mapSourceToTableRows), subscriptions);
    return formatted;
  };
};
var buildResult = function buildResult(query) {
  return (0, _applySpec2["default"])({
    offset: (0, _always2["default"])(query.offset),
    count: (0, _pipe2["default"])((0, _path2["default"])(['hits', 'hits']), _length2["default"]),
    rows: (0, _pipe2["default"])((0, _path2["default"])(['hits', 'hits']), buildTableRows(query)),
    total: (0, _path2["default"])(['hits', 'total'])
  });
};
var _default = exports["default"] = buildResult;
module.exports = exports["default"];