import { anyPass } from 'ramda'
import {
  ADD_ONBOARDING_ANSWERS,
  FETCHING_ONBOARDING_ANSWERS,
  ONBOARDING_ANSWERS_RECEIVE,
  ONBOARDING_ANSWERS_RESET,
  ONBOARDING_ANSWERS_FAIL,
  ONBOARDING_ANSWERS_COMPLETE,
} from './actions'
import isOnboardingComplete from '../../validation/isOnboardingComplete'
import isPaymentLink from '../../validation/isPaymentLink'
import isRecentlyCreatedCompany from '../../validation/recentCreatedCompany'

const makeInitialState = () => ({
  error: null,
  initialStep: 0,
  isOnboardingComplete: false,
  loading: true,
  onboardingAnswers: undefined,
})

export default function welcomeReducer (state = makeInitialState(), action) {
  switch (action.type) {
    case FETCHING_ONBOARDING_ANSWERS: {
      return {
        ...state,
        error: null,
        loading: true,
        onboardingAnswers: undefined,
      }
    }
    case ONBOARDING_ANSWERS_RECEIVE: {
      const {
        payload,
      } = action

      return {
        ...state,
        error: null,
        initialStep: Object.keys(payload).length,
        isOnboardingComplete: isOnboardingComplete(payload),
        loading: false,
        onboardingAnswers: payload,
      }
    }
    case ADD_ONBOARDING_ANSWERS: {
      const {
        payload,
      } = action

      return {
        ...state,
        onboardingAnswers: {
          ...state.onboardingAnswers,
          ...payload,
        },
      }
    }
    case ONBOARDING_ANSWERS_RESET: {
      return makeInitialState()
    }

    case ONBOARDING_ANSWERS_COMPLETE: {
      return {
        ...state,
        isOnboardingComplete: true,
      }
    }

    case ONBOARDING_ANSWERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default: {
      return state
    }
  }
}

const isOnboardingSkipped = () => localStorage.getItem('skip-onboarding')
const isPaymentLinkUser = ({ company }) => company && isPaymentLink(company)
const isDefaultUser = ({ company }) => company && company.type === 'default'
const isCompanyInbound = ({ company }) => company && company.type === 'inbound'
const isFetchOnboardingAnswersFailed = ({ welcome }) => !!welcome.error
const isTestEnvironment = ({ environment }) => environment === 'test'
const hasCompletedOnboarding = ({
  welcome,
}) => welcome.isOnboardingComplete
const isNotRecentlyCreatedCompany = ({
  company,
}) => company && !isRecentlyCreatedCompany({ company })

export const shouldSkipOnboarding = ({
  company,
  environment,
  welcome,
}) => anyPass([
  isDefaultUser,
  isCompanyInbound,
  isPaymentLinkUser,
  isOnboardingSkipped,
  isNotRecentlyCreatedCompany,
  isFetchOnboardingAnswersFailed,
  isTestEnvironment,
  hasCompletedOnboarding,
])({ company, environment, welcome })

