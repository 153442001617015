import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import copy from 'clipboard-copy'
import {
  Box, Button, Icon, IconButton, InputField, Text, Toast,
} from '@stone-payments/jade'

import { Trans } from 'react-i18next'
import useCountDown from 'react-countdown-hook'
import styles from './style.css'

const tPrefix = 'pages.settings.company.add_key.modal_created_key'

const CreatedKeyCard = ({ apiKey, onSubmit, t }) => {
  const [hasCopied, setHasCopied] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const [toastTimer, { reset, start }] = useCountDown(5000)

  const toastText = t(`${tPrefix}.toast_success`)

  useEffect(() => {
    if (toastTimer === 0) {
      setShowToast(false)
    }
  }, [toastTimer])

  const handleCopy = () => {
    copy(apiKey)
    setShowToast(true)
    setHasCopied(true)

    if (toastTimer > 0) {
      reset()
    } else {
      start()
    }
  }

  return (
    <>
      {showToast && (
        <div className={styles.toast}>
          <Toast
            leading={<Icon color="on-color" size="large" use="circle-check" />}
            onDismiss={() => setShowToast(false)}
            text={toastText}
          />
        </div>
      )}
      <Box
        className={styles.paddingBottom}
        title={t(`${tPrefix}.title`)}
        data-testid="box-created-key-card"
        hasPadding
      >
        <Text>
          <Trans
            i18nKey={`${tPrefix}.subtitle`}
            components={[<strong>text</strong>]}
          />
        </Text>
        <div className={styles.copyWrapper}>
          <div className={styles.input}>
            <InputField value={apiKey} disabled />
          </div>
          <div className={styles.button}>
            <IconButton
              variant="neutral-ghost"
              icon="action-copy"
              onClick={handleCopy}
            />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            disabled={!hasCopied}
            onClick={onSubmit}
            data-testid="button-finish"
          >
            {t(`${tPrefix}.button_finish`)}
          </Button>
          <Button
            variant="primary-solid"
            onClick={handleCopy}
            data-testid="button-copy"
          >
            {t(`${tPrefix}.button_copy`)}
          </Button>
        </div>
      </Box>
    </>
  )
}

CreatedKeyCard.propTypes = {
  apiKey: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default CreatedKeyCard
