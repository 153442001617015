"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _data = _interopRequireDefault(require("./data"));
var _operations = _interopRequireDefault(require("./operations"));
var _total = _interopRequireDefault(require("./total"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var _default = exports["default"] = {
  data: _data["default"],
  operations: _operations["default"],
  total: _total["default"]
};
module.exports = exports["default"];