"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var statusIsPendingTransferOrProcessing = (0, _either2["default"])((0, _propEq2["default"])('status', 'pending_transfer'), (0, _propEq2["default"])('status', 'processing'));
var handleTransferStatus = (0, _ifElse2["default"])(statusIsPendingTransferOrProcessing, (0, _always2["default"])('pending'), (0, _prop2["default"])('status'));
var statusIsNotTransferred = (0, _complement2["default"])((0, _propEq2["default"])('status', 'transferred'));
var formatDateWithMoment = function formatDateWithMoment(date) {
  return (0, _moment["default"])(date).format('DD/MM/YYYY HH:mm');
};
var formatDate = (0, _ifElse2["default"])(_isNil2["default"], (0, _always2["default"])(null), formatDateWithMoment);
var handleFundingDate = (0, _ifElse2["default"])(statusIsNotTransferred, (0, _always2["default"])(null), (0, _pipe2["default"])((0, _prop2["default"])('funding_date'), formatDate));
var transfersSpec = {
  amount: (0, _prop2["default"])('amount'),
  bank_code: (0, _ifElse2["default"])((0, _pipe2["default"])((0, _prop2["default"])('bank_account'), _isNil2["default"]), (0, _path2["default"])(['recipient', 'bank_account', 'bank_code']), (0, _path2["default"])(['bank_account', 'bank_code'])),
  bank_legal_name: (0, _ifElse2["default"])((0, _pipe2["default"])((0, _prop2["default"])('bank_account'), _isNil2["default"]), (0, _path2["default"])(['recipient', 'bank_account', 'legal_name']), (0, _path2["default"])(['bank_account', 'legal_name'])),
  date_created: (0, _pipe2["default"])((0, _prop2["default"])('date_created'), formatDate),
  fee: (0, _prop2["default"])('fee'),
  funding_date: handleFundingDate,
  id: (0, _prop2["default"])('id'),
  status: handleTransferStatus
};
var buildResult = (0, _map2["default"])((0, _applySpec2["default"])(transfersSpec));
var _default = exports["default"] = buildResult;
module.exports = exports["default"];