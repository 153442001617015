"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isWeekend = exports.getCalendarDate = void 0;
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _find2 = _interopRequireDefault(require("ramda/src/find"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var isWeekend = exports.isWeekend = function isWeekend(date) {
  return date.isoWeekday() === 6 || date.isoWeekday() === 7;
};
var getCalendarDate = exports.getCalendarDate = function getCalendarDate(calendar, date) {
  var key = date.format('YYYY-MM-DD');
  return (0, _find2["default"])((0, _propEq2["default"])('date', key), calendar.calendar);
};