import { Button } from 'former-kit'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.css'

const Welcome = ({ handleStartOnboarding, t }) => (
  <div className={styles.onboardingWelcome}>
    <div>
      <h3 className={styles.welcomeTitle}>{t('pages.onboarding.welcome.title')}</h3>
      <p className={styles.welcomeText}>{t('pages.onboarding.welcome.description')}</p>
    </div>
    <Button
      onClick={handleStartOnboarding}
      size="huge"
      fullWidth
    >
      {t('pages.onboarding.welcome.advance')}
    </Button>
  </div>
)

Welcome.propTypes = {
  handleStartOnboarding: PropTypes.func,
  t: PropTypes.func.isRequired,
}

Welcome.defaultProps = {
  handleStartOnboarding: () => { },
}

export default Welcome
