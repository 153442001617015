"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _pathEq2 = _interopRequireDefault(require("ramda/src/pathEq"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _uncurryN2 = _interopRequireDefault(require("ramda/src/uncurryN"));
var _contains2 = _interopRequireDefault(require("ramda/src/contains"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _find2 = _interopRequireDefault(require("ramda/src/find"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _formatCard = _interopRequireDefault(require("./formatCard"));
var _getAutomaticFee2 = _interopRequireDefault(require("./getAutomaticFee"));
var _getCompulsoryFee2 = _interopRequireDefault(require("./getCompulsoryFee"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var isNamePagarme = (0, _pipe2["default"])((0, _prop2["default"])('name'), (0, _equals2["default"])('pagarme'));
var getFeeAntifraud = (0, _pipe2["default"])((0, _pathOr2["default"])([], ['pricing', 'gateway', 'live', 'antifraud_cost']), (0, _find2["default"])(isNamePagarme));
var findPixAcquirer = (0, _find2["default"])((0, _pipe2["default"])((0, _propOr2["default"])([], 'payment_methods'), (0, _contains2["default"])('pix')));
var installmentsMdrZaoFormat = function installmentsMdrZaoFormat(anticipationFee, mdrInstallments) {
  var installments = [];
  var installmentBaseMdr = mdrInstallments[0].mdr;
  var _loop = function _loop(position) {
    if (position === 1) {
      installments.push(mdrInstallments[0]);
    } else {
      var installmentBase = mdrInstallments.find(function (item) {
        return item.installment === position;
      });
      if (installmentBase) {
        installmentBaseMdr = installmentBase.mdr;
      }
      installments.push(_extends({}, mdrInstallments[0], {
        installment: position,
        mdr: installmentBaseMdr + anticipationFee * position
      }));
    }
  };
  for (var position = 1; position <= 12; position += 1) {
    _loop(position);
  }
  return installments;
};
var buildMdrZao = function buildMdrZao(mdrs, anticipationFee) {
  var mdrInstallments = mdrs.map(function (mdr) {
    return _extends({}, mdr, {
      installments: installmentsMdrZaoFormat(anticipationFee, mdr.installments)
    });
  });
  return mdrInstallments;
};
var buildCard = (0, _uncurryN2["default"])(2, function (acquirers, company) {
  var acquirersPagarme = acquirers.find(function (acquirer) {
    return acquirer.name === 'pagarme';
  });
  var isMdrzao = (0, _pathOr2["default"])(false, ['capabilities', 'allow_transaction_anticipation'], company);
  var mdrs = (0, _pathOr2["default"])([], ['pricing', 'psp', 'live', 'mdrs'], company);
  var anticipationFee = (0, _pathOr2["default"])(0, ['pricing', 'psp', 'live', 'anticipation'], company);
  if (acquirersPagarme) {
    return {
      capture_methods: acquirersPagarme.capture_methods,
      payment_methods: acquirersPagarme.payment_methods,
      mdrs: isMdrzao ? buildMdrZao(mdrs, anticipationFee) : mdrs
    };
  }
  return {
    capture_methods: null,
    payment_methods: null,
    mdrs: null
  };
});
var buildPix = (0, _uncurryN2["default"])(2, function (acquirers, company) {
  var isPixEnabled = !!findPixAcquirer(acquirers);
  if (isPixEnabled) {
    return {
      paymentSpreadFee: (0, _pathOr2["default"])(null, ['pricing', 'gateway', 'live', 'pix', 'payment_spread_fee'], company),
      paymentFixedFee: (0, _pathOr2["default"])(null, ['pricing', 'gateway', 'live', 'pix', 'payment_fixed_fee'], company)
    };
  }
  return null;
});
var buildAntifraud = (0, _ifElse2["default"])((0, _pathEq2["default"])(['antifraud', 'live', 'enabled'], true), (0, _pipe2["default"])(getFeeAntifraud, (0, _pathOr2["default"])(null, ['cost'])), (0, _always2["default"])(null));
var buildFraudCoverageFee = (0, _ifElse2["default"])((0, _pathEq2["default"])(['antifraud', 'live', 'fraud_covered'], true), (0, _pathOr2["default"])(null, ['antifraud', 'live', 'fraud_coverage_fee']), (0, _always2["default"])(null));
var _default = exports["default"] = {
  getData: function getData(acquirers) {
    return (0, _applySpec2["default"])({
      anticipation: (0, _pathOr2["default"])(null, ['pricing', 'psp', 'live', 'anticipation']),
      antifraud: buildAntifraud,
      boleto: {
        paymentFixedFee: (0, _pathOr2["default"])(null, ['pricing', 'gateway', 'live', 'boletos', 'payment_fixed_fee']),
        paymentSpreadFee: (0, _pathOr2["default"])(null, ['pricing', 'gateway', 'live', 'boletos', 'payment_spread_fee'])
      },
      gateway: (0, _pathOr2["default"])(null, ['pricing', 'gateway', 'live', 'transaction_cost', 'credit_card']),
      fraudCoverageFee: buildFraudCoverageFee,
      card: buildCard(acquirers),
      pix: buildPix(acquirers),
      transfer: (0, _pathOr2["default"])(null, ['pricing', 'transfers', 'ted']),
      isMdrzao: (0, _pathOr2["default"])(false, ['capabilities', 'allow_transaction_anticipation']),
      anticipationDelay: (0, _pathOr2["default"])(null, ['pricing', 'psp', 'live', 'anticipation_delay']),
      getAutomaticFee: function getAutomaticFee() {
        return _getAutomaticFee2["default"];
      },
      getCompulsoryFee: function getCompulsoryFee() {
        return _getCompulsoryFee2["default"];
      }
    });
  },
  formatCard: _formatCard["default"]
};
module.exports = exports["default"];