"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _result = _interopRequireDefault(require("./result"));
var _query = _interopRequireDefault(require("./query"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var search = function search(client) {
  return function () {
    var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var query = (0, _query["default"])(filter);
    return client.search({
      type: 'payment_link',
      query: JSON.stringify(query)
    }).then(_result["default"]);
  };
};
var _default = exports["default"] = search;
module.exports = exports["default"];