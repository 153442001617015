"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _calculateInstallmentsAmount = _interopRequireDefault(require("../calculateInstallmentsAmount"));
var _business = require("../../../../business");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var buildInstallments = function buildInstallments(installmentsAmount, mdr, calendar) {
  return installmentsAmount.map(function (amount, index) {
    var installment = index + 1;
    var paymentDate = (0, _moment["default"])().add(30 * installment, 'd').startOf('day');
    if (calendar && (0, _business.isBusinessDay)(calendar, paymentDate) === false) {
      paymentDate = (0, _business.nextBusinessDay)(calendar, paymentDate);
    }
    return {
      amount: amount,
      fee: Math.abs(amount * mdr / 100),
      installmentNumber: installment,
      paymentDate: paymentDate
    };
  });
};
var getAutomaticFee = function getAutomaticFee(amount, mdr, numberOfInstallments, anticipationTax, anticipationDelay) {
  var calendar = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
  if (amount > 0) {
    var currentDate = (0, _moment["default"])().startOf('day').add(anticipationDelay, 'days');
    var installmentsAmount = (0, _calculateInstallmentsAmount["default"])(amount, numberOfInstallments);
    var installmentsPayload = buildInstallments(installmentsAmount, mdr, calendar);
    var anticipationPercentage = installmentsPayload.reduce(function (acc, installment) {
      var duration = Math.floor(_moment["default"].duration(installment.paymentDate.diff(currentDate)).asDays());
      var anticipationRate = (installment.amount - installment.fee) * (anticipationTax / 100) * duration / 30 / 100;
      var equivalentPercentage = 100 * (anticipationRate * 100) / amount;
      return acc + equivalentPercentage;
    }, 0);
    return Math.round((mdr + anticipationPercentage + Number.EPSILON) * 100) / 100;
  }
  return 0;
};
var _default = exports["default"] = getAutomaticFee;
module.exports = exports["default"];