import Cookies from 'js-cookie'
import decodeJwt from '../utils/helpers/decodeJwt'

/**
 * @typedef {'accessToken' | 'rd' | 'refreshToken'} CookieType
 */

export const DOMAINS = ['pagar.me', 'pagarme.net']
export const COOKIE_TYPE_MAP = {
  accessToken: 'mp_tk',
  rd: 'mp_rd',
  refreshToken: 'mp_rt',
}

/**
 * Get the current domain to handle cookies.
 *
 * @returns {string}
 */
export function getCookieDomain () {
  return DOMAINS.find(element => window.location.hostname.includes(element)) ?? ''
}

/**
 * Get token (JWT) expiration time left.
 * The returned number will be on milliseconds.
 *
 * @param {string} token
 * @returns {number}
 */
export function getTokenExpirationTimeLeft (token) {
  const EXPIRATION_GAP = 60 * 1000
  const decodedToken = decodeJwt(token)

  if (decodedToken) {
    const tokenExpirationTime = decodedToken.exp * 1000
    const tokenExpirationTimeLeft = tokenExpirationTime - Date.now()

    // It's necessary to subtract a margin time (gap) to ensure that
    // the new token is obtained before the old one expires
    if (tokenExpirationTimeLeft > EXPIRATION_GAP) {
      return tokenExpirationTimeLeft - EXPIRATION_GAP
    }
  }

  return 0
}

/**
 * Get auth Cookies for pagar.me applications.
 *
 * @param {CookieType} type
 * @returns {string|null}
 */
export function getAuthCookie (type) {
  if (type === 'refreshToken') {
    // Refresh token stored by Auth from pagar.me is an enconded JSON
    // This condition exists because this don't happen with others cookies
    const refreshTokenCookie = Cookies.get(COOKIE_TYPE_MAP[type])

    try {
      const decodedRefreshToken = decodeURIComponent(refreshTokenCookie)
      const parsedRefreshToken = JSON.parse(decodedRefreshToken)

      return parsedRefreshToken.refresh_token || null
    } catch {
      return null
    }
  }

  return Cookies.get(COOKIE_TYPE_MAP[type]) || null
}

/**
 * Stores new Cookies for pagar.me applications.
 *
 * @param {CookieType} type
 * @param {string} value
 * @returns {void}
 */
export function setAuthCookie (type, value) {
  const domain = getCookieDomain()

  if (type === 'refreshToken') {
    // Auth application from pagar.me stores refresh token as a JSON object
    // Based on that statement the value is transformed here to avoid breaking changes
    return Cookies.set(COOKIE_TYPE_MAP[type], JSON.stringify({ refresh_token: value }), { domain, path: '/' })
  }

  return Cookies.set(COOKIE_TYPE_MAP[type], value, { domain, path: '/' })
}

/**
 * Remove Cookies for pagar.me applications.
 *
 * @param {CookieType} type
 * @returns {void}
 */
export function removeAuthCookie (type) {
  const domain = getCookieDomain()

  Cookies.remove(COOKIE_TYPE_MAP[type], { domain, path: '/' })
}
