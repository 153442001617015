import React from 'react'
import {
  Route,
  Switch,
} from 'react-router-dom'

import { withError } from '../ErrorBoundary'
import list, { epic as listEpic } from './pages/List'
import details, { epic as detailsEpic } from './pages/Details'

import {
  PaymentLinksDetails,
  PaymentLinksList,
  PaymentLinksCreate,
} from './dynamicImports'

export const reducers = {
  details,
  list,
}

export const epics = {
  details: detailsEpic,
  list: listEpic,
}

const PaymentLinksRouter = () => (
  <Switch>
    <Route
      exact
      path="/payment-links/create"
      component={PaymentLinksCreate}
    />
    <Route
      component={PaymentLinksDetails}
      exact
      path="/payment-links/:id"
    />
    <Route
      path="/payment-links"
      component={PaymentLinksList}
    />
  </Switch>
)

export default withError(PaymentLinksRouter)
