/**
 * Decode a JSON Web Token.
 *
 * @param {string} token
 * @returns {Object|null}
 */
export default function decodeJwt (token) {
  try {
    const tokenParts = token.split('.')

    if (tokenParts.length !== 3) {
      throw new Error()
    }

    const decodedPayload = atob(
      tokenParts[1].replace(/-/g, '+').replace(/_/g, '/')
    )

    return JSON.parse(decodedPayload)
  } catch {
    return null
  }
}
