"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _isomorphicFetch = _interopRequireDefault(require("isomorphic-fetch"));
var _environment = require("../../environment");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var getChargebackReason = function getChargebackReason(reasonCode) {
  return (0, _isomorphicFetch["default"])("".concat(_environment.apiGanonUrl, "chargebacks/reason/").concat(reasonCode)).then(function (res) {
    if (!res.ok) {
      throw new Error('Failed to fetch');
    }
    return res.json();
  })["catch"](function () {
    return null;
  });
};
var _default = exports["default"] = getChargebackReason;
module.exports = exports["default"];