"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInstallment = exports["default"] = exports.buildOutgoing = exports.buildOutcoming = void 0;
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _reject2 = _interopRequireDefault(require("ramda/src/reject"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _shared = require("./shared");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var buildOutcoming = exports.buildOutcoming = (0, _juxt2["default"])([(0, _shared.transformMovementTypePropTo)(['amount'], 'payable')]);
var buildOutgoing = exports.buildOutgoing = (0, _pipe2["default"])((0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fraud_coverage_fee'], 'fraud_coverage_fee'), (0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'mdr'), (0, _shared.transformAndNegateMovementTypePropTo)(['anticipation_fee'], 'anticipation_fee')]), (0, _reject2["default"])((0, _propEq2["default"])('amount', 0)));
var getInstallment = exports.getInstallment = (0, _prop2["default"])('installment');
var _default = exports["default"] = (0, _shared.formatRows)({
  buildOutcoming: buildOutcoming,
  buildOutgoing: buildOutgoing,
  getInstallment: getInstallment
});