"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sumOutgoing = exports.sumOutcoming = exports.sumFees = exports.getPaymentMethodAggregations = exports.getPayablePropValueFromPayableTypes = exports["default"] = void 0;
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _mergeAll2 = _interopRequireDefault(require("ramda/src/mergeAll"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _sum2 = _interopRequireDefault(require("ramda/src/sum"));
var _flatten2 = _interopRequireDefault(require("ramda/src/flatten"));
var _reduce2 = _interopRequireDefault(require("ramda/src/reduce"));
var _pluck2 = _interopRequireDefault(require("ramda/src/pluck"));
var _pick2 = _interopRequireDefault(require("ramda/src/pick"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _when2 = _interopRequireDefault(require("ramda/src/when"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _values2 = _interopRequireDefault(require("ramda/src/values"));
var _negate2 = _interopRequireDefault(require("ramda/src/negate"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _lt2 = _interopRequireDefault(require("ramda/src/lt"));
var _gt2 = _interopRequireDefault(require("ramda/src/gt"));
var _filter2 = _interopRequireDefault(require("ramda/src/filter"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var getNegatives = (0, _filter2["default"])((0, _gt2["default"])(0));
var getPositives = (0, _filter2["default"])((0, _lt2["default"])(0));
var ensurePositive = Math.abs;
var ensureNegative = (0, _pipe2["default"])(ensurePositive, _negate2["default"]);
var getObjectValues = function getObjectValues(propName) {
  return (0, _pipe2["default"])((0, _propOr2["default"])({}, propName), _values2["default"]);
};
var getPayablePropValueFromPayableTypes = exports.getPayablePropValueFromPayableTypes = function getPayablePropValueFromPayableTypes(propName) {
  var types = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return (0, _pipe2["default"])((0, _when2["default"])(function () {
    return !(0, _isEmpty2["default"])(types);
  }, (0, _pick2["default"])(types)), (0, _pluck2["default"])(propName), _values2["default"]);
};
var getPositivePayablePropValues = function getPositivePayablePropValues(propName) {
  return (0, _pipe2["default"])(getPayablePropValueFromPayableTypes(propName), getPositives);
};
var getNegativePayablePropValues = function getNegativePayablePropValues(propName) {
  return (0, _pipe2["default"])(getPayablePropValueFromPayableTypes(propName), getNegatives);
};
var sumReduce = function sumReduce(reducer) {
  return (0, _reduce2["default"])(function (acc, val) {
    return acc + reducer(val);
  }, 0);
};
var sumFees = exports.sumFees = sumReduce((0, _pipe2["default"])((0, _juxt2["default"])([getPayablePropValueFromPayableTypes('fee'), getPayablePropValueFromPayableTypes('anticipation_fee'), getPayablePropValueFromPayableTypes('fraud_coverage_fee')]), _flatten2["default"], _sum2["default"], ensureNegative));
var sumOutgoing = exports.sumOutgoing = sumReduce((0, _pipe2["default"])(getNegativePayablePropValues('amount'), _sum2["default"]));
var sumOutcoming = exports.sumOutcoming = sumReduce((0, _pipe2["default"])(getPositivePayablePropValues('amount'), _sum2["default"]));
var sumNet = (0, _pipe2["default"])((0, _juxt2["default"])([sumOutcoming, sumOutgoing, sumFees]), _flatten2["default"], _sum2["default"]);
var getPaymentMethodAggregations = exports.getPaymentMethodAggregations = (0, _pipe2["default"])((0, _map2["default"])((0, _pipe2["default"])((0, _juxt2["default"])([getObjectValues('waiting_funds'), getObjectValues('prepaid'), getObjectValues('paid')]))), _flatten2["default"]);
var getPaymentMethodAggregationsPaid = (0, _pipe2["default"])((0, _map2["default"])((0, _pipe2["default"])((0, _juxt2["default"])([getObjectValues('paid')]))), _flatten2["default"]);
var buildPayablesTotal = (0, _pipe2["default"])(getPaymentMethodAggregations, (0, _applySpec2["default"])({
  fee: sumFees,
  outgoing: sumOutgoing,
  outcoming: sumOutcoming,
  net: sumNet
}));
var buildPayablesTotalPaid = (0, _pipe2["default"])(getPaymentMethodAggregationsPaid, (0, _applySpec2["default"])({
  fee: sumFees,
  outgoing: sumOutgoing,
  outcoming: sumOutcoming,
  net: sumNet
}));
var toArray = function toArray(value) {
  return [value];
};
var getDays = (0, _map2["default"])((0, _pipe2["default"])((0, _juxt2["default"])([(0, _applySpec2["default"])({
  date: (0, _prop2["default"])('date')
}), (0, _pipe2["default"])(toArray, buildPayablesTotal)]), _mergeAll2["default"]));
var getDaysPaid = (0, _map2["default"])((0, _pipe2["default"])((0, _juxt2["default"])([(0, _applySpec2["default"])({
  date: (0, _prop2["default"])('date')
}), (0, _pipe2["default"])(toArray, buildPayablesTotalPaid)]), _mergeAll2["default"]));
var getDates = (0, _pluck2["default"])('date');
var build = function build(data, onlyPaids) {
  return (0, _pipe2["default"])((0, _always2["default"])(data), (0, _juxt2["default"])([onlyPaids ? buildPayablesTotalPaid : buildPayablesTotal, (0, _applySpec2["default"])({
    dates: getDates,
    days: onlyPaids ? getDaysPaid : getDays
  })]), _mergeAll2["default"])();
};
var _default = exports["default"] = build;