"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _tap2 = _interopRequireDefault(require("ramda/src/tap"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _assoc2 = _interopRequireDefault(require("ramda/src/assoc"));
var _filter2 = _interopRequireDefault(require("ramda/src/filter"));
var _flatten2 = _interopRequireDefault(require("ramda/src/flatten"));
var _values2 = _interopRequireDefault(require("ramda/src/values"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _has2 = _interopRequireDefault(require("ramda/src/has"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _find2 = _interopRequireDefault(require("ramda/src/find"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _move2 = _interopRequireDefault(require("ramda/src/move"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _pick2 = _interopRequireDefault(require("ramda/src/pick"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _pluck2 = _interopRequireDefault(require("ramda/src/pluck"));
var _export = _interopRequireDefault(require("../shared/export"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
var globalMetadataKeys = [];
var getKeys = (0, _pluck2["default"])('key');
var filterProps = function filterProps(columns) {
  return (0, _pipe2["default"])((0, _applySpec2["default"])(_export["default"]), (0, _pick2["default"])(getKeys(columns)));
};
var formatArrayToCsv = function formatArrayToCsv(array) {
  return array.map(function (value) {
    return "\"".concat(value, "\"");
  }).join(',');
};
var mountHeaderWithLimiter = function mountHeaderWithLimiter(headers, limiter, exportType) {
  var message = ["\"# Exibindo apenas os primeiros ".concat(limiter.limit, " resultados de um total de ").concat(limiter.total, " encontrado\"")];
  var limit = limiter.limit,
    total = limiter.total;
  if (total > limit) {
    if (exportType === 'csv') {
      return [headers].concat([message]);
    }
    return [headers].concat([headers.map(function (_, i) {
      return i === 0 ? message : '';
    })]);
  }
  return [headers];
};
var buildExportArray = function buildExportArray(exportType, columns, limiter) {
  globalMetadataKeys = [];
  var lastColumn = (0, _last2["default"])(columns);
  var header = lastColumn.map(function (field) {
    return field.column;
  });
  var rows = columns.map(function (column) {
    return column.map(function (field) {
      return field.value;
    });
  });
  var file = mountHeaderWithLimiter(header, limiter, exportType);
  if (exportType === 'csv') {
    var headerCSV = formatArrayToCsv(header);
    var rowsCSV = rows.map(function (row) {
      return formatArrayToCsv(row);
    });
    var csvFile = mountHeaderWithLimiter(headerCSV, limiter, exportType);
    return csvFile.concat(rowsCSV).join('\r\n');
  }
  return file.concat(rows);
};
var getFilteredSource = function getFilteredSource(columns) {
  return (0, _pipe2["default"])((0, _prop2["default"])('_source'), filterProps(columns));
};
var moveMetadataToLastIndex = function moveMetadataToLastIndex(columns) {
  var metadataIndex = columns.findIndex(function (column) {
    return column.key === 'metadata';
  });
  if (metadataIndex !== -1) {
    return (0, _move2["default"])(metadataIndex, -1, columns);
  }
  return columns;
};
var transformMetadataToJSON = function transformMetadataToJSON(metadata) {
  if (metadata) {
    var valueParsedToJson = JSON.parse(metadata);
    var objectOfMetadata = {};
    Object.keys(valueParsedToJson).forEach(function (key) {
      try {
        objectOfMetadata[key] = JSON.parse(valueParsedToJson[key]);
      } catch (e) {
        objectOfMetadata[key] = valueParsedToJson[key];
      }
    });
    return objectOfMetadata;
  }
  return null;
};
var iterateGlobalMetadataKeys = function iterateGlobalMetadataKeys(obj) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  if (_typeof(obj) === 'object' && !(0, _isNil2["default"])(obj)) {
    var rootPath = !prefix ? '' : "".concat(prefix, ".");
    Object.keys(obj).forEach(function (key) {
      return iterateGlobalMetadataKeys(obj[key], rootPath + key);
    });
  } else if (globalMetadataKeys.indexOf(prefix) === -1) {
    globalMetadataKeys.push(prefix);
  }
};
var getValueInMetadata = function getValueInMetadata(metadata, metadataPath) {
  var pathSplited = metadataPath.split('.');
  return (0, _pathOr2["default"])('', pathSplited, metadata);
};
var breakMetadataInColumns = function breakMetadataInColumns(_ref) {
  var value = _ref.value;
  var objectOfMetadata = transformMetadataToJSON(value);
  var metadataInColumns = [];
  globalMetadataKeys.forEach(function (key) {
    return metadataInColumns.push({
      column: "metadata.".concat(key),
      key: "metadata.".concat(key),
      value: String(getValueInMetadata(objectOfMetadata, key))
    });
  });
  return metadataInColumns;
};
var getOnlyMetadataInColumns = (0, _pipe2["default"])((0, _find2["default"])((0, _propEq2["default"])('key', 'metadata_in_columns')), (0, _ifElse2["default"])((0, _has2["default"])('key'), breakMetadataInColumns, (0, _always2["default"])([])));
var hasNotKeyMetadataInColumns = function hasNotKeyMetadataInColumns(column) {
  return column.key !== 'metadata_in_columns';
};
var buildMetadaColumns = (0, _pipe2["default"])((0, _applySpec2["default"])({
  data: (0, _filter2["default"])(hasNotKeyMetadataInColumns),
  metadata: getOnlyMetadataInColumns
}), _values2["default"], _flatten2["default"]);
var buildColumnValues = function buildColumnValues(data) {
  return function (column) {
    return (0, _assoc2["default"])('value', (0, _prop2["default"])((0, _prop2["default"])('key', column), data), column);
  };
};
var buildColumns = function buildColumns(columns) {
  return function (exportData) {
    return (0, _map2["default"])((0, _pipe2["default"])((0, _applySpec2["default"])({
      column: (0, _prop2["default"])('value'),
      key: (0, _prop2["default"])('key')
    }), buildColumnValues(exportData)), columns);
  };
};
var buildRows = function buildRows(columns, exportData) {
  return (0, _map2["default"])((0, _pipe2["default"])(getFilteredSource(columns), buildColumns(columns), buildMetadaColumns, moveMetadataToLastIndex), exportData);
};
var buildData = function buildData(exportType, columns, limiter) {
  return function (exportData) {
    var exportRows = buildRows(columns, exportData);
    return buildExportArray(exportType, exportRows, limiter);
  };
};
var buildGlobalMetadataKeys = function buildGlobalMetadataKeys(columns) {
  return function (exportData) {
    return (0, _map2["default"])((0, _pipe2["default"])(getFilteredSource(columns), (0, _prop2["default"])('metadata_in_columns'), transformMetadataToJSON, iterateGlobalMetadataKeys), exportData);
  };
};
var buildResult = function buildResult(exportType, columns, limiter) {
  return (0, _pipe2["default"])((0, _path2["default"])(['hits', 'hits']), (0, _tap2["default"])(buildGlobalMetadataKeys(columns)), buildData(exportType, columns, limiter));
};
var _default = exports["default"] = buildResult;
module.exports = exports["default"];