"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var query = function query(_ref) {
  var end = _ref.end,
    start = _ref.start;
  return {
    query: {
      bool: {
        must: [{
          range: {
            date_created: {
              lte: end,
              gte: start
            }
          }
        }, {
          terms: {
            payment_method: ['credit_card', 'debit_card']
          }
        }]
      }
    },
    aggs: {
      paid: {
        filter: {
          term: {
            status: 'paid'
          }
        }
      }
    },
    size: 500,
    from: 0
  };
};
var _default = exports["default"] = query;
module.exports = exports["default"];