"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function getCapabilities(property, object) {
  return object[property];
}
var capabilities = function capabilities(company) {
  return getCapabilities('capabilities', company);
};
var _default = exports["default"] = capabilities;
module.exports = exports["default"];