"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _query = _interopRequireDefault(require("./query"));
var _result = _interopRequireDefault(require("./result"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var boleto = function boleto(client, rangeDates) {
  return client.search({
    query: JSON.stringify((0, _query["default"])(_extends({}, rangeDates, {
      paymentMethod: 'boleto'
    }))),
    type: 'transaction',
    search_type: 'count'
  }).then(_result["default"]);
};
var pix = function pix(client, rangeDates) {
  return client.search({
    query: JSON.stringify((0, _query["default"])(_extends({}, rangeDates, {
      paymentMethod: 'pix'
    }))),
    type: 'transaction',
    search_type: 'count'
  }).then(_result["default"]);
};
var _default = exports["default"] = {
  boleto: boleto,
  pix: pix
};
module.exports = exports["default"];