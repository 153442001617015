"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transactionSpec = exports.isInternational = exports.getMetadataProp = exports.getCustomerProp = exports.getCardProp = exports.getAntifraudProp = void 0;
var _subtract2 = _interopRequireDefault(require("ramda/src/subtract"));
var _sum2 = _interopRequireDefault(require("ramda/src/sum"));
var _apply2 = _interopRequireDefault(require("ramda/src/apply"));
var _identity2 = _interopRequireDefault(require("ramda/src/identity"));
var _startsWith2 = _interopRequireDefault(require("ramda/src/startsWith"));
var _find2 = _interopRequireDefault(require("ramda/src/find"));
var _keys2 = _interopRequireDefault(require("ramda/src/keys"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _both2 = _interopRequireDefault(require("ramda/src/both"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _when2 = _interopRequireDefault(require("ramda/src/when"));
var _propSatisfies2 = _interopRequireDefault(require("ramda/src/propSatisfies"));
var _reject2 = _interopRequireDefault(require("ramda/src/reject"));
var _of2 = _interopRequireDefault(require("ramda/src/of"));
var _flatten2 = _interopRequireDefault(require("ramda/src/flatten"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _replace2 = _interopRequireDefault(require("ramda/src/replace"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _props2 = _interopRequireDefault(require("ramda/src/props"));
var _splitAt2 = _interopRequireDefault(require("ramda/src/splitAt"));
var _join2 = _interopRequireDefault(require("ramda/src/join"));
var _length2 = _interopRequireDefault(require("ramda/src/length"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _T2 = _interopRequireDefault(require("ramda/src/T"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _has2 = _interopRequireDefault(require("ramda/src/has"));
var _is2 = _interopRequireDefault(require("ramda/src/is"));
var _allPass2 = _interopRequireDefault(require("ramda/src/allPass"));
var _cond2 = _interopRequireDefault(require("ramda/src/cond"));
var _unless2 = _interopRequireDefault(require("ramda/src/unless"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var isInternational = exports.isInternational = (0, _complement2["default"])(_equals2["default"])('BRAZIL');
var isAntifraudScoreNil = (0, _pipe2["default"])((0, _prop2["default"])('antifraud_score'), _isNil2["default"]);
var isRefuseReasonNil = (0, _pipe2["default"])((0, _prop2["default"])('refuse_reason'), _isNil2["default"]);
var stringToMoment = (0, _unless2["default"])(_isNil2["default"], _momentTimezone["default"]);
var getCardProp = exports.getCardProp = function getCardProp(subProp) {
  return (0, _cond2["default"])([[(0, _pipe2["default"])((0, _prop2["default"])('card'), (0, _allPass2["default"])([(0, _is2["default"])(Object), (0, _has2["default"])(subProp)])), (0, _path2["default"])(['card', subProp])], [(0, _has2["default"])("card_".concat(subProp)), (0, _prop2["default"])("card_".concat(subProp))], [_T2["default"], (0, _always2["default"])(null)]]);
};
var isRefuseReasonAntifraud = (0, _pipe2["default"])((0, _prop2["default"])('refuse_reason'), (0, _equals2["default"])('antifraud'));
var antifraudRecommendation = (0, _cond2["default"])([[isAntifraudScoreNil, (0, _always2["default"])(null)], [isRefuseReasonNil, (0, _always2["default"])(null)], [isRefuseReasonAntifraud, (0, _always2["default"])('refused')], [_T2["default"], (0, _always2["default"])('approved')]]);
var getAntifraudProp = exports.getAntifraudProp = (0, _ifElse2["default"])((0, _pipe2["default"])((0, _prop2["default"])('antifraud_score'), _isNil2["default"]), (0, _always2["default"])(null), (0, _applySpec2["default"])({
  recommendation: antifraudRecommendation,
  score: (0, _prop2["default"])('antifraud_score')
}));
var getCustomerSubProp = function getCustomerSubProp(subProp) {
  return (0, _pathOr2["default"])(null, ['customer', subProp]);
};
var isEmptyCustomerProp = function isEmptyCustomerProp(propName) {
  return (0, _pipe2["default"])(getCustomerSubProp(propName), _isNil2["default"]);
};
var formatPhoneNumber = function formatPhoneNumber(number) {
  if (!number) return '';
  var len = (0, _length2["default"])(number) - 4;
  return (0, _join2["default"])('-', (0, _splitAt2["default"])(len, number));
};
var formatPhoneProp = (0, _pipe2["default"])((0, _props2["default"])(['ddd', 'number']), (0, _juxt2["default"])([(0, _pipe2["default"])(_head2["default"], function (h) {
  return "(".concat(h, ")");
}), (0, _pipe2["default"])(_last2["default"], formatPhoneNumber)]), (0, _join2["default"])(' '));
var getPhoneProp = (0, _pipe2["default"])((0, _prop2["default"])('phone'), (0, _ifElse2["default"])((0, _either2["default"])(_isNil2["default"], _isEmpty2["default"]), (0, _always2["default"])(null), formatPhoneProp));
var phoneGroupsRegex = /(\+\d{2})(\d{2})(\d{4,5})(\d{4})/;
var formatPhone = function formatPhone(phone) {
  if (!phone) {
    return null;
  }
  return (0, _replace2["default"])(phoneGroupsRegex, '$1 ($2) $3-$4', phone);
};
var getFormatedPhones = (0, _pipe2["default"])(getCustomerSubProp('phone_numbers'), (0, _unless2["default"])(_isNil2["default"], (0, _map2["default"])(formatPhone)));
var getPhones = (0, _pipe2["default"])((0, _juxt2["default"])([(0, _pipe2["default"])(getPhoneProp, _of2["default"]), getFormatedPhones]), _flatten2["default"], (0, _reject2["default"])(_isNil2["default"]));
var rejectInvalidDocument = (0, _reject2["default"])((0, _either2["default"])(_isNil2["default"], (0, _propSatisfies2["default"])(_isNil2["default"], 'number')));
var getDocuments = (0, _pipe2["default"])((0, _prop2["default"])('customer'), (0, _juxt2["default"])([(0, _pipe2["default"])((0, _applySpec2["default"])({
  number: (0, _prop2["default"])('document_number'),
  type: (0, _prop2["default"])('document_type')
}), _of2["default"]), (0, _prop2["default"])('documents')]), _flatten2["default"], rejectInvalidDocument);
var getAddress = (0, _ifElse2["default"])((0, _propSatisfies2["default"])(_isNil2["default"], 'address'), (0, _path2["default"])(['billing', 'address']), (0, _prop2["default"])('address'));
var getCustomerProp = exports.getCustomerProp = (0, _ifElse2["default"])((0, _pipe2["default"])((0, _prop2["default"])('customer'), (0, _either2["default"])(_isNil2["default"], _isEmpty2["default"])), (0, _always2["default"])(null), (0, _applySpec2["default"])({
  address: getAddress,
  birthday: (0, _pipe2["default"])((0, _ifElse2["default"])(isEmptyCustomerProp('born_at'), getCustomerSubProp('birthday'), getCustomerSubProp('born_at')), stringToMoment),
  country: getCustomerSubProp('country'),
  created_at: getCustomerSubProp('date_created'),
  documents: getDocuments,
  email: getCustomerSubProp('email'),
  external_id: getCustomerSubProp('external_id'),
  id: getCustomerSubProp('id'),
  individual: getCustomerSubProp('individual'),
  name: getCustomerSubProp('name'),
  object: getCustomerSubProp('object'),
  phones: getPhones
}));
var getCaptureMethod = (0, _ifElse2["default"])((0, _pipe2["default"])(getCardProp('capture_method'), _isNil2["default"]), (0, _prop2["default"])('capture_method'), getCardProp('capture_method'));
var buildCard = (0, _when2["default"])((0, _has2["default"])('card_holder_name'), (0, _applySpec2["default"])({
  brand_name: getCardProp('brand'),
  capture_method: getCaptureMethod,
  first_digits: getCardProp('first_digits'),
  funding_source: getCardProp('funding_source'),
  holder_name: getCardProp('holder_name'),
  international: (0, _pipe2["default"])(getCardProp('country'), isInternational),
  last_digits: getCardProp('last_digits'),
  pin_mode: getCardProp('pin_mode')
}));
var getSubscription = (0, _applySpec2["default"])({
  id: (0, _propOr2["default"])(null, 'subscription_id')
});
var isRefundedBeforeCaptured = (0, _both2["default"])((0, _propEq2["default"])('status', 'refunded'), (0, _propEq2["default"])('paid_amount', 0));
var getMetadataProp = exports.getMetadataProp = (0, _ifElse2["default"])((0, _propSatisfies2["default"])(_isNil2["default"], 'metadata'), (0, _pipe2["default"])((0, _juxt2["default"])([(0, _pipe2["default"])(_keys2["default"], (0, _find2["default"])((0, _startsWith2["default"])('metadata_'))), _identity2["default"]]), (0, _apply2["default"])(_prop2["default"])), (0, _prop2["default"])('metadata'));
var getPixProp = (0, _ifElse2["default"])((0, _propEq2["default"])('payment_method', 'pix'), (0, _applySpec2["default"])({
  expiration_date: (0, _prop2["default"])('pix_expiration_date'),
  qr_code: (0, _prop2["default"])('pix_qr_code'),
  end_to_end_id: (0, _pathOr2["default"])(null, ['pix_data', 'end_to_end_id']),
  payer: (0, _ifElse2["default"])((0, _propSatisfies2["default"])((0, _complement2["default"])(_isNil2["default"]), 'pix_data'), (0, _applySpec2["default"])({
    bank_account: {
      account_number: (0, _path2["default"])(['pix_data', 'payer', 'bank_account', 'account_number']),
      bank_name: (0, _path2["default"])(['pix_data', 'payer', 'bank_account', 'bank_name']),
      branch_code: (0, _path2["default"])(['pix_data', 'payer', 'bank_account', 'branch_code']),
      ispb: (0, _path2["default"])(['pix_data', 'payer', 'bank_account', 'ispb'])
    },
    document: (0, _path2["default"])(['pix_data', 'payer', 'document']),
    document_type: (0, _path2["default"])(['pix_data', 'payer', 'document_type']),
    name: (0, _path2["default"])(['pix_data', 'payer', 'name'])
  }), (0, _always2["default"])(null))
}), (0, _always2["default"])(null));
var transactionSpec = exports.transactionSpec = {
  acquirer: {
    name: (0, _prop2["default"])('acquirer_name'),
    response_code: (0, _prop2["default"])('acquirer_response_code'),
    response_message: (0, _prop2["default"])('acquirer_response_message'),
    sequence_number: (0, _prop2["default"])('nsu'),
    transaction_id: (0, _prop2["default"])('tid')
  },
  amount: (0, _prop2["default"])('amount'),
  antifraud: getAntifraudProp,
  authorization_code: (0, _prop2["default"])('authorization_code'),
  boleto: (0, _ifElse2["default"])((0, _propEq2["default"])('payment_method', 'boleto'), (0, _applySpec2["default"])({
    barcode: (0, _prop2["default"])('boleto_barcode'),
    due_date: (0, _pipe2["default"])((0, _prop2["default"])('boleto_expiration_date'), stringToMoment),
    url: (0, _prop2["default"])('boleto_url')
  }), (0, _always2["default"])(null)),
  card: buildCard,
  created_at: (0, _pipe2["default"])((0, _prop2["default"])('date_created'), stringToMoment),
  customer: getCustomerProp,
  external_id: (0, _propOr2["default"])(null, 'reference_key'),
  id: (0, _prop2["default"])('id'),
  items: (0, _prop2["default"])('items'),
  metadata: getMetadataProp,
  payment: {
    authorized_amount: (0, _prop2["default"])('authorized_amount'),
    cost_amount: (0, _prop2["default"])('cost'),
    installments: (0, _prop2["default"])('installments'),
    method: (0, _prop2["default"])('payment_method'),
    net_amount: (0, _pipe2["default"])((0, _juxt2["default"])([(0, _ifElse2["default"])(isRefundedBeforeCaptured, (0, _prop2["default"])('authorized_amount'), (0, _prop2["default"])('paid_amount')), (0, _pipe2["default"])((0, _props2["default"])(['cost', 'refunded_amount']), _sum2["default"])]), (0, _apply2["default"])(_subtract2["default"])),
    paid_amount: (0, _prop2["default"])('paid_amount'),
    refund_amount: (0, _prop2["default"])('refunded_amount')
  },
  pix: getPixProp,
  postback_url: (0, _ifElse2["default"])((0, _propSatisfies2["default"])((0, _either2["default"])(_isNil2["default"], _isEmpty2["default"]), 'postback_url'), (0, _always2["default"])(null), (0, _prop2["default"])('postback_url')),
  risk_level: (0, _prop2["default"])('risk_level'),
  split_rules: (0, _prop2["default"])('split_rules'),
  referer: (0, _prop2["default"])('referer'),
  soft_descriptor: (0, _prop2["default"])('soft_descriptor'),
  status: (0, _ifElse2["default"])((0, _propEq2["default"])('fraud_reimbursed', true), (0, _always2["default"])('fraud_reimbursed'), (0, _prop2["default"])('status')),
  status_reason: (0, _ifElse2["default"])((0, _propEq2["default"])('status', 'refused'), (0, _prop2["default"])('refuse_reason'), (0, _prop2["default"])('status_reason')),
  shipping: (0, _prop2["default"])('shipping'),
  subscription: (0, _ifElse2["default"])((0, _propSatisfies2["default"])((0, _complement2["default"])(_isNil2["default"]), 'subscription_id'), getSubscription, (0, _always2["default"])(null)),
  updated_at: (0, _pipe2["default"])((0, _prop2["default"])('date_updated'), (0, _unless2["default"])(_isNil2["default"], _momentTimezone["default"]))
};