import qs from "qs";
import resources from "./resources";
export function paramsSerializer(params) {
  return qs.stringify(params, {
    indices: false
  });
}
const apiClient = options => {
  const axiosConfig = {
    baseURL: options.baseURL,
    params: {},
    paramsSerializer
  };
  axiosConfig.headers = {
    'Accept-Language': 'pt-BR',
    'X-Live': options.isSandbox ? 0 : 1,
    'is_sandbox': options.isSandbox
  };
  if (options.impersonateKey) {
    axiosConfig.params.impersonation_key = options.companyId;
  }
  if (options.authentication.session_id) {
    axiosConfig.params.session_id = options.authentication.session_id;
  } else {
    axiosConfig.headers.Authorization = "Bearer ".concat(options.authentication.jwt);
  }
  return Object.assign(Object.assign({}, resources(axiosConfig, options)), {
    axiosHeaders: axiosConfig.headers
  });
};
export default apiClient;