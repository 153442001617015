import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, IconShape, Spinner, Text,
} from '@stone-payments/jade'

import { format, toDate } from 'date-fns'
import styles from './styles.css'
import Card from '../../../../components/Card'

const convertTime = time => format(toDate(time, { timeZone: 'UTC' }), 'mm:ss')

const CardQrCode = ({
  isExpired,
  isLoading,
  onClickToCreateNewQrCode,
  qrcode,
  t,
  time,
}) => {
  const tPrefix = 'pages.kyc.card_qr_code'

  const tips = [
    { icon: 'device-phone', label: t(`${tPrefix}.tip_item_1`) },
    { icon: 'star', label: t(`${tPrefix}.tip_item_2`) },
    { icon: 'person-check', label: t(`${tPrefix}.tip_item_3`) },
  ]

  return (
    <Card>
      <Text variant="text-large" color="high" weight="semibold">
        {t(`${tPrefix}.title`)}
      </Text>
      <div className={styles.qrCodeContainer}>
        <div className={styles.leftColumn}>
          <Text variant="overline" color="medium">
            {t(`${tPrefix}.tip_title`)}
          </Text>
          <div className={styles.list}>
            {tips.map(tip => (
              <div key={tip.icon} className={styles.listItem}>
                <IconShape size="small" use={tip.icon} variant="brand" />
                <Text weight="medium">{tip.label}</Text>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.qrcodeColumn}>
          {!isLoading && (
            <img
              className={styles.qrcodeImage}
              src={`data:image/png;base64,${qrcode}`}
              style={{ opacity: isExpired ? 0.1 : 1 }}
              alt="QRCode"
            />
          )}
          {(isExpired || isLoading) && (
            <div className={styles.expiredWrapper}>
              {!isLoading && (
                <Button
                  icon="action-refresh"
                  variant="primary-solid"
                  size="small"
                  onClick={onClickToCreateNewQrCode}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  {t(`${tPrefix}.request_new_qrcode`)}
                </Button>
              )}
              {isLoading && (
                <Spinner size="xlarge" color="brand" />
              )}
            </div>
          )}
          {!isExpired && !isLoading && (
            <div className={styles.expiresWrapper}>
              <Spinner size="small" color="brand" />
              <div className={styles.expiresText}>
                <Text color="medium">{t(`${tPrefix}.expires_at`)}</Text>
                <Text color="brand" weight="semibold">
                  {convertTime(time)}
                </Text>
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

CardQrCode.propTypes = {
  isExpired: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClickToCreateNewQrCode: PropTypes.func,
  qrcode: PropTypes.string,
  t: PropTypes.func.isRequired,
  time: PropTypes.number,
}

CardQrCode.defaultProps = {
  isExpired: false,
  isLoading: false,
  onClickToCreateNewQrCode: null,
  qrcode: null,
  time: 0,
}

export default CardQrCode
