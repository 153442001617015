"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zeroMovementAmount = exports.tedTransferOutgoing = exports.tedTransferOutcoming = exports.settlementOutgoing = exports.settlementCreditOutcoming = exports.refundOrChargeBackOutgoing = exports.refundOrChargeBackOutcoming = exports.pixTransferOutgoing = exports.pixTransferOutcoming = exports.isTedTransfer = exports.isSettlement = exports.isRefundReversal = exports.isRefundOrChargeBack = exports.isPixTransfer = exports.isInterRecipientTransfer = exports.isFeeCollection = exports.isExternalsettlement = exports.isDocTransfer = exports.isCreditTransfer = exports.isCredit = exports.isChargebackRefund = exports.isBoletoRefund = exports.interRecipientTransferOutgoing = exports.interRecipientTransferOutcoming = exports.getInstallment = exports.externalSettlementOutgoing = exports.docTransferOutgoing = exports.docTransferOutcoming = exports["default"] = exports.creditTransferOutgoing = exports.creditTransferOutcoming = exports.creditOutgoing = exports.creditOutcoming = exports.buildOutgoing = exports.buildOutcoming = exports.boletoRefundFeeOutgoing = exports.boletoRefundFeeOutcoming = void 0;
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _of2 = _interopRequireDefault(require("ramda/src/of"));
var _cond2 = _interopRequireDefault(require("ramda/src/cond"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _reject2 = _interopRequireDefault(require("ramda/src/reject"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _includes2 = _interopRequireDefault(require("ramda/src/includes"));
var _pathSatisfies2 = _interopRequireDefault(require("ramda/src/pathSatisfies"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _both2 = _interopRequireDefault(require("ramda/src/both"));
var _pathEq2 = _interopRequireDefault(require("ramda/src/pathEq"));
var _shared = require("./shared");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var compareMovementTypeTo = function compareMovementTypeTo(type) {
  return (0, _pathEq2["default"])(['movement_object', 'type'], type);
};
var isChargebackRefund = exports.isChargebackRefund = (0, _both2["default"])((0, _propEq2["default"])('type', 'payable'), compareMovementTypeTo('chargeback_refund'));
var isRefundOrChargeBack = exports.isRefundOrChargeBack = (0, _pathSatisfies2["default"])((0, _includes2["default"])(_2["default"], ['chargeback', 'refund']), ['movement_object', 'type']);
var isRefundReversal = exports.isRefundReversal = (0, _both2["default"])((0, _propEq2["default"])('type', 'payable'), compareMovementTypeTo('refund_reversal'));
var refundOrChargeBackOutcoming = exports.refundOrChargeBackOutcoming = (0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'mdr')]);
var refundOrChargeBackOutgoing = exports.refundOrChargeBackOutgoing = (0, _juxt2["default"])([(0, _shared.transformMovementTypePropTo)(['amount'], 'payable')]);
var isTedTransfer = exports.isTedTransfer = (0, _both2["default"])((0, _propEq2["default"])('type', 'transfer'), compareMovementTypeTo('ted'));
var isPixTransfer = exports.isPixTransfer = (0, _both2["default"])((0, _propEq2["default"])('type', 'transfer'), compareMovementTypeTo('pix'));
var isDocTransfer = exports.isDocTransfer = (0, _both2["default"])((0, _propEq2["default"])('type', 'transfer'), compareMovementTypeTo('doc'));
var isCreditTransfer = exports.isCreditTransfer = (0, _both2["default"])((0, _propEq2["default"])('type', 'transfer'), compareMovementTypeTo('credito_em_conta'));
var isExternalsettlement = exports.isExternalsettlement = (0, _propEq2["default"])('type', 'external_settlement');
var isSettlement = exports.isSettlement = (0, _propEq2["default"])('type', 'settlement');
var zeroMovementAmount = exports.zeroMovementAmount = (0, _always2["default"])({
  amount: 0,
  type: 'payable'
});
var tedTransferOutgoing = exports.tedTransferOutgoing = (0, _ifElse2["default"])((0, _shared.isNegative)('amount'), (0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'tedFee'), (0, _shared.transformMovementTypePropTo)(['amount'], 'payable')]), (0, _juxt2["default"])([zeroMovementAmount]));
var pixTransferOutgoing = exports.pixTransferOutgoing = (0, _ifElse2["default"])((0, _shared.isNegative)('amount'), (0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'tedFee'), (0, _shared.transformMovementTypePropTo)(['amount'], 'payable')]), (0, _juxt2["default"])([zeroMovementAmount]));
var docTransferOutgoing = exports.docTransferOutgoing = (0, _ifElse2["default"])((0, _shared.isNegative)('amount'), (0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'tedFee'), (0, _shared.transformMovementTypePropTo)(['amount'], 'payable')]), (0, _juxt2["default"])([zeroMovementAmount]));
var tedTransferOutcoming = exports.tedTransferOutcoming = (0, _ifElse2["default"])((0, _shared.isNegative)('amount'), (0, _juxt2["default"])([zeroMovementAmount]), (0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'tedFee'), (0, _shared.transformMovementTypePropTo)(['amount'], 'payable')]));
var pixTransferOutcoming = exports.pixTransferOutcoming = (0, _ifElse2["default"])((0, _shared.isNegative)('amount'), (0, _juxt2["default"])([zeroMovementAmount]), (0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'tedFee'), (0, _shared.transformMovementTypePropTo)(['amount'], 'payable')]));
var docTransferOutcoming = exports.docTransferOutcoming = (0, _ifElse2["default"])((0, _shared.isNegative)('amount'), (0, _juxt2["default"])([zeroMovementAmount]), (0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'tedFee'), (0, _shared.transformMovementTypePropTo)(['amount'], 'payable')]));
var creditTransferOutgoing = exports.creditTransferOutgoing = (0, _juxt2["default"])([(0, _ifElse2["default"])((0, _shared.isNegative)('amount'), (0, _shared.transformMovementTypePropTo)(['amount'], 'payable'), zeroMovementAmount)]);
var externalSettlementOutgoing = exports.externalSettlementOutgoing = (0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'external_settlement')]);
var settlementOutgoing = exports.settlementOutgoing = (0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'settlement')]);
var creditTransferOutcoming = exports.creditTransferOutcoming = (0, _juxt2["default"])([(0, _ifElse2["default"])((0, _shared.isNegative)('amount'), zeroMovementAmount, (0, _shared.transformMovementTypePropTo)(['amount'], 'payable'))]);
var isInterRecipientTransfer = exports.isInterRecipientTransfer = (0, _both2["default"])((0, _propEq2["default"])('type', 'transfer'), compareMovementTypeTo('inter_recipient'));
var interRecipientTransferOutcoming = exports.interRecipientTransferOutcoming = (0, _juxt2["default"])([(0, _ifElse2["default"])((0, _shared.isNegative)('amount'), zeroMovementAmount, (0, _shared.transformMovementTypePropTo)(['amount'], 'payable'))]);
var interRecipientTransferOutgoing = exports.interRecipientTransferOutgoing = (0, _juxt2["default"])([(0, _ifElse2["default"])((0, _shared.isNegative)('amount'), (0, _shared.transformMovementTypePropTo)(['amount'], 'payable'), zeroMovementAmount)]);
var isBoletoRefund = exports.isBoletoRefund = (0, _both2["default"])((0, _propEq2["default"])('type', 'refund'), compareMovementTypeTo('boleto'));
var boletoRefundFeeOutgoing = exports.boletoRefundFeeOutgoing = (0, _juxt2["default"])([(0, _shared.transformAndNegateMovementTypePropTo)(['fee'], 'tedFee')]);
var boletoRefundFeeOutcoming = exports.boletoRefundFeeOutcoming = (0, _juxt2["default"])([(0, _shared.transformMovementTypePropTo)(['amount'], 'payable')]);
var isCredit = exports.isCredit = (0, _both2["default"])((0, _propEq2["default"])('type', 'payable'), compareMovementTypeTo('credit'));
var creditOutcoming = exports.creditOutcoming = (0, _juxt2["default"])([(0, _shared.transformMovementTypePropTo)(['movement_object', 'amount'], 'payable')]);
var settlementCreditOutcoming = exports.settlementCreditOutcoming = (0, _juxt2["default"])([(0, _shared.transformMovementTypePropTo)(['amount'], 'payable')]);
var creditOutgoing = exports.creditOutgoing = (0, _pipe2["default"])((0, _juxt2["default"])([(0, _ifElse2["default"])((0, _either2["default"])((0, _pathEq2["default"])(['movement_object', 'payment_method'], 'boleto'), (0, _pathEq2["default"])(['movement_object', 'payment_method'], 'pix')), (0, _shared.transformAndNegateMovementTypePropTo)(['movement_object', 'fee'], 'boleto'), (0, _shared.transformAndNegateMovementTypePropTo)(['movement_object', 'fee'], 'mdr')), (0, _shared.transformAndNegateMovementTypePropTo)(['movement_object', 'anticipation_fee'], 'anticipation_fee'), (0, _shared.transformAndNegateMovementTypePropTo)(['movement_object', 'fraud_coverage_fee'], 'fraud_coverage_fee')]), (0, _reject2["default"])((0, _propEq2["default"])('amount', 0)));
var isFeeCollection = exports.isFeeCollection = (0, _propEq2["default"])('type', 'fee_collection');
var feeCollectionOutcoming = (0, _juxt2["default"])([(0, _ifElse2["default"])((0, _complement2["default"])((0, _shared.isNegative)('amount')), (0, _shared.transformMovementTypePropTo)(['amount'], 'fee_collection'), zeroMovementAmount)]);
var feeCollectionOutgoing = (0, _juxt2["default"])([(0, _ifElse2["default"])((0, _shared.isNegative)('amount'), (0, _shared.transformMovementTypePropTo)(['amount'], 'fee_collection'), zeroMovementAmount)]);
var buildOutcoming = exports.buildOutcoming = (0, _cond2["default"])([[isRefundOrChargeBack, refundOrChargeBackOutcoming], [isRefundReversal, creditOutcoming], [isTedTransfer, tedTransferOutcoming], [isCreditTransfer, creditTransferOutcoming], [isInterRecipientTransfer, interRecipientTransferOutcoming], [isBoletoRefund, boletoRefundFeeOutcoming], [isCredit, creditOutcoming], [isChargebackRefund, creditOutcoming], [isFeeCollection, feeCollectionOutcoming], [isPixTransfer, pixTransferOutcoming], [isDocTransfer, docTransferOutcoming], [isExternalsettlement, creditOutcoming], [isSettlement, settlementCreditOutcoming]]);
var buildOutgoing = exports.buildOutgoing = (0, _cond2["default"])([[isRefundOrChargeBack, refundOrChargeBackOutgoing], [isRefundReversal, (0, _pipe2["default"])(zeroMovementAmount, _of2["default"])], [isTedTransfer, tedTransferOutgoing], [isCreditTransfer, creditTransferOutgoing], [isInterRecipientTransfer, interRecipientTransferOutgoing], [isBoletoRefund, boletoRefundFeeOutgoing], [isCredit, creditOutgoing], [isChargebackRefund, creditOutgoing], [isFeeCollection, feeCollectionOutgoing], [isPixTransfer, pixTransferOutgoing], [isDocTransfer, docTransferOutgoing], [isExternalsettlement, externalSettlementOutgoing], [isSettlement, settlementOutgoing]]);
var getInstallment = exports.getInstallment = (0, _ifElse2["default"])((0, _pathEq2["default"])(['movement_object', 'payment_method'], 'boleto'), (0, _always2["default"])(null), (0, _pathOr2["default"])(null, ['movement_object', 'installment']));
var _default = exports["default"] = (0, _shared.formatRows)({
  buildOutcoming: buildOutcoming,
  buildOutgoing: buildOutgoing,
  getInstallment: getInstallment
});