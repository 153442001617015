"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _query = _interopRequireDefault(require("./query"));
var _result = _interopRequireDefault(require("./result"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var pendingReviews = function pendingReviews(client) {
  return function () {
    return client.search({
      query: JSON.stringify(_query["default"]),
      search_type: 'count',
      type: 'transaction'
    }).then(_result["default"]);
  };
};
var _default = exports["default"] = pendingReviews;
module.exports = exports["default"];