import React from 'react'
import PropTypes from 'prop-types'

import { Switch, Text } from '@stone-payments/jade'
import PermissionOptions from '../PermissionsOptions'

import styles from './style.css'

const ScopedPermissionToogle = ({
  onChangePermission,
  onChangeSelect,
  scope,
  t,
}) => (
  <div data-testid="div-scoped-permission-togle">
    <div className={styles.itemToggle}>
      <div className={styles.itemToggleText}>
        <Text variant="text-medium" color="high" weight="semibold">
          {scope.title}
        </Text>
        <Text variant="text-small" color="medium">
          {scope.description}
        </Text>
      </div>
      <div className={styles.itemToggleSwitch}>
        <Switch name={scope.id} onChange={onChangeSelect} data-testid="scoped-switch" />
      </div>
    </div>
    {scope.selected && (
    <PermissionOptions
      t={t}
      name={`${scope.id}-permission`}
      onChange={onChangePermission}
      selected={scope.permission}
    />
    )}
  </div>
)

ScopedPermissionToogle.propTypes = {
  onChangePermission: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  scope: PropTypes.shape({
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    permission: PropTypes.string,
    selected: PropTypes.bool,
    title: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
}

export default ScopedPermissionToogle
