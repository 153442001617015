import { useCallback, useMemo, useState } from 'react'
import ApiClientSingleton from '../../../../../utils/helpers/ApiClientSingleton'

const useDeleteCompanyKey = () => {
  const [isLoading, setIsLoading] = useState(false)

  const apiClient = ApiClientSingleton.get()

  const handleRequest = useCallback(async ({ keyId, password }) => {
    setIsLoading(true)

    try {
      const { data } = await apiClient.keys.delete({
        keyId,
        password,
      })

      return { data }
    } catch (error) {
      return { error }
    } finally {
      setIsLoading(false)
    }
  }, [apiClient.keys])

  return useMemo(
    () => ({
      doDeleteRequest: handleRequest,
      isLoading,
    }),
    [isLoading, handleRequest]
  )
}

export default useDeleteCompanyKey
