"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var query = function query(_ref) {
  var end = _ref.end,
    paymentMethod = _ref.paymentMethod,
    start = _ref.start;
  return {
    query: {
      range: {
        date_created: {
          lte: end,
          gte: start
        }
      }
    },
    aggs: {
      boleto: {
        filter: {
          term: {
            payment_method: paymentMethod
          }
        },
        aggs: {
          paid: {
            filter: {
              term: {
                status: 'paid'
              }
            }
          }
        }
      }
    }
  };
};
var _default = exports["default"] = query;
module.exports = exports["default"];