import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import {
  complement,
  compose,
  equals,
  values,
} from 'ramda'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import isCompanyPaymentLink from '../../validation/isPaymentLink'
import isNilOrEmpty from '../../validation/isNilOrEmpty'
import isRecentlyCreatedCompany from '../../validation/recentCreatedCompany'
import { impersonate } from '../../environment'

import ChangeImpersonatedCompanyModal from '../ChangeImpersonatedCompanyModal'
import HeaderContainer from '../../containers/Header'
import RedirectByVpnModal from '../../components/RedirectByVpnModal'

const isNotWelcomePage = complement(equals('/welcome'))

const mapStateToProps = ({ account: { company, user } }) => ({
  company,
  user,
})

const enhance = compose(
  connect(
    mapStateToProps
  ),
  withRouter
)

const Header = ({
  company,
  history: { goBack, push },
  location: {
    pathname,
  },
  routes,
  t,
  user,
}) => {
  const [impersonatedModalOpen, onChangeImpersonatedModalOpen] = useState(false)
  const [
    redirectByVpnModalOpen,
    onChangeRedirectByVpnModalOpen,
  ] = useState(false)

  const toggleImpersonatedModal = useCallback(
    () => onChangeImpersonatedModalOpen(
      !impersonatedModalOpen
    ), [impersonatedModalOpen, onChangeImpersonatedModalOpen]
  )

  const toggleRedirectByVpnModal = useCallback(
    () => onChangeRedirectByVpnModalOpen(
      !redirectByVpnModalOpen
    ), [redirectByVpnModalOpen, onChangeRedirectByVpnModalOpen]
  )

  const handleRedirectToDashAdmin = () => window.open(
    'https://dash-admin.network.pagarme.net/',
    '_blank'
  )

  const handleRedirectToStoneAdmin = () => window.open(
    'https://dash-admin.network.pagarme.net/',
    '_blank'
  )

  const showWelcomeButton = !isNilOrEmpty(company)
    && isRecentlyCreatedCompany({ company })
    && isNotWelcomePage(pathname)
    && !isCompanyPaymentLink(company)

  return (
    <>
      <HeaderContainer
        company={company}
        onBack={goBack}
        onWelcome={() => push(routes.welcome.path)}
        onClickChangeImpersonatedCompany={toggleImpersonatedModal}
        onClickGoToDashAdmin={toggleRedirectByVpnModal}
        routes={values(routes)}
        showWelcomeButton={showWelcomeButton}
        t={t}
        user={user}
      />
      {impersonate && (
        <>
          <ChangeImpersonatedCompanyModal
            isOpen={impersonatedModalOpen}
            onCancel={toggleImpersonatedModal}
            t={t}
          />
          <RedirectByVpnModal
            isOpen={redirectByVpnModalOpen}
            onClose={toggleRedirectByVpnModal}
            onRedirectToDashAdmin={handleRedirectToDashAdmin}
            onRedirectToStoneAdmin={handleRedirectToStoneAdmin}
            t={t}
          />
        </>
      )}
    </>
  )
}

Header.propTypes = {
  company: PropTypes.shape({
    alreadyTransacted: PropTypes.bool,
    id: PropTypes.string,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  routes: PropTypes.shape({
    accountSettings: PropTypes.shape({
      path: PropTypes.string,
    }),
    welcome: PropTypes.shape({
      path: PropTypes.string,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    date_created: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
}

Header.defaultProps = {
  company: {},
}

export default enhance(Header)
