import React from 'react'
import { useSelector } from 'react-redux'
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import details from './Details'
import search from './Search'

import {
  TransactionCreate,
  TransactionDetails,
  TransactionsSearch,
} from './dynamicImports'

import canCreateTransaction from '../../validation/canCreateTransaction'

export const reducers = {
  details,
  search,
}

const TransactionsRouter = () => {
  const {
    company,
    user,
  } = useSelector(state => state.account)

  return (
    <Switch>
      <Route
        exact
        path="/transactions/add"
        render={() => {
          if (!canCreateTransaction(company, user)) {
            return <Redirect to="/transactions" />
          }

          return <TransactionCreate />
        }}
      />
      <Route
        component={TransactionDetails}
        exact
        path="/transactions/:id"
      />
      <Route
        path="/transactions"
        component={TransactionsSearch}
      />
    </Switch>
  )
}

export default TransactionsRouter
