"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _business = require("../business");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var getAnticipationLimits = function getAnticipationLimits(client) {
  return function (recipientId) {
    var getAnticipableLimits = client.bulkAnticipations.limits;
    var now = (0, _momentTimezone["default"])();
    var currentYear = now.year();
    var cutDate = {
      hour: 10
    };
    return (0, _business.requestBusinessCalendar)(currentYear).then(function (calendar) {
      return (0, _business.nextAnticipableBusinessDay)(calendar, cutDate, now);
    }).then(function (paymentDate) {
      return getAnticipableLimits({
        recipientId: recipientId,
        payment_date: paymentDate.valueOf(),
        timeframe: 'start'
      });
    });
  };
};
var _default = exports["default"] = getAnticipationLimits;
module.exports = exports["default"];