"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _mergeAll2 = _interopRequireDefault(require("ramda/src/mergeAll"));
var _T2 = _interopRequireDefault(require("ramda/src/T"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _filter2 = _interopRequireDefault(require("ramda/src/filter"));
var _find2 = _interopRequireDefault(require("ramda/src/find"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _includes2 = _interopRequireDefault(require("ramda/src/includes"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _pathEq2 = _interopRequireDefault(require("ramda/src/pathEq"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var isNilOrEmpty = (0, _either2["default"])(_isEmpty2["default"], _isNil2["default"]);
var hasPaymentMethod = (0, _pathEq2["default"])(['transaction', 'payment', 'method']);
var isSplitting = (0, _pipe2["default"])((0, _path2["default"])(['body', 'recipient']), (0, _complement2["default"])((0, _either2["default"])(isNilOrEmpty, (0, _equals2["default"])('same'))));
var isResponsibleForMdr = (0, _pipe2["default"])((0, _prop2["default"])('liabilities'), (0, _includes2["default"])('mdr'));
var getFeePayer = (0, _pipe2["default"])((0, _pathOr2["default"])([], ['transaction', 'recipients']), (0, _find2["default"])(isResponsibleForMdr));
var buildSplitRulesCard = function buildSplitRulesCard(data) {
  return (0, _pipe2["default"])((0, _pathOr2["default"])([], ['transaction', 'split_rules']), (0, _filter2["default"])((0, _either2["default"])((0, _propEq2["default"])('id', (0, _path2["default"])(['body', 'recipient'], data)), (0, _propEq2["default"])('charge_processing_fee', true))), (0, _map2["default"])((0, _applySpec2["default"])({
    amount: (0, _ifElse2["default"])((0, _pipe2["default"])((0, _prop2["default"])('id'), function (splitId) {
      return (0, _equals2["default"])(splitId, (0, _path2["default"])(['body', 'recipient'], data));
    }), (0, _always2["default"])((0, _path2["default"])(['body', 'amount'], data)), (0, _always2["default"])(0)),
    charge_processing_fee: (0, _prop2["default"])('charge_processing_fee'),
    charge_remainder: (0, _prop2["default"])('charge_remainder'),
    id: (0, _prop2["default"])('id'),
    liable: (0, _prop2["default"])('liable'),
    recipient_id: (0, _prop2["default"])('recipient_id')
  })))(data);
};
var buildSplitRulesBoletoAndPix = function buildSplitRulesBoletoAndPix(data) {
  return (0, _pipe2["default"])((0, _pathOr2["default"])([], ['transaction', 'split_rules']), (0, _filter2["default"])((0, _propEq2["default"])('id', (0, _path2["default"])(['body', 'recipient'], data))), (0, _propOr2["default"])(null, 0), (0, _juxt2["default"])([(0, _applySpec2["default"])({
    amount: (0, _always2["default"])((0, _path2["default"])(['body', 'amount'], data)),
    charge_processing_fee: _T2["default"],
    charge_remainder: (0, _prop2["default"])('charge_remainder'),
    id: (0, _prop2["default"])('id'),
    liable: (0, _prop2["default"])('liable'),
    recipient_id: (0, _prop2["default"])('recipient_id')
  })]))(data);
};
var buildSplitRules = (0, _ifElse2["default"])((0, _either2["default"])(hasPaymentMethod('boleto'), hasPaymentMethod('pix')), buildSplitRulesBoletoAndPix, buildSplitRulesCard);
var buildDefaultPayload = (0, _applySpec2["default"])({
  amount: (0, _path2["default"])(['body', 'amount']),
  id: (0, _path2["default"])(['transaction', 'id']),
  otp: (0, _pathOr2["default"])(undefined, ['body', 'otp'])
});
var buildBoletoPayload = (0, _ifElse2["default"])(hasPaymentMethod('boleto'), (0, _applySpec2["default"])({
  bank_account: (0, _path2["default"])(['body', 'bank_account'])
}), (0, _always2["default"])({}));
var buildSplitRulesPayload = (0, _ifElse2["default"])(isSplitting, (0, _applySpec2["default"])({
  charge_fee_recipient_id: (0, _pipe2["default"])(getFeePayer, (0, _prop2["default"])('id')),
  split_rules: buildSplitRules
}), (0, _always2["default"])({}));
var buildPayload = (0, _pipe2["default"])((0, _juxt2["default"])([buildDefaultPayload, buildBoletoPayload, buildSplitRulesPayload]), _mergeAll2["default"]);
var _default = exports["default"] = buildPayload;
module.exports = exports["default"];