"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _isomorphicFetch = _interopRequireDefault(require("isomorphic-fetch"));
var _environment = require("../../environment");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var getFeatures = function getFeatures(companyId) {
  return (0, _isomorphicFetch["default"])("".concat(_environment.apiGanonUrl, "features?company_id=").concat(companyId)).then(function (res) {
    return res.json();
  });
};
var _default = exports["default"] = getFeatures;
module.exports = exports["default"];