"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _assoc2 = _interopRequireDefault(require("ramda/src/assoc"));
var _pathEq2 = _interopRequireDefault(require("ramda/src/pathEq"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _pathSatisfies2 = _interopRequireDefault(require("ramda/src/pathSatisfies"));
var _uncurryN2 = _interopRequireDefault(require("ramda/src/uncurryN"));
var _pick2 = _interopRequireDefault(require("ramda/src/pick"));
var _of2 = _interopRequireDefault(require("ramda/src/of"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _merge2 = _interopRequireDefault(require("ramda/src/merge"));
var _bluebird = require("bluebird");
var _result = _interopRequireDefault(require("./result"));
var _ganon = require("../../ganon");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var fetchRecipient = function fetchRecipient(client) {
  return function (object) {
    return client.recipients.find({
      id: object.recipient_id
    }).then(function (recipient) {
      return (0, _merge2["default"])(object, {
        recipient: recipient
      });
    });
  };
};
var payableToRecipient = (0, _ifElse2["default"])((0, _either2["default"])(_isNil2["default"], _isEmpty2["default"]), (0, _always2["default"])([]), (0, _pipe2["default"])(_head2["default"], (0, _pick2["default"])(['recipient_id']), (0, _merge2["default"])({
  charge_processing_fee: true,
  id: null,
  liable: true
}), _of2["default"]));
var fetchRecipients = (0, _uncurryN2["default"])(2, function (client) {
  return (0, _pipe2["default"])((0, _ifElse2["default"])((0, _pathSatisfies2["default"])(_isNil2["default"], ['transaction', 'split_rules']), (0, _pipe2["default"])((0, _prop2["default"])('payables'), payableToRecipient), (0, _path2["default"])(['transaction', 'split_rules'])), (0, _map2["default"])(fetchRecipient(client)), _bluebird.all);
});
var getReasonCode = (0, _pipe2["default"])(_last2["default"], (0, _prop2["default"])('reason_code'));
var fetchChargebackReason = (0, _ifElse2["default"])((0, _pathEq2["default"])(['transaction', 'status'], 'chargedback'), (0, _pipe2["default"])((0, _prop2["default"])('chargebacks'), getReasonCode, _ganon.getChargebackReason), (0, _always2["default"])(Promise.resolve(null)));
var details = function details(client) {
  return function (transactionId) {
    return (0, _bluebird.props)({
      antifraudAnalyses: client.antifraudAnalyses.find({
        transactionId: transactionId
      }),
      chargebacks: client.chargebacks.find({
        transaction_id: transactionId
      }),
      chargebackOperations: client.chargebackOperations.find({
        transactionId: transactionId
      }),
      gatewayOperations: client.gatewayOperations.find({
        transactionId: transactionId
      }),
      payables: client.payables.find({
        transactionId: transactionId
      }),
      reprocessed: [],
      transaction: client.transactions.find({
        id: transactionId
      })
    }).then(function (data) {
      return fetchRecipients(client, data).then((0, _assoc2["default"])('split_rules', _2["default"], data));
    }).then(function (data) {
      return fetchChargebackReason(data).then((0, _assoc2["default"])('chargeback_reason', _2["default"], data));
    }).then(_result["default"]);
  };
};
var _default = exports["default"] = details;
module.exports = exports["default"];