"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _has2 = _interopRequireDefault(require("ramda/src/has"));
var _is2 = _interopRequireDefault(require("ramda/src/is"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var isNotObject = (0, _either2["default"])((0, _complement2["default"])((0, _is2["default"])(Object)), (0, _either2["default"])((0, _is2["default"])(Function), (0, _is2["default"])(Array)));
var isFunction = (0, _is2["default"])(Function);
var enhanceFunction = function enhanceFunction(prop, errorHandler) {
  if (errorHandler && isFunction(prop)) {
    return function () {
      var result = prop.apply(void 0, arguments);
      if (result.then && isFunction(result.then)) {
        return result["catch"](errorHandler);
      }
      return result;
    };
  }
  return prop;
};
var getProxyHandler = function getProxyHandler(fallback, errorHandler) {
  return {
    get: function get(target, key) {
      var hasKey = (0, _has2["default"])(key);
      if (hasKey(target)) {
        var propInTarget = target[key];
        if (isNotObject(propInTarget)) {
          return enhanceFunction(propInTarget, errorHandler);
        }
        return new Proxy(propInTarget, getProxyHandler(fallback[key], errorHandler));
      }
      if (fallback && hasKey(fallback)) {
        var propInFallback = fallback[key];
        if (isNotObject(propInFallback)) {
          return enhanceFunction(propInFallback, errorHandler);
        }
        return new Proxy(propInFallback, getProxyHandler(propInFallback, errorHandler));
      }
      return undefined;
    }
  };
};
var proxy = function proxy(cockpit) {
  return function (client, errorHandler) {
    var cockpitWithClient = cockpit(client);
    return new Proxy(cockpitWithClient, getProxyHandler(client, errorHandler));
  };
};
var _default = exports["default"] = proxy;
module.exports = exports["default"];