"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var query = {
  query: {
    term: {
      status: 'pending_review'
    }
  }
};
var _default = exports["default"] = query;
module.exports = exports["default"];