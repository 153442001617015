"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _bluebird = require("bluebird");
var _queries = require("./queries");
var _result = _interopRequireDefault(require("./result"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var chargeback = function chargeback(client) {
  return function (dates) {
    return (0, _bluebird.props)({
      chargebacks: client.search({
        type: 'chargeback_operation',
        query: JSON.stringify((0, _queries.chargebackQuery)(dates))
      }),
      transactions: client.search({
        type: 'transaction',
        query: JSON.stringify((0, _queries.transactionsQuery)(dates))
      })
    }).then(_result["default"]);
  };
};
var _default = exports["default"] = chargeback;
module.exports = exports["default"];