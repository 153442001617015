/* eslint-disable sort-keys */

import { ReceivablesRegister } from '../dynamicImports'

export const receivablesRegisterRoutes = {
  receivablesRegister: {
    component: ReceivablesRegister,
    showInSidebar: false,
    showHeaderBack: false,
    showSubLink: true,
    path: '/receivables-register',
    subLinkPath: ['financial', 'schedule'],
    relevance: 7,
    title: 'pages.receivables_register.list.title',
    value: 'receivables-register',
  },
  receivablesRegisterWithRecipientId: {
    showHeaderBack: false,
    showInSidebar: false,
    path: '/receivables-register/:recipientId',
    title: 'pages.receivables_register.list.title',
  },
  receivablesRegisterDay: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/receivables-register/:recipientId/:day',
    title: 'pages.receivables_register.day.title',
  },
  receivablesRegisterArrangement: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/receivables-register/:recipientId/:day/:arrangement',
    title: 'pages.receivables_register.arrangement.title',
  },
}

export default receivablesRegisterRoutes
