import axios from "axios";
import { clone } from "ramda";
import { baseUrl } from "..";
import { startOfDay, endOfDay } from 'date-fns';
function prepareUrl(options) {
  let url = baseUrl;
  url = url.replace(":company_id", options.companyId);
  return "".concat(url, "/summary");
}
export function setParams(params, axiosConfig) {
  if (params === null || params === void 0 ? void 0 : params.start_date) {
    axiosConfig.params.start_date = startOfDay(new Date(params.start_date));
  }
  if (params === null || params === void 0 ? void 0 : params.end_date) {
    axiosConfig.params.end_date = endOfDay(new Date(params.end_date));
  }
}
export default ((axiosConfig, options) => {
  return params => {
    const config = clone(axiosConfig);
    config.url = prepareUrl(options);
    config.params = Object.assign(Object.assign({}, axiosConfig.params), params);
    setParams(params, config);
    config.method = "GET";
    return axios(config);
  };
});