"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _shared = require("./shared");
var isBusinessDay = function isBusinessDay() {
  var calendar = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var date = arguments.length > 1 ? arguments[1] : undefined;
  if ((0, _shared.isWeekend)(date)) {
    return false;
  }
  var day = (0, _shared.getCalendarDate)(calendar, date);
  if (!day) {
    return true;
  }
  return !day.holiday && !day.limited_financial_operation;
};
var _default = exports["default"] = isBusinessDay;
module.exports = exports["default"];