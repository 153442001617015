"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatToTransfer = exports.formatToRecipient = exports.formatToBankAccount = exports.formatToAnticipation = void 0;
var _range2 = _interopRequireDefault(require("ramda/src/range"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var getOnlyNumbers = function getOnlyNumbers(string) {
  return string.replace(/\D/g, '');
};
var formatToAnticipation = exports.formatToAnticipation = function formatToAnticipation(data, options) {
  var recipientAnticipationData = {};
  var canConfigureAnticipation = options.canConfigureAnticipation;
  switch (data.configuration.anticipationModel) {
    case 'automatic_dx':
      recipientAnticipationData.anticipatable_volume_percentage = 100;
      recipientAnticipationData.automatic_anticipation_enabled = true;
      recipientAnticipationData.automatic_anticipation_days = JSON.stringify((0, _range2["default"])(1, 32));
      recipientAnticipationData.automatic_anticipation_1025_delay = data.configuration.anticipationDelay;
      if (canConfigureAnticipation) {
        recipientAnticipationData.automatic_anticipation_type = '1025';
      }
      break;
    case 'automatic_volume':
      recipientAnticipationData.anticipatable_volume_percentage = data.configuration.anticipationVolumePercentage;
      recipientAnticipationData.automatic_anticipation_enabled = true;
      if (canConfigureAnticipation) {
        recipientAnticipationData.automatic_anticipation_type = 'full';
      }
      break;
    case 'automatic_1025':
      recipientAnticipationData.anticipatable_volume_percentage = 100;
      recipientAnticipationData.automatic_anticipation_enabled = true;
      recipientAnticipationData.automatic_anticipation_days = '[10,25]';
      recipientAnticipationData.automatic_anticipation_1025_delay = 15;
      if (canConfigureAnticipation) {
        recipientAnticipationData.automatic_anticipation_type = '1025';
      }
      break;
    case 'custom':
      recipientAnticipationData.anticipatable_volume_percentage = data.configuration.anticipationVolumePercentage || data.anticipationVolumePercentage;
      recipientAnticipationData.automatic_anticipation_enabled = true;
      recipientAnticipationData.automatic_anticipation_days = "[".concat(data.configuration.anticipationDays, "]");
      recipientAnticipationData.automatic_anticipation_1025_delay = data.configuration.anticipationDelay;
      if (canConfigureAnticipation) {
        recipientAnticipationData.automatic_anticipation_type = '1025';
      }
      break;
    default:
      recipientAnticipationData.anticipatable_volume_percentage = data.configuration.anticipationVolumePercentage || data.anticipationVolumePercentage;
      recipientAnticipationData.automatic_anticipation_enabled = false;
      if (canConfigureAnticipation) {
        recipientAnticipationData.automatic_anticipation_type = 'full';
      }
      break;
  }
  return recipientAnticipationData;
};
var formatToTransfer = exports.formatToTransfer = function formatToTransfer(data) {
  var recipientTransferData = {};
  recipientTransferData.transfer_interval = data.configuration.transferInterval;
  recipientTransferData.transfer_enabled = data.configuration.transferEnabled;
  if (data.configuration.transferInterval === 'weekly') {
    var weekDay = data.configuration.transferDay;
    recipientTransferData.transfer_day = weekDay;
  }
  if (data.configuration.transferInterval === 'daily') {
    recipientTransferData.transfer_day = '0';
  } else {
    recipientTransferData.transfer_day = data.configuration.transferDay;
  }
  return recipientTransferData;
};
var format = function format(data) {
  var recipient = {};
  var documentType = data.identification.documentType;
  var document = data.identification[documentType];
  recipient.bank_account = {
    bank_code: data.bankAccount.bank,
    agencia: data.bankAccount.agency,
    conta: data.bankAccount.number,
    conta_dv: data.bankAccount.number_digit,
    type: data.bankAccount.type,
    document_number: getOnlyNumbers(document),
    legal_name: data.bankAccount.name
  };
  if (data.bankAccount.agency_digit) {
    recipient.bank_account.agencia_dv = data.bankAccount.agency_digit;
  }
  return recipient;
};
var formatToBankAccount = exports.formatToBankAccount = function formatToBankAccount(data) {
  if (data.bankAccount && data.bankAccount.id) {
    return {
      bank_account_id: data.bankAccount.id
    };
  }
  if (data.configuration && data.configuration.id) {
    return {
      bank_account_id: data.configuration.id
    };
  }
  return format(data);
};
var formatToRecipient = exports.formatToRecipient = function formatToRecipient(data, options) {
  var recipientData = _extends({}, formatToAnticipation(data, options), formatToTransfer(data), formatToBankAccount(data));
  var documentType = data.identification.documentType;
  var hasRegisterInformation = data.identification["".concat(documentType, "Information")];
  if (hasRegisterInformation) {
    var phone = data.identification["".concat(documentType, "Phone")];
    var phoneDigits = getOnlyNumbers(phone);
    var ddd = phoneDigits.slice(0, 2);
    var number = phoneDigits.slice(2);
    var url = data.identification["".concat(documentType, "Url")];
    var email = data.identification["".concat(documentType, "Email")];
    recipientData.register_information = {
      document_number: data.identification[documentType].replace(/\D/g, ''),
      site_url: url !== '' ? url : undefined,
      email: email
    };
    if (phone !== '') {
      recipientData.register_information.phone_numbers = [{
        ddd: ddd,
        number: number,
        type: 'not defined'
      }];
    }
    var name = data.identification["".concat(documentType, "Name")];
    if (documentType === 'cpf') {
      recipientData.register_information.type = 'individual';
      recipientData.register_information.name = name;
    }
    if (documentType === 'cnpj') {
      recipientData.register_information.type = 'corporation';
      recipientData.register_information.company_name = name;
    }
    var partnerNumber = parseInt(data.identification.partnerNumber, 10);
    if (partnerNumber > 0) {
      var partners = (0, _range2["default"])(0, partnerNumber).map(function (n) {
        return data.identification["partner".concat(n)];
      }).map(function (partner) {
        return {
          type: 'individual',
          document_number: getOnlyNumbers(partner.cpf),
          name: partner.name,
          email: partner.email
        };
      });
      recipientData.register_information.managing_partners = partners;
    }
  }
  return recipientData;
};