import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@stone-payments/jade'
import Card from '../Card'

import styles from './styles.css'

const CardInfo = ({ icon, subTitle, title }) => (
  <Card>
    <div className={styles.cardContainer}>
      <div className={styles.cardText}>
        <Text variant="heading-medium">{title}</Text>
        <Text color="medium">{subTitle}</Text>
      </div>
      <div className={styles.cardImage}>
        {icon}
      </div>
    </div>
  </Card>
)

CardInfo.propTypes = {
  icon: PropTypes.node.isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default CardInfo
