"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _query = _interopRequireDefault(require("../search/query"));
var _result = _interopRequireDefault(require("./result"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var exportData = function exportData(client) {
  return function (clientFilter, exportType, columns, limiter) {
    var query = (0, _query["default"])(_extends({}, clientFilter, {
      exportData: true
    }));
    return client.search({
      type: 'transaction',
      query: JSON.stringify(query)
    }).then((0, _result["default"])(exportType, columns, limiter));
  };
};
var _default = exports["default"] = exportData;
module.exports = exports["default"];