import { Icon, Tooltip } from '@stone-payments/jade'
import {
  Button,
  Card, CardContent, Flexbox,
} from 'former-kit'
import PropTypes from 'prop-types'
import { findIndex, isEmpty, propEq } from 'ramda'
import React from 'react'
import EmptyCard from '../../../../../../components/EmptyCard'
import TableList from '../../../../../../components/TableList'
import style from './style.css'
import tableColumns from './tableColumns'

const tPrefix = 'pages.company_settings.api_data'

const TableApiKeys = ({
  disableNew,
  isAdmin,
  onClickAddNew,
  onDelete,
  rows,
  t,
}) => {
  const columns = tableColumns({
    isAdmin,
    onClick: onDelete,
    t,
  })

  const orderColumn = findIndex(propEq('accessor'), columns)

  return (
    <Card>
      <CardContent>
        <div className={style.cardContainerWrapper}>
          <Flexbox justifyContent="space-between" alignItems="center">
            <div className={style.cardInfoWrapper}>
              <p className={style.cardTitle}>
                {t(`${tPrefix}.table_api_keys_title`)}
              </p>
              <p className={style.cardDescription}>
                {`${t(`${tPrefix}.table_api_keys_description`)} `}
                <a href="https://docs.pagar.me/docs/chaves-de-acesso" target="_blank" rel="noopener noreferrer">
                  {t(`${tPrefix}.doc_link`)}
                </a>
              </p>
            </div>
            {isAdmin && (
              <div>
                <div className={style.tootipAddKey}>
                  <Tooltip
                    placement="bottom-start"
                    text={`${t(`${tPrefix}.outdated_keys_message_tooltip`)} `}
                    trigger={<Icon size="small" use="circle-alert" color="brand" />}
                    style={{ zIndex: 1 }}
                  />
                </div>
                <Button
                  disabled={disableNew}
                  onClick={onClickAddNew}
                >
                  {t(`${tPrefix}.button_create_key`)}
                </Button>
              </div>

            )}
          </Flexbox>
          {!isEmpty(rows) ? (
            <TableList
              key="id"
              columns={columns}
              maxColumns={8}
              orderColumn={orderColumn}
              rows={rows}
            />
          ) : (
            <div className={style.emptyBox}>
              <EmptyCard
                title={t(`${tPrefix}.empty_list_title`)}
                description={t(`${tPrefix}.empty_list_description`)}
              />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

TableApiKeys.propTypes = {
  disableNew: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onClickAddNew: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    last_session_at: PropTypes.string.isRequired,
    masked_key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  t: PropTypes.func.isRequired,
}

export default (TableApiKeys)
