import React from 'react'
import PropTypes from 'prop-types'
import {
  curry,
  head,
  isNil,
  pipe,
  split,
} from 'ramda'

import {
  matchPath,
  withRouter,
} from 'react-router-dom'

import SidebarContainer from '../../containers/Sidebar'

const removeRouteParams = pipe(
  split(':'),
  head
)

const handleLinkClick = curry((push, currentPath, route) => {
  const matched = matchPath(currentPath, route)

  if (route.isExternalLink) {
    window.open(route.path, '_blank')
  } else if (isNil(matched) || !route.active) {
    push(route.path)
  }
})

// Welcome page should show "Home" icon selected in sidebar
const isWelcomePageInHomeRoute = (pathname, route) => pathname.includes('welcome')
  && removeRouteParams(route.path).includes('home')

const Sidebar = ({
  companyCnpj,
  companyName,
  history,
  location: { pathname },
  routes,
  t,
  userEmail,
  userName,
}) => (
  <SidebarContainer
    companyCnpj={companyCnpj}
    companyName={companyName}
    userName={userName}
    userEmail={userEmail}
    links={routes
      .filter(({ showInSidebar, showSubLink }) => showInSidebar || showSubLink)
      .map(route => ({
        ...route,
        active: isWelcomePageInHomeRoute(pathname, route)
          || pathname.startsWith(removeRouteParams(route.path)),
      }))
    }
    onLinkClick={handleLinkClick(history.push, pathname)}
    t={t}
  />
)

Sidebar.propTypes = {
  companyCnpj: PropTypes.string,
  companyName: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      hidden: PropTypes.bool,
      path: PropTypes.string,
    })
  ).isRequired,
  t: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  userName: PropTypes.string,
}

Sidebar.defaultProps = {
  companyCnpj: '',
  companyName: '',
  userEmail: '',
  userName: '',
}

export default withRouter(Sidebar)
