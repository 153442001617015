"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _uniqWith2 = _interopRequireDefault(require("ramda/src/uniqWith"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _sortBy2 = _interopRequireDefault(require("ramda/src/sortBy"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var sortByInstallment = (0, _pipe2["default"])((0, _sortBy2["default"])((0, _prop2["default"])('installment')));
var getInstallment = function getInstallment(sortInstallments, index) {
  var itsTheLastPossibleInstallment = sortInstallments[index].installment === 12;
  if (itsTheLastPossibleInstallment) {
    return [sortInstallments[index].installment];
  }
  var isTheLastInstallment = index === sortInstallments.length - 1;
  if (isTheLastInstallment) {
    return [sortInstallments[index].installment, 12];
  }
  var isNextInstallmentEqualCurrentPlusOne = sortInstallments[index + 1].installment === sortInstallments[index].installment + 1;
  if (isNextInstallmentEqualCurrentPlusOne) {
    return [sortInstallments[index].installment];
  }
  return [sortInstallments[index].installment, sortInstallments[index + 1].installment - 1];
};
var feesFormatter = function feesFormatter(fees) {
  var formattedFees = [];
  fees.forEach(function (fee) {
    var sortedInstallments = sortByInstallment(fee.installments);
    sortedInstallments.forEach(function (installment, index) {
      formattedFees.push({
        capture_method: fee.capture_method,
        payment_method: fee.payment_method,
        card_brand: fee.card_brand,
        installments: getInstallment(sortedInstallments, index),
        fee: installment.mdr
      });
    });
  });
  return formattedFees;
};
var getCardBrands = function getCardBrands(fees) {
  var cardBrands = [];
  for (var position = 0; position < fees.length; position += 1) {
    if (!fees[position].payment_method && !fees[position].capture_method && fees[position].card_brand) {
      cardBrands.push(fees[position]);
    } else if (!fees[position].card_brand) {
      break;
    }
  }
  return cardBrands;
};
var removeDuplicateds = (0, _uniqWith2["default"])(_equals2["default"]);
var putNullsInLastPositions = function putNullsInLastPositions(fees) {
  return fees.sort(function (a, b) {
    if (a.card_brand === null) {
      return 1;
    }
    if (b.card_brand === null) {
      return -1;
    }
    return 0;
  });
};
var removeWrongSearch = function removeWrongSearch(results, paymentMethod, captureMethod) {
  return results.filter(function (result) {
    return (result.payment_method === null || result.payment_method === paymentMethod) && (result.capture_method === null || result.capture_method === captureMethod);
  });
};
var debitCardResult = function debitCardResult(result) {
  var debitResults = result.filter(function (fee) {
    return fee.installments.includes(1);
  });
  return putNullsInLastPositions(removeDuplicateds(debitResults.map(function (debitResult) {
    return _extends({}, debitResult, {
      installments: [1]
    });
  })));
};
var outputFees = function outputFees(fees, paymentMethod, captureMethod) {
  var result = [];
  var formattedFees = feesFormatter(fees);
  var cardBrands = getCardBrands(_toConsumableArray(formattedFees));
  result.push.apply(result, _toConsumableArray(cardBrands));
  var paymentMethods = removeWrongSearch(formattedFees.filter(function (item) {
    return item.payment_method === paymentMethod;
  }), paymentMethod, captureMethod);
  var captureMethods = removeWrongSearch(formattedFees.filter(function (item) {
    return item.capture_method === captureMethod;
  }), paymentMethod, captureMethod);
  var cardBrandNull = formattedFees.filter(function (item) {
    return item.card_brand === null && item.payment_method === null && item.capture_method === null;
  });
  if (paymentMethods.length === 0 && captureMethods.length === 0) {
    var nullEntries = formattedFees.filter(function (item) {
      return item.capture_method === null && item.payment_method === null;
    });
    result.push.apply(result, _toConsumableArray(nullEntries));
    if (paymentMethod === 'debit_card') {
      return debitCardResult(result);
    }
    return putNullsInLastPositions(removeDuplicateds(result));
  }
  result.push.apply(result, _toConsumableArray(paymentMethods));
  result.push.apply(result, _toConsumableArray(captureMethods));
  if (!result.find(function (fee) {
    return fee.card_brand === null;
  })) {
    result.push.apply(result, _toConsumableArray(cardBrandNull));
  }
  if (paymentMethod === 'debit_card') {
    return debitCardResult(result);
  }
  return putNullsInLastPositions(removeDuplicateds(result));
};
var _default = exports["default"] = outputFees;
module.exports = exports["default"];