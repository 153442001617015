import { lazy } from 'react'

const PaymentLinksDetails = lazy(() => import(
  /* webpackChunkName: "payment-links-details" */ './pages/Details/Details'
))

const PaymentLinksList = lazy(() => import(
  /* webpackChunkName: "payment-links" */ './pages/List/List'
))

const PaymentLinksCreate = lazy(() => import(
  /* webpackChunkName: "payment-links" */ './pages/Create'
))

export {
  PaymentLinksDetails,
  PaymentLinksList,
  PaymentLinksCreate,
}
