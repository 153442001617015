"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var postbacksPathBase = ['events', 'subscription'];
var _default = exports["default"] = (0, _applySpec2["default"])({
  subscriptionStatusChanged: (0, _pathOr2["default"])(false, [].concat(postbacksPathBase, ['subscription_status_changed', 'postback'])),
  transactionCreated: (0, _pathOr2["default"])(false, [].concat(postbacksPathBase, ['transaction_created', 'postback']))
});
module.exports = exports["default"];