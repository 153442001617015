"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findPendingRequests = exports.filterPendingRequest = exports["default"] = exports.concatAndSortBulkAnticipationsAndTransfers = exports.buildPendingRequest = void 0;
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _filter2 = _interopRequireDefault(require("ramda/src/filter"));
var _sort2 = _interopRequireDefault(require("ramda/src/sort"));
var _concat2 = _interopRequireDefault(require("ramda/src/concat"));
var _apply2 = _interopRequireDefault(require("ramda/src/apply"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _negate2 = _interopRequireDefault(require("ramda/src/negate"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _sum2 = _interopRequireDefault(require("ramda/src/sum"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var calculateAnticipationAmount = (0, _pipe2["default"])((0, _juxt2["default"])([(0, _prop2["default"])('amount'), (0, _pipe2["default"])((0, _prop2["default"])('anticipation_fee'), _negate2["default"]), (0, _pipe2["default"])((0, _prop2["default"])('fee'), _negate2["default"])]), _sum2["default"]);
var buildPendingRequest = exports.buildPendingRequest = (0, _map2["default"])((0, _applySpec2["default"])({
  amount: (0, _ifElse2["default"])((0, _propEq2["default"])('object', 'bulk_anticipation'), calculateAnticipationAmount, (0, _prop2["default"])('amount')),
  automaticTransfer: (0, _propOr2["default"])(false, 'automatic_transfer'),
  created_at: (0, _prop2["default"])('date_created'),
  id: (0, _prop2["default"])('id'),
  payment_date: (0, _ifElse2["default"])((0, _propEq2["default"])('object', 'bulk_anticipation'), (0, _prop2["default"])('payment_date'), (0, _prop2["default"])('funding_estimated_date')),
  status: (0, _always2["default"])('pending'),
  type: (0, _ifElse2["default"])((0, _propEq2["default"])('object', 'bulk_anticipation'), (0, _always2["default"])('anticipation'), (0, _always2["default"])('transfer'))
}));
var concatAndSortBulkAnticipationsAndTransfers = exports.concatAndSortBulkAnticipationsAndTransfers = (0, _pipe2["default"])((0, _apply2["default"])(_concat2["default"]), (0, _sort2["default"])(function (a, b) {
  return new Date(a.created_at) - new Date(b.created_at);
}));
var filterPendingRequest = exports.filterPendingRequest = (0, _pipe2["default"])((0, _filter2["default"])((0, _either2["default"])((0, _propEq2["default"])('status', 'pending'), (0, _propEq2["default"])('status', 'pending_transfer'))));
var findPendingRequests = exports.findPendingRequests = function findPendingRequests(client) {
  return function (recipientId) {
    return Promise.all([client.bulkAnticipations.find({
      recipientId: recipientId,
      status: 'pending'
    }), client.transfers.all({
      recipient_id: recipientId
    })]).then(concatAndSortBulkAnticipationsAndTransfers).then(filterPendingRequest).then(buildPendingRequest);
  };
};
var _default = exports["default"] = {
  findPendingRequests: findPendingRequests
};