import identifyUser from '../../../vendor/identifyUser'
import { impersonate } from '../../../environment'

const getUserDetails = ({
  accountType, client, env, user,
}) => {
  if (!impersonate) {
    const localStorageKey = `@pilot/user-info-${user.id}`
    const storedUser = JSON.parse(localStorage.getItem(localStorageKey))

    if (storedUser && storedUser?.id === user.id) {
      identifyUser(
        user.id,
        user.email,
        user.name,
        storedUser.dateCreated,
        user.permission,
        env,
        accountType
      )

      return
    }

    if (!window.location.href.includes('onboarding.pagar.me')) {
      client.user.find({ id: user.id }).then((foundUser) => {
        const {
          date_created: dateCreated, email, id, name, permission,
        } = foundUser

        localStorage.setItem(
          localStorageKey,
          JSON.stringify({ dateCreated, id })
        )

        identifyUser(
          user.id,
          email,
          name,
          dateCreated,
          permission,
          env,
          accountType
        )
      })
    }
  }
}

export default getUserDetails
