import searchTransactions from "./search";
import findTransactions from "./find";
import findOneTransaction from "./findOne";
import reportTransactions from "./report";
import cancellationLetter from "./cancellationLetter";
import findReprocessedTransactions from "./findReprocessedTransactions";
import chargebackOperations from "./chargebackOperations";
import summaryTransactions from "./summary";
export const baseUrl = "/company/:company_id/transactions";
export default ((axiosConfig, options) => ({
  search: searchTransactions(axiosConfig, options),
  find: findTransactions(axiosConfig, options),
  findReprocessedTransactions: findReprocessedTransactions(axiosConfig, options),
  findOne: findOneTransaction(axiosConfig, options),
  report: reportTransactions(axiosConfig, options),
  cancellationLetter: cancellationLetter(axiosConfig, options),
  chargebackOperations: chargebackOperations(axiosConfig, options),
  summary: summaryTransactions(axiosConfig, options)
}));