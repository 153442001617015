import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Button,
  Checkbox,
  FormInput,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitle,
} from 'former-kit'
import IconClose from 'emblematic-icons/svg/ClearClose32.svg'
import IconError from 'emblematic-icons/svg/CloseCircle32.svg'

import { isEmpty, isNil } from 'ramda'
import styles from './style.css'

const tPrefix = 'pages.company_settings.api_data'

const ModalDeleteKey = ({
  apiKey,
  error,
  isLoading,
  isOpen,
  onClose,
  onSubmit,
  t,
}) => {
  const [hasSelectedTerms, setHasSelectedTerms] = useState(false)
  const [userPassword, setUserPassword] = useState('')

  const isInvalidForm = !hasSelectedTerms || isEmpty(userPassword)

  const handleSubmit = (e) => {
    e.preventDefault()

    onSubmit({ password: userPassword })
  }

  return (
    <Modal size="small" isOpen={isOpen}>
      <ModalTitle
        closeIcon={<IconClose width={16} height={16} />}
        onClose={onClose}
        title={t(`${tPrefix}.modal_delete_key_title`)}
      />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          {!isEmpty(error) && !isNil(error) && (
            <div className={styles.alertWrapper}>
              <Alert icon={<IconError height={16} width={16} />} type="error">
                <span>{error}</span>
              </Alert>
            </div>
          )}
          <p className={styles.modalDescription}>
            {t(`${tPrefix}.modal_delete_key_description`)}
          </p>
          <div className={styles.inputWrapper}>
            <FormInput
              label=""
              placeholder=""
              type="text"
              value={apiKey}
              readOnly
              disabled
              onChange={() => {}}
            />
          </div>
          <div className={styles.inputWrapper}>
            <FormInput
              label={t(`${tPrefix}.modal_delete_key_input_password`)}
              placeholder={t(`${tPrefix}.modal_delete_key_input_password`)}
              value={userPassword}
              type="password"
              onChange={e => setUserPassword(e.target.value)}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Checkbox
              name="active"
              type="checkbox"
              value="active"
              checked={hasSelectedTerms}
              onChange={() => setHasSelectedTerms(oldValue => !oldValue)}
              label={t(`${tPrefix}.modal_delete_key_input_terms`)}
            />
          </div>
        </ModalContent>
        <ModalActions>
          <Button
            fill="outline"
            type="button"
            onClick={onClose}
            disabled={isLoading}
          >
            {t(`${tPrefix}.modal_delete_key_button_back`)}
          </Button>
          <div className={styles.buttonDelete}>
            <Button type="submit" loading={isLoading} disabled={isInvalidForm}>
              {t(`${tPrefix}.modal_delete_key_button_delete`)}
            </Button>
          </div>
        </ModalActions>
      </form>
    </Modal>
  )
}

ModalDeleteKey.propTypes = {
  apiKey: PropTypes.string.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

ModalDeleteKey.defaultProps = {
  error: null,
}

export default ModalDeleteKey
