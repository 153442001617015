import { impersonate } from '../environment'

const getStates = () => {
  const states = [
    'account.accountId',
    'account.sessionId',
    'account.jwt',
    'account.companyId',
  ]

  if (impersonate) {
    states.push('account.impersonationKey')
    states.push('account.impersonationEnv')
  }

  return states
}

export default getStates
