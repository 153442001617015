"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _buildQuery = _interopRequireDefault(require("./buildQuery"));
var _buildResult = _interopRequireDefault(require("./buildResult"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var search = function search(client) {
  return function (query) {
    return client.transfers.all((0, _buildQuery["default"])(query)).then(_buildResult["default"]);
  };
};
var _default = exports["default"] = search;
module.exports = exports["default"];