"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var query = function query(_ref) {
  var end = _ref.end,
    start = _ref.start;
  return {
    filter: {
      range: {
        date_created: {
          lte: end,
          gte: start
        }
      }
    },
    aggs: {
      weekdayVolume: {
        filter: {
          range: {
            date_created: {
              lte: end,
              gte: start
            }
          }
        },
        aggs: {
          weekdays: {
            date_histogram: {
              field: 'date_created',
              interval: 'day',
              format: 'MM/dd/yyyy',
              time_zone: 'America/Sao_Paulo'
            },
            aggs: {
              volume: {
                sum: {
                  field: 'paid_amount'
                }
              }
            }
          }
        }
      },
      metrics: {
        date_range: {
          field: 'date_created',
          ranges: [{
            from: start,
            to: end
          }]
        },
        aggs: {
          averageAmount: {
            avg: {
              field: 'paid_amount'
            }
          },
          paidTransactions: {
            sum: {
              field: 'paid_amount'
            }
          },
          payment_method: {
            terms: {
              field: 'payment_method'
            }
          },
          card_brand: {
            terms: {
              field: 'card_brand'
            }
          },
          status: {
            terms: {
              field: 'status'
            }
          },
          refuse_reason: {
            terms: {
              field: 'refuse_reason'
            }
          },
          creditCardTransactions: {
            terms: {
              field: 'payment_method',
              include: ['credit_card']
            },
            aggs: {
              installments: {
                terms: {
                  field: 'installments'
                }
              }
            }
          }
        }
      }
    }
  };
};
var _default = exports["default"] = query;
module.exports = exports["default"];