"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _boletoAndPix = require("./boletoAndPix");
var _card = _interopRequireDefault(require("./card"));
var _result = _interopRequireDefault(require("./result"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var exportConvertion = function exportConvertion(client) {
  return function (rangeDates) {
    return Promise.all([(0, _card["default"])(client, rangeDates), (0, _boletoAndPix.boleto)(client, rangeDates), (0, _boletoAndPix.pix)(client, rangeDates)]).then(_result["default"]);
  };
};
var _default = exports["default"] = exportConvertion;
module.exports = exports["default"];