import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'

import account, { epic as accountEpic } from './Account/actions'
import errors, { epic as errorsEpic } from './ErrorBoundary'
import { reducers as transactionsReducers } from './Transactions'
import home, { epic as homeEpic } from './Home'
import { epics as paymentLinksEpics, reducers as paymentLinksReducers } from './PaymentLinks'
import onboarding, { epic as onboardingEpic } from './Onboarding'
import welcome, { epic as welcomeEpic } from './Welcome'

import recipientsReducers from './Recipients/reducer'

export const rootEpic = combineEpics(
  accountEpic,
  errorsEpic,
  homeEpic,
  onboardingEpic,
  paymentLinksEpics.list,
  paymentLinksEpics.details,
  welcomeEpic
)

export const rootReducer = combineReducers({
  account,
  errors,
  home,
  onboarding,
  paymentLinks: paymentLinksReducers.list,
  paymentLinksDetails: paymentLinksReducers.details,
  recipients: recipientsReducers.search,
  transactionDetails: transactionsReducers.details,
  transactions: transactionsReducers.search,
  welcome,
})
