import React, { forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal as FormerKitModal } from 'former-kit'
import IconClose from 'emblematic-icons/svg/ClearClose32.svg'

import styles from './style.css'

import Header from './Header'
import Description from './Description'
import Actions from './Actions'

function findCustomSlots (children, elementNames) {
  return children.filter(element => elementNames.includes(element.type))
}

const ModalCustomSlot = forwardRef(({
  children,
  isCloseButtonDisabled,
}, ref) => {
  const [open, setOpen] = useState(false)

  useImperativeHandle(
    ref,
    () => ({
      close: () => setOpen(false),
      open: () => setOpen(true),
    }),
    []
  )

  const [header, description, actions] = findCustomSlots(children, [
    Header,
    Description,
    Actions,
  ])

  return (
    <div className={styles.modal}>
      <FormerKitModal isOpen={open}>
        <div className={styles.closeButtonContainer}>
          <button
            type="button"
            onClick={() => ref.current.close()}
            className={styles.closeButton}
            disabled={isCloseButtonDisabled}
          >
            <IconClose width={14} height={14} data-testid="close-icon" />
          </button>
        </div>
        <div className={styles.container}>
          <div className={styles.content}>
            {header}
            {description}
          </div>
          <div className={styles.actions}>{actions}</div>
        </div>
      </FormerKitModal>
    </div>
  )
})

ModalCustomSlot.Description = Description
ModalCustomSlot.Actions = Actions
ModalCustomSlot.Header = Header

ModalCustomSlot.propTypes = {
  children: PropTypes.node.isRequired,
  isCloseButtonDisabled: PropTypes.bool,
}

ModalCustomSlot.defaultProps = {
  isCloseButtonDisabled: false,
}

export default ModalCustomSlot
