"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var getBalance = function getBalance(client) {
  return function (recipientId) {
    return client.balance.find({
      recipientId: recipientId
    });
  };
};
var _default = exports["default"] = getBalance;
module.exports = exports["default"];