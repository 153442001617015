import React from 'react'
import PropTypes from 'prop-types'
import styles from '../style.css'

function Header ({ children, title }) {
  return (
    <>
      {children}
      <br />
      { title && <h3 className={styles.title}>{title}</h3> }
    </>
  )
}

Header.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

Header.defaultProps = {
  children: null,
  title: '',
}

export default Header
