import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import ApiClientSingleton from '../../../../../utils/helpers/ApiClientSingleton'

const useGetCompanyKeys = ({
  page,
  size,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [hasError, setHasError] = useState(null)

  const apiClient = ApiClientSingleton.get()

  const handleGet = useCallback(async () => {
    setIsLoading(true)

    try {
      const response = await apiClient.keys.list({
        page,
        size,
      })

      setData(response.data)
    } catch (error) {
      setHasError(error)
    } finally {
      setIsLoading(false)
    }
  }, [apiClient.keys, page, size])

  useEffect(() => {
    handleGet()
  }, [handleGet])

  return useMemo(() => ({
    data,
    error: hasError,
    isLoading,
    refetch: handleGet,
  }), [data, hasError, isLoading, handleGet])
}

export default useGetCompanyKeys
