import React from 'react'
import PropTypes from 'prop-types'
import IconInfo from 'emblematic-icons/svg/Info32.svg'
import { Alert } from 'former-kit'

import { Trans } from 'react-i18next'
import styles from './style.css'

const AlertWarning = ({ components, message }) => (
  <div className={styles.testEnviromentAlert}>
    <Alert
      type="warning"
      icon={<IconInfo height={16} width={16} />}
    >
      <div>
        <Trans
          defaults={message}
          components={components}
        />
      </div>
    </Alert>
  </div>
)

AlertWarning.propTypes = {
  components: PropTypes.arrayOf(PropTypes.node),
  message: PropTypes.string.isRequired,
}

AlertWarning.defaultProps = {
  components: [],
}

export default (AlertWarning)
