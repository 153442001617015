const sortPlatforms = (x, y) => {
  const specialValues = {
    'A Plataforma não está na lista': 1,
    'Ainda não decidi a Plataforma': 2,
    'Desenvolvimento Próprio': 3,
    'Uso Link de Pagamento': 4,
  }

  const getXValueOrder = specialValues[x.value] || Infinity
  const getYValueOrder = specialValues[y.value] || Infinity

  if (getXValueOrder !== Infinity || getYValueOrder !== Infinity) {
    return getXValueOrder - getYValueOrder
  }

  const startsWithNumberX = /^\d/.test(x.value)
  const startsWithNumberY = /^\d/.test(y.value)

  if (startsWithNumberX || startsWithNumberY) {
    return startsWithNumberX ? 1 : -1
  }

  return x.value.localeCompare(y.value)
}

export default sortPlatforms
