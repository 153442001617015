"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _both2 = _interopRequireDefault(require("ramda/src/both"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var isAuthorized = (0, _propEq2["default"])('status', 'authorized');
var isFromCheckout = (0, _propEq2["default"])('referer', 'encryption_key');
var isTokenValid = function isTokenValid(transaction) {
  var createdAt = (0, _momentTimezone["default"])(transaction.date_created);
  return (0, _momentTimezone["default"])().diff(createdAt, 'hours') < 5;
};
var isCapturable = (0, _ifElse2["default"])(isFromCheckout, (0, _both2["default"])(isAuthorized, isTokenValid), isAuthorized);
var _default = exports["default"] = isCapturable;
module.exports = exports["default"];