import PropTypes from 'prop-types'

function Actions ({ children }) {
  return children
}

Actions.propTypes = {
  children: PropTypes.node,
}

Actions.defaultProps = {
  children: null,
}

export default Actions
