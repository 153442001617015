"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOutgoing = exports.getFee = exports["default"] = exports.buildTotal = exports.buildNet = void 0;
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _mergeAll2 = _interopRequireDefault(require("ramda/src/mergeAll"));
var _subtract2 = _interopRequireDefault(require("ramda/src/subtract"));
var _apply2 = _interopRequireDefault(require("ramda/src/apply"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _add2 = _interopRequireDefault(require("ramda/src/add"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _uncurryN2 = _interopRequireDefault(require("ramda/src/uncurryN"));
var _negate2 = _interopRequireDefault(require("ramda/src/negate"));
var _reduce2 = _interopRequireDefault(require("ramda/src/reduce"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _sum2 = _interopRequireDefault(require("ramda/src/sum"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var absSum = (0, _pipe2["default"])((0, _map2["default"])(Math.abs), _sum2["default"]);
var sumOut = (0, _pipe2["default"])((0, _path2["default"])(['amount', 'out']), Math.abs);
var sumIn = (0, _pipe2["default"])((0, _juxt2["default"])([(0, _path2["default"])(['amount', 'in'])]), absSum);
var buildTotal = exports.buildTotal = function buildTotal(direction) {
  return (0, _reduce2["default"])(function (acc, _ref) {
    var available = _ref.available;
    if (direction === 'out') {
      return acc + sumOut(available);
    }
    return acc + sumIn(available);
  }, 0);
};
var getOutgoing = exports.getOutgoing = (0, _pipe2["default"])(buildTotal('out'), _negate2["default"]);
var sumFee = (0, _pipe2["default"])((0, _juxt2["default"])([(0, _path2["default"])(['fee', 'in']), (0, _path2["default"])(['fee', 'out'])]), _sum2["default"], _negate2["default"]);
var sumRecuder = (0, _uncurryN2["default"])(2, function (acc) {
  return (0, _pipe2["default"])((0, _propOr2["default"])({}, 'available'), sumFee, (0, _add2["default"])(acc));
});
var getFee = exports.getFee = (0, _reduce2["default"])(sumRecuder, 0);
var buildNet = exports.buildNet = (0, _pipe2["default"])((0, _juxt2["default"])([buildTotal('in'), getOutgoing, getFee]), _sum2["default"]);
var buildBalanceTotal = (0, _applySpec2["default"])({
  net: buildNet,
  outcoming: buildTotal('in'),
  outgoing: getOutgoing,
  fee: getFee
});
var toArray = function toArray(value) {
  return [value];
};
var getDayBalance = (0, _pipe2["default"])((0, _juxt2["default"])([(0, _path2["default"])(['available', 'amount', 'balance']), (0, _path2["default"])(['available', 'fee', 'balance'])]), (0, _apply2["default"])(_subtract2["default"]));
var getDays = (0, _map2["default"])((0, _pipe2["default"])((0, _juxt2["default"])([(0, _applySpec2["default"])({
  date: (0, _prop2["default"])('date'),
  balance: getDayBalance
}), (0, _pipe2["default"])(toArray, buildBalanceTotal)]), _mergeAll2["default"]));
var build = (0, _pipe2["default"])((0, _juxt2["default"])([buildBalanceTotal, (0, _applySpec2["default"])({
  days: getDays
})]), _mergeAll2["default"]);
var _default = exports["default"] = build;