/* eslint-disable no-undef */
import hasProperty from './hasProperty'

/**
 * Identify User in FullStory
 *
 * @param {number} id user id
 * @param {string} userDateCreated account created date
 * @param {string} userPermission user permission
 * @param {string} environment current environment
 */
export const identify = (
  id,
  userDateCreated,
  userPermission,
  environment,
  type
) => {
  if (hasProperty(window.FS)) {
    window.FS.identify(
      id,
      {
        companyType_str: type,
        environment_str: environment,
        userDateCreated_date: new Date(userDateCreated),
        userPermission_str: userPermission,
      }
    )
  }
}

/**
 * Set Company data in FullStory session
 *
 * @param {string} id company id
 * @param {string} name company name
 * @param {string} dateCreated company created date
 * @param {string} status company status
 *
 */
export const setCompany = (id, name, dateCreated, status) => {
  if (hasProperty(window.FS)) {
    window.FS.setUserVars({
      companyDateCreated_date: new Date(dateCreated),
      companyId_str: id,
      companyName_str: name,
      companyStatus_str: status,
    })
  }
}

/**
 * Send chooseDashboard event to vendor sdks
 *
 * @param {string} dashboard dashboard chosen
  */
export const chooseDashboard = (dashboard) => {
  if (hasProperty(window.FS)) {
    window.FS.event('chooseDashboard', {
      dashboard_str: dashboard,
    })
  }
}

/**
 * send redirectToLegacy event to vendor sdks
 *
 * @param {string} redirectType type
  */
export const redirectToLegacy = (redirectType) => {
  if (hasProperty(window.FS)) {
    window.FS.event('redirectToLegacy', {
      redirectType_str: redirectType,
    })
  }
}

/**
 * send exportTransactions event to vendor sdks
 *
 * @param {string} exportType exportType (csv or xls)
 * @param {array} columns columns chosen
  */
export const exportTransactions = ({ columns, exportType }) => {
  if (hasProperty(window.FS)) {
    window.FS.event('exportTransactions', {
      columns: JSON.stringify(columns),
      exportType,
    })
  }
}

/**
 * send changeTransactionsViewMode event to vendor sdks
 *
 *  @param {string} viewMode view mode (table or chart)
  */
export const changeTransactionsViewMode = (viewMode) => {
  if (hasProperty(window.FS)) {
    window.FS.event('changeTransactionsViewMode', {
      viewMode,
    })
  }
}

/**
 * send changeBalanceViewMode event to vendor sdks
 *
 *  @param {string} viewMode view mode (days or operations)
  */
export const changeBalanceViewMode = (viewMode) => {
  if (hasProperty(window.FS)) {
    window.FS.event('changeBalanceViewMode', {
      viewMode,
    })
  }
}

/**
 * send dayBalanceFilter event to vendor sdks
 *
  */
export const dayBalanceFilter = () => {
  if (hasProperty(window.FS)) {
    window.FS.event('dayBalanceFilter', {})
  }
}
