/* eslint-disable */
import React, { Fragment, isValidElement,useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  HashRouter,
  Route,
  Switch,
} from 'react-router-dom'
import classNames from 'classnames'

import {
  Button,
  Header,
  HeaderBackButton,
  HeaderContent,
  HeaderTitle,
  Spacing,
} from 'former-kit'
import pagarme from 'pagarme'

import IconArrowDownRight from 'emblematic-icons/svg/ArrowDownRight24.svg'
import IconDashboard from 'emblematic-icons/svg/Dashboard32.svg'
import IconRocket from './rocket.svg'

import environment, {
  impersonate,
  liveUrl,
  apiAdminUrl,
} from '../../environment'

import style from './style.css'

import { requestLogin } from '../../pages/Account/actions/actions'

const renderTestEnviromentNav = t => (
  <div className={classNames(style.nav, style.testEnvironmentLabel)}>
    <p>{t('header.environment.text_test_1')}&nbsp;
      <b>{t('header.environment.text_test_2')}</b>
    </p>
    <a href={liveUrl}>
      {t('header.environment.text_action_test')}
      <IconArrowDownRight height={10} />
    </a>
  </div>
)

const renderImpersonateEnvNav = (env, t, test) => (
  <div className={classNames(style.nav, style.testEnvironmentLabel)}>
    <p>{env === 'test' ? t('header.environment.text_test_1') :  t('header.environment.text_prod_1')}&nbsp;
      <b>{env === 'test' ? t('header.environment.text_test_2') :  t('header.environment.text_prod_2')}</b>
    </p>
    <div className={style.navButtons}>
      <button
        className={style.impersonateCompanyButton}
        onClick={test}
        role="link"
        type="button"
      >
        {t(`header.environment.text_action_${env}`)}
        <IconArrowDownRight height={10} />
      </button>
    </div>
  </div>
)

const renderImpersonateNav = (
  companyId, name, t, onClickChangeImpersonatedCompany, onClickGoToDashAdmin
) => (
  <div className={classNames(style.nav, style.impersonateEnvironmentLabel)}>
    {`
      ${t('header.environment.text_impersonate_1')}
      ${name}
      ${t('header.environment.text_impersonate_2')}
      ${companyId}
    `}
    <div className={style.navButtons}>
      <button
        className={style.impersonateCompanyButton}
        onClick={onClickGoToDashAdmin}
        role="link"
        type="button"
      >
        {t('header.environment.text_action_dash_admin')}
        <IconDashboard height={16} />
      </button>
      <button
        className={style.impersonateCompanyButton}
        onClick={onClickChangeImpersonatedCompany}
        role="link"
        type="button"
      >
        {t('header.environment.text_action_impersonate')}
        <IconArrowDownRight height={10} />
      </button>
    </div>
  </div>
)

const HeaderContainer = ({
  company,
  onBack,
  onClickChangeImpersonatedCompany,
  onClickGoToDashAdmin,
  onWelcome,
  routes,
  showWelcomeButton,
  t,
  user,
}) => {
  const { sessionId, impersonationEnv, impersonationKey, jwt } = useSelector(state => state.account)
  const state = useSelector(state => state)

  const dispatch = useDispatch()

  const handleChangeEnvironment = useCallback(async (e) => {
    e.preventDefault()

    const currentEnvironment = impersonationEnv || environment
    const nextEnvironment = currentEnvironment === 'live' ? 'test' : 'live'
    sessionStorage.setItem("impersonationEnv", nextEnvironment );

    try {
      const params = {
        company_id: impersonationKey,
        environment: nextEnvironment,
        impersonationKey,
        jwt,
        options: {
          baseURL: apiAdminUrl,
        },
      }

      await pagarme.client.connect(params)

      dispatch(requestLogin({
        impersonationEnv: nextEnvironment,
        ...params,
      }))
    } catch (err) {
    }
  }, [dispatch, sessionId, state])

  return (
    <Fragment>
      { environment === 'test' && renderTestEnviromentNav(t) }
      { impersonate
        && renderImpersonateNav(
          company.id,
          user.name,
          t,
          onClickChangeImpersonatedCompany,
          onClickGoToDashAdmin
        )
      }
      { impersonate && renderImpersonateEnvNav(impersonationEnv || environment, t, handleChangeEnvironment) }
      <Header>
        <HashRouter>
          <Switch>
            {routes.map(({
              icon: Icon,
              path: pathURI,
              otherPaths = [],
              showHeaderBack,
              title,
            }) => (
              <Route
                exact
                key={pathURI}
                path={[pathURI, ...otherPaths]}
                render={() => (
                  <Fragment>
                    {showHeaderBack && <HeaderBackButton onClick={onBack} />}
                    {!showHeaderBack && Icon && <Icon width={16} height={16} />}
                    <HeaderTitle>{t(title)}</HeaderTitle>
                  </Fragment>
                )}
              />
            ))}
          </Switch>
        </HashRouter>
        <HeaderContent>
          {showWelcomeButton && (
            <Fragment>
              <Button
                icon={<IconRocket />}
                onClick={onWelcome}
              >
                <span className={style.welcome}>
                  {t('header.welcome')}
                </span>
              </Button>

              <Spacing size="small" />
            </Fragment>
          )}
        </HeaderContent>
      </Header>
    </Fragment>
)}

HeaderContainer.propTypes = {
  company: PropTypes.shape({
    date_created: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  onBack: PropTypes.func.isRequired,
  onClickChangeImpersonatedCompany: PropTypes.func,
  onClickGoToDashAdmin: PropTypes.func,
  onWelcome: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    component: isValidElement,
    path: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  showWelcomeButton: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
}

HeaderContainer.defaultProps = {
  company: {},
  onClickChangeImpersonatedCompany: () => {},
  onClickGoToDashAdmin: () => {},
}

export default HeaderContainer
