import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { compose } from 'ramda'
import { Alert, Button } from '@stone-payments/jade'
import { differenceInMinutes, isAfter } from 'date-fns'
import useCountDown from 'react-countdown-hook'
import { withError } from '../../../ErrorBoundary'
import CardInfo from '../../../../components/CardInfo'
import LockSvg from '../../assets/lock.svg'

import styles from './styles.css'
import CardQrCode from '../../components/CardQrCode'
import usePostKycQrCode from '../../../../hooks/usePostKycQrCode'
import { useKyc } from '../../context/KycContext'

const enhanced = compose(translate(), withError)
const tPrefix = 'pages.kyc.pages.qrcode'

const INTERVAL_TO_REQUEST = 5 * 60 * 1000 // 5 minutes

const getExpiresTimer = (expiresAt) => {
  const minutes = differenceInMinutes(new Date(expiresAt), new Date())

  return (minutes + 1) * 60000
}

const getIsExpired = (result) => {
  if (result && result.expires_at) {
    return isAfter(new Date(), new Date(result.expires_at))
  }

  return true
}

const QrCode = ({ t }) => {
  const { requestStatus } = useKyc()
  const {
    error, isLoading, request, result,
  } = usePostKycQrCode({ runOnMount: true })
  const [timer, { start }] = useCountDown()

  const isExpired = getIsExpired(result)

  useEffect(() => {
    let intervalToRequestKycStatus

    if (result) {
      intervalToRequestKycStatus = setInterval(
        requestStatus,
        INTERVAL_TO_REQUEST
      )
    }

    return () => clearInterval(intervalToRequestKycStatus)
  }, [result, requestStatus])

  useEffect(() => {
    if (result && result.expires_at) {
      const expiresTimer = getExpiresTimer(result.expires_at)
      start(expiresTimer)
    }
  }, [result, start])

  const handleOpenInDevice = () => {
    window.open(result?.url, '_blank')
  }

  return (
    <div className={styles.pageWrapper}>
      {!!error && !isLoading && (
        <Alert
          description={t(`${tPrefix}.error`)}
          showIcon
          variant="negative"
        />
      )}
      <CardInfo
        icon={<LockSvg />}
        title={t(`${tPrefix}.info_title`)}
        subTitle={t(`${tPrefix}.info_subtitle`)}
      />
      <CardQrCode
        isExpired={timer === 0 || isExpired}
        isLoading={isLoading}
        qrcode={result?.base64_qrcode}
        onClickToCreateNewQrCode={request}
        t={t}
        time={timer}
      />
      {!!result?.url && (
        <Button
          variant="neutral-ghost"
          size="large"
          onClick={handleOpenInDevice}
        >
          {t(`${tPrefix}.button`)}
        </Button>
      )}
    </div>
  )
}

QrCode.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(QrCode)
