"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var formatAddress = (0, _applySpec2["default"])({
  street: (0, _propOr2["default"])('', 'street'),
  complementary: (0, _propOr2["default"])('', 'complementary'),
  streetNumber: (0, _propOr2["default"])('', 'street_number'),
  neighborhood: (0, _propOr2["default"])('', 'neighborhood'),
  city: (0, _propOr2["default"])('', 'city'),
  state: (0, _propOr2["default"])('', 'state'),
  zipcode: (0, _propOr2["default"])('', 'zipcode')
});
var _default = exports["default"] = (0, _pipe2["default"])((0, _propOr2["default"])({}, 'address'), formatAddress);
module.exports = exports["default"];