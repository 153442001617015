"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _divide2 = _interopRequireDefault(require("ramda/src/divide"));
var _both2 = _interopRequireDefault(require("ramda/src/both"));
var _when2 = _interopRequireDefault(require("ramda/src/when"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _is2 = _interopRequireDefault(require("ramda/src/is"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _props2 = _interopRequireDefault(require("ramda/src/props"));
var _splitAt10 = _interopRequireDefault(require("ramda/src/splitAt"));
var _length2 = _interopRequireDefault(require("ramda/src/length"));
var _append2 = _interopRequireDefault(require("ramda/src/append"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _propIs2 = _interopRequireDefault(require("ramda/src/propIs"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _join2 = _interopRequireDefault(require("ramda/src/join"));
var _values2 = _interopRequireDefault(require("ramda/src/values"));
var _pick2 = _interopRequireDefault(require("ramda/src/pick"));
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _defaultTo2 = _interopRequireDefault(require("ramda/src/defaultTo"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _intl = _interopRequireDefault(require("intl"));
require("intl/locale-data/jsonp/pt");
var _acquirerNames = _interopRequireDefault(require("./acquirerNames"));
var _antifraudStatusNames = _interopRequireDefault(require("./antifraudStatusNames"));
var _cardBrandNames = _interopRequireDefault(require("./cardBrandNames"));
var _statusName = _interopRequireDefault(require("../../utils/statusName"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
var LIMITER = '-';
var isEmptyOrNill = (0, _either2["default"])(_isNil2["default"], _isEmpty2["default"]);
var defaultToLimiter = (0, _defaultTo2["default"])(LIMITER);
var propOrLimiter = function propOrLimiter(property) {
  return (0, _pipe2["default"])((0, _propOr2["default"])(LIMITER, property), defaultToLimiter);
};
var getAntifraudProp = (0, _pipe2["default"])((0, _propOr2["default"])('unknown', 'risk_level'), (0, _prop2["default"])(_2["default"], _antifraudStatusNames["default"]));
var pickCardDigits = (0, _pick2["default"])(['card_first_digits', 'card_last_digits']);
var concatCardDigits = (0, _pipe2["default"])(pickCardDigits, _values2["default"], (0, _join2["default"])('******'));
var getCardNumber = (0, _ifElse2["default"])((0, _propIs2["default"])(String, 'card_first_digits'), concatCardDigits, (0, _always2["default"])(LIMITER));
var getCustomerName = (0, _pathOr2["default"])(LIMITER, ['customer', 'name']);
var getEndToEndPixId = (0, _pathOr2["default"])(LIMITER, ['pix_data', 'end_to_end_id']);
var getCustomerSubProp = function getCustomerSubProp(subProp) {
  return (0, _pathOr2["default"])(LIMITER, ['customer', subProp]);
};
var getAddressProp = function getAddressProp(property) {
  return (0, _path2["default"])(['address', property]);
};
var getAddressPropFromBilling = function getAddressPropFromBilling(property) {
  return (0, _path2["default"])(['billing', 'address', property]);
};
var getAddressSubProp = function getAddressSubProp(property) {
  return (0, _pipe2["default"])((0, _either2["default"])(getAddressProp(property), getAddressPropFromBilling(property)), defaultToLimiter);
};
var getStatusName = (0, _pipe2["default"])((0, _prop2["default"])('status'), (0, _prop2["default"])(_2["default"], _statusName["default"]));
var isStatusRefused = (0, _propEq2["default"])('status', 'refused');
var getRefuseReason = (0, _pipe2["default"])((0, _prop2["default"])('status_reason'), (0, _append2["default"])(_2["default"], ['refused']), (0, _path2["default"])(_2["default"], _statusName["default"]));
var getStatus = (0, _ifElse2["default"])(isStatusRefused, getRefuseReason, getStatusName);
var paymentMethodNames = {
  boleto: 'Boleto',
  credit_card: 'Cartão de Crédito',
  pix: 'Pix'
};
var getPaymentMethod = (0, _pipe2["default"])((0, _prop2["default"])('payment_method'), (0, _prop2["default"])(_2["default"], paymentMethodNames));
var completeNumberRegex = /(\+*[0-9]{2})([1-9]{2})(([9][0-9]{4})|[0-9]{4})([0-9]{4})/;
var isCompletePhoneNumber = function isCompletePhoneNumber(number) {
  return completeNumberRegex.test(number);
};
var formatPhoneNumber = function formatPhoneNumber(number) {
  if (!number) return '';
  var phoneLength = (0, _length2["default"])(number);
  var _splitAt = (0, _splitAt10["default"])(phoneLength - 4, number),
    _splitAt2 = _slicedToArray(_splitAt, 2),
    phoneRemainder = _splitAt2[0],
    last4Digit = _splitAt2[1];

  // this case is common for API versions 2013-03-01 and 2017-07-17
  if (phoneLength <= 9) {
    return "".concat(phoneRemainder, "-").concat(last4Digit);
  }
  var phoneRemainderLength = (0, _length2["default"])(phoneRemainder);
  if (isCompletePhoneNumber(number)) {
    var _splitIndex = phoneRemainderLength >= 10 ? phoneRemainderLength - 5 : phoneRemainderLength - 4;
    var _splitAt4 = (0, _splitAt10["default"])(_splitIndex, phoneRemainder),
      _splitAt5 = _slicedToArray(_splitAt4, 2),
      _remainder = _splitAt5[0],
      _firstDigits = _splitAt5[1];
    var _splitAt6 = (0, _splitAt10["default"])((0, _length2["default"])(_remainder) - 2, _remainder),
      _splitAt7 = _slicedToArray(_splitAt6, 2),
      ddi = _splitAt7[0],
      ddd = _splitAt7[1];
    var outNumber = "".concat(_firstDigits, "-").concat(last4Digit);
    if (ddd) {
      outNumber = "(".concat(ddd, ") ").concat(outNumber);
    }
    if (ddi) {
      outNumber = "".concat(ddi, " ").concat(outNumber);
    }
    return outNumber;
  }
  var splitIndex = phoneRemainderLength >= 7 ? phoneRemainderLength - 5 : phoneRemainderLength - 4;
  var _splitAt8 = (0, _splitAt10["default"])(splitIndex, phoneRemainder),
    _splitAt9 = _slicedToArray(_splitAt8, 2),
    remainder = _splitAt9[0],
    firstDigits = _splitAt9[1];
  return "(".concat(remainder, ") ").concat(firstDigits, "-").concat(last4Digit);
};
var formatPhone = (0, _pipe2["default"])((0, _props2["default"])(['ddd', 'number']), (0, _juxt2["default"])([(0, _pipe2["default"])(_head2["default"], function (ddd) {
  return "(".concat(ddd, ")");
}), (0, _pipe2["default"])(_last2["default"], formatPhoneNumber)]), (0, _join2["default"])(' '));
var getRecipients = (0, _pipe2["default"])((0, _prop2["default"])('split_rules'), (0, _ifElse2["default"])((0, _is2["default"])(Array), (0, _pipe2["default"])((0, _map2["default"])((0, _propOr2["default"])('', 'recipient_id')), (0, _join2["default"])(', ')), (0, _always2["default"])('Recebedor Padrão')));
var getPhone = (0, _pipe2["default"])((0, _prop2["default"])('phone'), (0, _ifElse2["default"])(isEmptyOrNill, (0, _always2["default"])(LIMITER), formatPhone));
var formatPhones = (0, _pipe2["default"])((0, _path2["default"])(['customer', 'phone_numbers']), (0, _ifElse2["default"])(isEmptyOrNill, (0, _always2["default"])(LIMITER), (0, _pipe2["default"])((0, _map2["default"])(formatPhoneNumber), (0, _join2["default"])(', '))));
var getPhones = (0, _ifElse2["default"])((0, _pipe2["default"])((0, _prop2["default"])('phone'), isEmptyOrNill), formatPhones, getPhone);
var getId = (0, _prop2["default"])('id');
var getTid = (0, _prop2["default"])('tid');
var getNsu = (0, _prop2["default"])('nsu');
var hasCustomerDocumentNumber = (0, _pipe2["default"])((0, _path2["default"])(['customer', 'document_number']), (0, _complement2["default"])(_isNil2["default"]));
var getCustomerDocumentNumber = (0, _pipe2["default"])((0, _path2["default"])(['customer', 'document_number']), (0, _when2["default"])(_isNil2["default"], (0, _always2["default"])(LIMITER)));
var isArrayNotEmpty = (0, _both2["default"])((0, _is2["default"])(Array), (0, _complement2["default"])(_isEmpty2["default"]));
var getDocumentList = (0, _pipe2["default"])((0, _path2["default"])(['customer', 'documents']), (0, _ifElse2["default"])(isArrayNotEmpty, (0, _pipe2["default"])((0, _map2["default"])((0, _propOr2["default"])('', 'number')), (0, _join2["default"])(', ')), (0, _always2["default"])(LIMITER)));
var getDocumentNumber = (0, _ifElse2["default"])(hasCustomerDocumentNumber, getCustomerDocumentNumber, getDocumentList);
var formatDate = function formatDate(date) {
  return (0, _momentTimezone["default"])(date).format('DD/MM/YYYY HH:mm');
};
var getCreatedDate = (0, _pipe2["default"])((0, _prop2["default"])('date_created'), formatDate);
var getUpdateData = (0, _pipe2["default"])((0, _prop2["default"])('date_updated'), formatDate);
var getAcquirerName = (0, _pipe2["default"])((0, _prop2["default"])('acquirer_name'), (0, _prop2["default"])(_2["default"], _acquirerNames["default"]), defaultToLimiter);
var getCardBrand = (0, _pipe2["default"])((0, _prop2["default"])('card_brand'), (0, _prop2["default"])(_2["default"], _cardBrandNames["default"]), defaultToLimiter);
var formatToBRL = new _intl["default"].NumberFormat('pt-BR', {
  minimumFractionDigits: 2
});
var getBRLProp = function getBRLProp(property) {
  return (0, _pipe2["default"])((0, _prop2["default"])(property), (0, _ifElse2["default"])(_isNil2["default"], (0, _always2["default"])(0), (0, _divide2["default"])(_2["default"], 100)), formatToBRL.format);
};
var getJsonProp = function getJsonProp(property) {
  return (0, _pipe2["default"])((0, _propOr2["default"])({}, property), JSON.stringify);
};
var transactionSpec = {
  status: getStatus,
  id: getId,
  created_at: getCreatedDate,
  name: getCustomerName,
  payment_method: getPaymentMethod,
  card_number: getCardNumber,
  documents: getDocumentNumber,
  email: getCustomerSubProp('email'),
  subscription: propOrLimiter('subscription_id'),
  phones: getPhones,
  acquirer_name: getAcquirerName,
  acquirer_response_code: propOrLimiter('acquirer_response_code'),
  ip: propOrLimiter('ip'),
  brand_name: getCardBrand,
  amount: getBRLProp('amount'),
  paid_amount: getBRLProp('paid_amount'),
  refunded_amount: getBRLProp('refunded_amount'),
  split_rules: getRecipients,
  street: getAddressSubProp('street'),
  streetNumber: getAddressSubProp('street_number'),
  complementary: getAddressSubProp('complementary'),
  neighborhood: getAddressSubProp('neighborhood'),
  zipcode: getAddressSubProp('zipcode'),
  city: getAddressSubProp('city'),
  state: getAddressSubProp('state'),
  antifraud: getAntifraudProp,
  metadata: getJsonProp('metadata'),
  metadata_in_columns: getJsonProp('metadata'),
  updated_at: getUpdateData,
  cost: getBRLProp('cost'),
  installments: propOrLimiter('installments'),
  card_first_digits: propOrLimiter('card_first_digits'),
  card_last_digits: propOrLimiter('card_last_digits'),
  boleto_expiration_date: propOrLimiter('boleto_expiration_date'),
  boleto_url: propOrLimiter('boleto_url'),
  tid: getTid,
  authorization_code: propOrLimiter('authorization_code'),
  nsu: getNsu,
  items: getJsonProp('items'),
  card_holder_name: propOrLimiter('card_holder_name'),
  pix: getEndToEndPixId,
  card_funding_source: propOrLimiter('card_funding_source')
};
var _default = exports["default"] = transactionSpec;
module.exports = exports["default"];