import React from 'react'
import PropTypes from 'prop-types'
import { Flexbox } from 'former-kit'

import MenuAvatar from '../../../../assets/menuAvatar.svg'
import formatCpfCnpj from '../../../../formatters/cpfCnpj'
import styles from './style.css'

const MenuHeader = ({ companyCnpj, companyName }) => (
  <div className={styles.container}>
    <MenuAvatar />
    <Flexbox className={styles.companyInformations} direction="column">
      <span className={styles.companyName}>{companyName}</span>
      <span className={styles.companyCnpj}>{formatCpfCnpj(companyCnpj)}</span>
    </Flexbox>
  </div>
)

MenuHeader.propTypes = {
  companyCnpj: PropTypes.string,
  companyName: PropTypes.string,
}

MenuHeader.defaultProps = {
  companyCnpj: '',
  companyName: '',
}

export default MenuHeader
