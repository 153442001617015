const BFFKnowErrors = {
  'Identificamos que para a company informada possuímos mais de uma referência na Dashboard Pagar.Me 2.0, será necessário que a manipulação da chave ocorra por lá': 'error_have_two_accounts',
  'Você deve informar uma company valida': 'error_do_not_have_access',
}

const getErrorStatus = (error) => {
  if (error?.response) {
    return error.response.status
  }

  return undefined
}

const getErrorMessage = ({ error, t }) => {
  if (!error) {
    return null
  }

  const status = getErrorStatus(error)
  const genericError = t('pages.company_settings.api_data.error_general')
  const errorMessage = error?.response?.data

  if (errorMessage && Object.keys(errorMessage).length) {
    const errorKey = Object.keys(errorMessage)[0]
    const BFFErrorMessage = BFFKnowErrors[errorKey]

    if (BFFErrorMessage) {
      return t(`pages.company_settings.api_data.${BFFErrorMessage}`)
    }
  }

  const errors = {
    401: t('pages.company_settings.api_data.error_password'),
    412: t('pages.company_settings.api_data.error_password'),
  }

  if (status) {
    return errors[status] || genericError
  }

  return genericError
}

export default getErrorMessage
