const isOnboardingFinished = (
  onboardingAnswers
) => {
  const numberOfquestionAnswered = Object.keys(onboardingAnswers).length

  if (numberOfquestionAnswered === 0 || numberOfquestionAnswered < 6) {
    return false
  }

  return true
}

export default isOnboardingFinished
