"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _environment = _interopRequireDefault(require("../../../environment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var _default = exports["default"] = (0, _path2["default"])(['api_version', _environment["default"]]);
module.exports = exports["default"];