"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortByCreationDate = exports.search = exports["default"] = void 0;
var _sort2 = _interopRequireDefault(require("ramda/src/sort"));
var _buildQuery = _interopRequireDefault(require("./buildQuery"));
var _buildResult = _interopRequireDefault(require("./buildResult"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var sortByCreationDate = exports.sortByCreationDate = (0, _sort2["default"])(function (a, b) {
  return new Date(a.date_created) - new Date(b.date_created);
});
var search = exports.search = function search(client) {
  return function (query) {
    return client.bulkAnticipations.all((0, _buildQuery["default"])(query)).then(sortByCreationDate).then(_buildResult["default"]);
  };
};
var _default = exports["default"] = search;