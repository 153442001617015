"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _info = _interopRequireDefault(require("./info"));
var _update = require("./update");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var _default = exports["default"] = {
  info: _info["default"],
  update: _update.updateCompany,
  updateBrandingLogo: _update.updateBrandingLogo,
  updateSubscriptionPostback: _update.updateSubscriptionPostback,
  updateSubscription: _update.updateSubscription
};
module.exports = exports["default"];