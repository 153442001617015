"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _formatBankAccount = _interopRequireDefault(require("./formatBankAccount"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var bankAccount = function bankAccount(client) {
  return function (documentData) {
    var document = documentData[documentData.documentType];
    var documentNumber = document.replace(/\D/g, '');
    var query = {
      document_number: documentNumber,
      count: 100
    };
    return client.bankAccounts.all(query).then(_formatBankAccount["default"]);
  };
};
var _default = exports["default"] = bankAccount;
module.exports = exports["default"];