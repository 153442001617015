import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Radio } from 'former-kit'
import Card from '../Card'

import style from './style.css'

const CardContent = ({
  description,
  label,
}) => (
  <div>
    <div className={style.labelText}>{label}</div>
    <span className={style.descriptionText}>{description}</span>
  </div>
)
CardContent.propTypes = {
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

const RadioCardOptions = ({
  handleSubmit,
  options,
  t,
}) => {
  const [selectedOption, setSelectedOption] = useState()

  const handleChange = e => setSelectedOption(e.target.value)

  return (
    <div className={style.radioCardOptions}>
      <div className={style.radioContainer}>
        {options.map(({ description, label, value }) => (
          <div key={value}>
            <Card className={style.card}>
              <Radio
                name="radioCardOptions"
                id={`radioCardOptions-${value}`}
                value={value}
                checked={selectedOption === value}
                onChange={handleChange}
                size="huge"
                label={(
                  <CardContent
                    label={label}
                    description={description}
                  />
                )}
              />
            </Card>
          </div>
        ))}
      </div>
      <Button
        disabled={!selectedOption}
        size="huge"
        onClick={() => handleSubmit(selectedOption)}
      >
        {t('pages.onboarding.advance_step')}
      </Button>
    </div>
  )
}

RadioCardOptions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  t: PropTypes.func.isRequired,
}

export default RadioCardOptions
