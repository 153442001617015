"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _query = _interopRequireDefault(require("./query"));
var _result = _interopRequireDefault(require("./result"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var metrics = function metrics(client) {
  return function (rangeDates) {
    return client.search({
      query: JSON.stringify((0, _query["default"])(rangeDates)),
      type: 'transaction',
      search_type: 'count'
    }).then(_result["default"]);
  };
};
var _default = exports["default"] = metrics;
module.exports = exports["default"];