import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { compose } from 'ramda'
import { Button } from '@stone-payments/jade'
import { withError } from '../../../ErrorBoundary'
import CardDefault from '../../components/CardDefault'
import UnauthorizedSVG from '../../assets/denied.svg'

import styles from './styles.css'
import { profileUrl } from '../../../../environment'

const enhanced = compose(
  translate(),
  withError
)

const Unauthorized = ({
  t,
}) => {
  const tPrefix = 'pages.kyc.pages.unauthorized'

  const handleGoToProfile = () => {
    window.location.href = profileUrl
  }

  return (
    <div className={styles.pageWrapper}>
      <CardDefault
        title={t(`${tPrefix}.title`)}
        subTitle={t(`${tPrefix}.subtitle`)}
        icon={<UnauthorizedSVG />}
      >
        <Button size="large" onClick={handleGoToProfile}>{t(`${tPrefix}.button`)}</Button>
      </CardDefault>
    </div>
  )
}

Unauthorized.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(Unauthorized)
