/* eslint-disable sort-keys */

import { Contracts } from '../dynamicImports'

const contractsRoutes = {
  contracts: {
    component: Contracts,
    showInSidebar: false,
    showHeaderBack: false,
    showSubLink: true,
    path: '/contracts',
    subLinkPath: ['financial', 'contracts'],
    relevance: 7,
    title: 'pages.contracts.list.title',
    value: 'transfers',
  },
  contractsWithRecipientId: {
    showHeaderBack: false,
    showInSidebar: false,
    path: '/contracts/:recipientId',
    title: 'pages.contracts.list.title',
  },
  contractsList: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/contracts/:recipientId/:tabActive',
    title: 'pages.contracts.list.title',
  },
  contractsOfDay: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/contracts/:recipientId/values/:day',
    title: 'pages.contracts.day.title',
  },
  contractsInstitution: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/contracts/:recipientId/institution/:contractHolder',
    title: 'pages.contracts.institution.title',
  },
  contractsDetails: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/contracts/:recipientId/contract/:contractKey',
    title: 'pages.contracts.details.title',
  },
  contractsContestation: {
    showHeaderBack: true,
    showInSidebar: false,
    path: '/contracts/:recipientId/contract/:contractKey/contestation',
    title: 'pages.contracts.contestation.title',
  },
}

export default contractsRoutes
