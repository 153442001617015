"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _pluck2 = _interopRequireDefault(require("ramda/src/pluck"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var makeRows = (0, _pipe2["default"])((0, _path2["default"])(['hits', 'hits']), (0, _pluck2["default"])('_source'));
var result = (0, _applySpec2["default"])({
  totalPaymentLinks: (0, _path2["default"])(['hits', 'total']),
  rows: makeRows
});
var _default = exports["default"] = result;
module.exports = exports["default"];