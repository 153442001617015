import React, {
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { flushSync } from 'react-dom'
import PropTypes from 'prop-types'
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import {
  applySpec,
  complement,
  compose,
  find,
  isNil,
  path,
  pipe,
  prop,
  propEq,
  values,
} from 'ramda'
import { Layout } from 'former-kit'

import { ErrorBoundary } from '../ErrorBoundary'
import Sidebar from './Sidebar'
import Header from './Header'
import Loader from '../../components/Loader'
import environment from '../../environment'
import buildRoutes from './routes'
import isAdminOrImpersonated from '../../validation/isAdminOrImpersonated'
import isPaymentLink from '../../validation/isPaymentLink'

import style from './style.css'
import PlatformProvider from '../../providers/Platform'

const getCompany = path(['account', 'company'])
const getAuthenticatedUser = path(['account', 'user'])
const getAccountSessionId = path(['account', 'sessionId'])

const mapStateToProps = applySpec({
  client: path(['account', 'client']),
  company: getCompany,
  sessionId: getAccountSessionId,
  user: getAuthenticatedUser,
})

const enhanced = compose(
  translate(),
  withRouter,
  connect(mapStateToProps)
)

const getDefaultRoutePath = pipe(
  values,
  find(propEq('defaultRoute', true)),
  prop('path')
)

const isPagarmeAcquirer = acquirers => (acquirers.some(acquirer => acquirer.name === 'pagarme'))

const LoggedArea = ({
  client,
  company,
  sessionId,
  t,
  user,
}) => {
  const [loading, setLoading] = useState(true)
  const [acquirers, setAcquirers] = useState(null)
  const { type } = company
  const routes = useMemo(() => buildRoutes({
    canDoPayments: company.canDoPayments,
    company,
    environment,
    hasCreditLines: company.hasCreditLines,
    hasExternalContract: company.hasExternalContract,
    hasLoan: company.hasLoan,
    isAdminOrImpersonated: isAdminOrImpersonated(user),
    isPagarmeAcquirer: isPagarmeAcquirer(acquirers || []),
    isPaymentLink: isPaymentLink(type),
    user,
  }), [company, user, acquirers, type])

  useEffect(() => {
    const fetchAcquirers = async () => {
      const response = await client.acquirers.all()

      flushSync(() => {
        setAcquirers(response)

        setLoading(false)
      })
    }

    fetchAcquirers()
  }, [client])

  const defaultRoute = getDefaultRoutePath(routes)

  const renderedRoutes = values(routes)

  if (loading) {
    return (
      <div data-testid="loader">
        <Loader
          position="absolute"
          visible
        />
      </div>
    )
  }

  return (
    <PlatformProvider>
      <Layout
        sidebar={(
          <Sidebar
            userName={prop('name', user)}
            userEmail={prop('email', user)}
            companyName={prop('name', company)}
            companyCnpj={prop('cnpj', company)}
            t={t}
            routes={renderedRoutes}
          />
      )}
        header={(
          <Header
            t={t}
            routes={routes}
            sessionId={sessionId}
          />
      )}
        footer={(
          <div className={style.footer} />
      )}
      >
        <ErrorBoundary>
          <Suspense
            fallback={(
              <div data-testid="internalLoader">
                <Loader
                  position="relative"
                  visible
                />
              </div>
          )}
          >
            <Switch>
              {values(routes)
                .filter(({ component }) => complement(isNil(component)))
                .map(({ component, path: pathURI, otherPaths = [] }) => (
                  <Route
                    key={pathURI}
                    path={[pathURI, ...otherPaths]}
                    component={component}
                  />
                ))}
              <Redirect to={defaultRoute} />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Layout>
    </PlatformProvider>
  )
}

LoggedArea.propTypes = {
  client: PropTypes.shape({
    acquirers: PropTypes.shape({ all: PropTypes.func }),
  }).isRequired,
  company: PropTypes.shape({
    canDoPayments: PropTypes.bool,
    hasCreditLines: PropTypes.bool,
    hasExternalContract: PropTypes.bool,
    hasLoan: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  sessionId: PropTypes.string,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    permission: PropTypes.string,
  }),
}

LoggedArea.defaultProps = {
  company: {},
  sessionId: '',
  user: {},
}

export default enhanced(LoggedArea)
