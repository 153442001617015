import React from 'react'
import { compose } from 'ramda'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import KycAnalysis from './pages/ValidationAnalyze'
import KycCustomization from './pages/Customization'
import KycDenied from './pages/Denied'
import KycQrCode from './pages/QrCode'
import KycUnauthorized from './pages/Unauthorized'
import { useKyc } from './context/KycContext'
import Header from './components/Header'
import { withError } from '../ErrorBoundary'

import styles from './index.module.css'
import Steps from '../../components/Steps'
import { profileUrl } from '../../environment'

const enhanced = compose(translate(), withError)

const Kyc = ({ t }) => {
  const { step } = useKyc()

  const handleClose = () => {
    window.location.href = profileUrl
  }

  const stepsPage = {
    analysis: <KycAnalysis />,
    customization: <KycCustomization />,
    denied: <KycDenied />,
    qrcode: <KycQrCode />,
    unauthorized: <KycUnauthorized />,
  }

  const steps = [
    {
      icon: 'person-check',
      isFilled: ['analysis', 'qrcode', 'customization'].includes(step),
      title: t('pages.kyc.steps.validation'),
    },
    {
      icon: 'shop',
      isFilled: ['customization', 'conclusion'].includes(step),
      title: t('pages.kyc.steps.customization'),
    },
    {
      icon: 'circle-check',
      isFilled: step === 'conclusion',
      title: t('pages.kyc.steps.conclusion'),
    },
  ]

  return (
    <div className={styles.pageWrapper}>
      <Header t={t} onClose={handleClose} />
      <div className={styles.pageForm}>
        {['analysis', 'qrcode', 'customization', 'conclusion'].includes(step) && (
          <Steps steps={steps} />
        )}
        {stepsPage[step]}
      </div>
    </div>
  )
}

Kyc.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(Kyc)
