import { Analitica } from '@stone-payments/analitica'
import { AmplitudePlugin } from '../../../../node_modules/@stone-payments/analitica/src/plugins/amplitude/amplitude'
import environment, { impersonate } from '../environment'

class AmplitudePilot extends AmplitudePlugin {
  name = 'AMPLITUDE'

  sendEvent (event) {
    const { label, metadata, ...rest } = event

    if (this.instance) {
      this.instance.logEvent(label, { ...metadata, ...rest })
    }
  }
}

const isProduction = process.env.NODE_ENV === 'production' && !impersonate && environment === 'live'

const analiticaInstance = new Analitica({
  app: { version: process.env.REACT_APP_VERSION },
  user: {
    id: undefined,
  },
}, [
  new AmplitudePilot(process.env.REACT_APP_AMPLITUDE_API_KEY ?? '',
    { defaultTracking: false }),
],
{ dryRun: !isProduction })

export default analiticaInstance
