"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.impersonate = exports["default"] = exports.apiUrl = exports.apiGanonUrl = void 0;
var _process$env = process.env,
  REACT_APP_API_ENVIRONMENT = _process$env.REACT_APP_API_ENVIRONMENT,
  REACT_APP_IMPERSONATE = _process$env.REACT_APP_IMPERSONATE;
var env = REACT_APP_API_ENVIRONMENT === 'live' ? 'live' : 'test';
var apiUrl = exports.apiUrl = REACT_APP_IMPERSONATE ? 'https://hodor.network.pagarme.net/api-admin' : 'https://api.pagar.me/1/';
var apiGanonUrl = exports.apiGanonUrl = 'https://ganon-api.prd.pagarme.net/';
var impersonate = exports.impersonate = REACT_APP_IMPERSONATE === 'true';
var _default = exports["default"] = env;