import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Text } from '@stone-payments/jade'

import styles from './styles.css'

const Steps = ({ steps }) => (
  <div className={styles.stepsContainer}>
    {steps?.map((step, index) => (
      <>
        <div key={step.title} className={styles.stepsItem}>
          <Icon
            size="large"
            color={step.isFilled ? 'brand' : 'low'}
            use={step.icon}
          />
          <Text
            color={step.isFilled ? 'brand' : 'low'}
            weight="semibold"
            variant="text-large"
          >
            {step.title}
          </Text>
        </div>
        {index + 1 < steps.length && (
          <Icon size="large" color="low" use="chevron-right" />
        )}
      </>
    ))}
  </div>
)

Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func.isRequired,
      isFilled: PropTypes.string.isRequired,
      title: PropTypes.bool.isRequired,
    })
  ).isRequired,
}

export default Steps
