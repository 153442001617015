"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _build = _interopRequireDefault(require("./build"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var refund = function refund(client) {
  return function (_ref) {
    var body = _ref.body,
      transaction = _ref.transaction;
    var payload = (0, _build["default"])({
      body: body,
      transaction: transaction
    });
    return client.transactions.refund(payload);
  };
};
var _default = exports["default"] = refund;
module.exports = exports["default"];