import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Checkbox,
  InputField,
  Text,
  ListItemSelection,
  IconShape,
  ListItem,
  Button,
} from '@stone-payments/jade'
import { isEmpty } from 'ramda'

import styles from './style.css'

const tPrefix = 'pages.settings.company.add_key.key_info_card'

const KeyInfoCard = ({
  keyName: propsKeyName,
  onSubmit,
  selectedType: propsSelectedType,
  t,
}) => {
  const [selectedType, setSelectedType] = useState(propsSelectedType)
  const [keyName, setKeyName] = useState(propsKeyName)
  const [isPostbackDerived, setIsPostbackDerived] = useState(false)

  const permissionsTypes = [
    {
      description: t(`${tPrefix}.item_select_total_description`),
      icon: 'file-folded',
      id: 'total',
      title: t(`${tPrefix}.item_select_total_title`),
    },
    {
      description: t(`${tPrefix}.item_select_custom_description`),
      icon: 'menu-grid',
      id: 'custom',
      title: t(`${tPrefix}.item_select_custom_title`),
    },
  ]

  const hasNotSelectAny = isEmpty(selectedType) && !isPostbackDerived
  const buttonDisabled = hasNotSelectAny || isEmpty(keyName)

  const handleSubmit = () => {
    onSubmit({
      isPostbackDerived,
      name: keyName,
      type: selectedType,
    })
  }

  return (
    <Box
      title={t(`${tPrefix}.card_title`)}
      data-testid="box-key-info-card"
    >
      <div className={styles.cardWrapper}>
        <div className={styles.input}>
          <InputField
            label={t(`${tPrefix}.input_name_label`)}
            placeholder={t(`${tPrefix}.input_name_placeholder`)}
            value={keyName}
            maxLength={32}
            supportText={`${keyName.length}/32`}
            onChange={e => setKeyName(e.target.value)}
            data-testid="input-name"
          />
        </div>
        <Checkbox
          id="default"
          label={t(`${tPrefix}.checkbox_postback_label`)}
          onChange={() => setIsPostbackDerived(!isPostbackDerived)}
          data-testid="checkbox-postback"
        />
      </div>
      {!isPostbackDerived && (
        <div className={styles.selectItems}>
          <div className={styles.cardWrapper}>
            <Text color="medium" variant="overline">
              {t(`${tPrefix}.items_title`)}
            </Text>
          </div>
          {permissionsTypes.map(type => (
            <ListItemSelection
              key={type.id}
              id={type.id}
              control="radio"
              name="as-radio"
              value={type.id}
              onChange={e => setSelectedType(e.target.value)}
              defaultChecked={selectedType === type.id}
              content={(
                <ListItem.Content
                  label={type.title}
                  description={type.description}
                />
              )}
              leading={(
                <IconShape
                  size="small"
                  use={type.icon}
                  variant={selectedType === type.id ? 'brand' : 'neutral'}
                />
              )}
              data-testid={`select-${type.id}`}
            />
          ))}
        </div>
      )}
      <div className={styles.buttonWrapper}>
        <Button
          variant="primary-solid"
          disabled={buttonDisabled}
          onClick={handleSubmit}
          data-testid="button-submit"
        >
          {t(`${tPrefix}.button_submit`)}
        </Button>
      </div>
    </Box>
  )
}

KeyInfoCard.propTypes = {
  keyName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  selectedType: PropTypes.string,
  t: PropTypes.func.isRequired,
}

KeyInfoCard.defaultProps = {
  keyName: '',
  selectedType: '',
}

export default KeyInfoCard
