"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _length2 = _interopRequireDefault(require("ramda/src/length"));
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _mergeDeepWith2 = _interopRequireDefault(require("ramda/src/mergeDeepWith"));
var _unless2 = _interopRequireDefault(require("ramda/src/unless"));
var _T2 = _interopRequireDefault(require("ramda/src/T"));
var _cond2 = _interopRequireDefault(require("ramda/src/cond"));
var _subtract2 = _interopRequireDefault(require("ramda/src/subtract"));
var _defaultTo2 = _interopRequireDefault(require("ramda/src/defaultTo"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _anyPass2 = _interopRequireDefault(require("ramda/src/anyPass"));
var _when2 = _interopRequireDefault(require("ramda/src/when"));
var _flatten2 = _interopRequireDefault(require("ramda/src/flatten"));
var _mergeAll2 = _interopRequireDefault(require("ramda/src/mergeAll"));
var _objOf2 = _interopRequireDefault(require("ramda/src/objOf"));
var _apply2 = _interopRequireDefault(require("ramda/src/apply"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _find2 = _interopRequireDefault(require("ramda/src/find"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _sort2 = _interopRequireDefault(require("ramda/src/sort"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _findIndex2 = _interopRequireDefault(require("ramda/src/findIndex"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _shared = require("../shared");
var _statusOrder = _interopRequireDefault(require("./statusOrder"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var mapSourceToTableRows = (0, _applySpec2["default"])(_shared.transactionSpec);
var orderByStatusLegend = function orderByStatusLegend(order) {
  return function (transactionA, transactionB) {
    var indexStatusA = (0, _findIndex2["default"])((0, _equals2["default"])(transactionA.status), _statusOrder["default"]);
    var indexStatusB = (0, _findIndex2["default"])((0, _equals2["default"])(transactionB.status), _statusOrder["default"]);
    if (order === 'ascending') {
      return indexStatusA - indexStatusB;
    }
    return indexStatusB - indexStatusA;
  };
};
var sortByStatusLegend = function sortByStatusLegend(formattedTransactions, order) {
  return (0, _sort2["default"])(orderByStatusLegend(order), formattedTransactions);
};
var buildTableRows = function buildTableRows(query) {
  return function (transactions) {
    var formattedTransactions = (0, _map2["default"])((0, _pipe2["default"])((0, _prop2["default"])('_source'), mapSourceToTableRows), transactions);
    var status = (0, _equals2["default"])('status');
    if (query && query.sort && (0, _find2["default"])(status, query.sort.field)) {
      return sortByStatusLegend(formattedTransactions, query.sort.order);
    }
    return formattedTransactions;
  };
};
var parseStatusValue = (0, _pipe2["default"])((0, _juxt2["default"])([(0, _prop2["default"])('key'), (0, _path2["default"])(['amount', 'value'])]), (0, _apply2["default"])(_objOf2["default"]));
var buildStatusesValues = (0, _pipe2["default"])((0, _map2["default"])(parseStatusValue), _mergeAll2["default"]);
var buildDataSet = function buildDataSet(_ref) {
  var date = _ref.key_as_string,
    perStatus = _ref.per_status;
  return _extends({
    name: date
  }, buildStatusesValues(perStatus.buckets));
};
var buildChartDataSet = (0, _pipe2["default"])((0, _when2["default"])((0, _anyPass2["default"])([_isNil2["default"], _isEmpty2["default"]]), (0, _always2["default"])([])), (0, _map2["default"])(buildDataSet), _flatten2["default"]);
var defaultTotalAmount = (0, _defaultTo2["default"])(0);
var getAggregationTotal = function getAggregationTotal(amountPath) {
  return (0, _pipe2["default"])((0, _path2["default"])(amountPath), defaultTotalAmount);
};
var getPaidAmount = getAggregationTotal(['aggregations', 'total_amount', 'value']);
var getCostAmount = getAggregationTotal(['aggregations', 'total_cost', 'value']);
var getNetAmount = (0, _pipe2["default"])((0, _juxt2["default"])([getPaidAmount, getCostAmount]), (0, _apply2["default"])(_subtract2["default"]));
var ascNames = (0, _anyPass2["default"])([(0, _equals2["default"])('asc'), (0, _equals2["default"])('ascending')]);
var descNames = (0, _anyPass2["default"])([(0, _equals2["default"])('desc'), (0, _equals2["default"])('descending')]);
var formatOrder = (0, _cond2["default"])([[ascNames, (0, _always2["default"])('ascending')], [descNames, (0, _always2["default"])('descending')], [_T2["default"], (0, _always2["default"])('ascending')]]);
var buildQuery = (0, _unless2["default"])((0, _pipe2["default"])((0, _prop2["default"])('sort'), _isNil2["default"]), (0, _mergeDeepWith2["default"])(formatOrder, _2["default"], {
  sort: {
    order: null
  }
}));
var buildResult = function buildResult(query) {
  return (0, _applySpec2["default"])({
    query: (0, _always2["default"])(buildQuery(query)),
    result: {
      total: {
        count: (0, _path2["default"])(['hits', 'total']),
        payment: {
          paid_amount: getPaidAmount,
          net_amount: getNetAmount
        }
      },
      list: {
        offset: (0, _always2["default"])(query.offset),
        count: (0, _pipe2["default"])((0, _path2["default"])(['hits', 'hits']), _length2["default"]),
        rows: (0, _pipe2["default"])((0, _path2["default"])(['hits', 'hits']), buildTableRows(query))
      },
      chart: {
        dataset: (0, _pipe2["default"])((0, _path2["default"])(['aggregations', 'total_per_day', 'buckets']), buildChartDataSet)
      }
    }
  });
};
var _default = exports["default"] = buildResult;
module.exports = exports["default"];