"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var _default = exports["default"] = (0, _applySpec2["default"])({
  live: (0, _pathOr2["default"])({}, ['antifraud', 'live']),
  test: (0, _pathOr2["default"])({}, ['antifraud', 'test'])
});
module.exports = exports["default"];