import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box, IconShape, ListItem, Button,
} from '@stone-payments/jade'

import { isEmpty } from 'ramda'
import styles from './style.css'
import PermissionOptions from '../PermissionsOptions'

const tPrefix = 'pages.settings.company.add_key'

const TotalPermissionsCard = ({ onBack, onSubmit, t }) => {
  const [permission, setPermission] = useState('')
  const buttonDisabled = isEmpty(permission)

  const handleSubmit = () => {
    onSubmit(permission)
  }

  return (
    <>
      <Box
        title={t(`${tPrefix}.total_permissions_card.title`)}
        data-testid="box-total-permissions-card"
      >
        <ListItem
          content={(
            <ListItem.Content
              label={t(`${tPrefix}.total_permissions_card.item_select_title`)}
              description={t(
                `${tPrefix}.total_permissions_card.item_select_description`
              )}
            />
          )}
          leading={<IconShape size="small" use="file-folded" variant="brand" />}
        />
        <PermissionOptions
          t={t}
          name="permission"
          onChange={setPermission}
          selected={permission}
        />
      </Box>
      <div className={styles.boxActions}>
        <Box hasPadding>
          <div className={styles.buttonWrapper}>
            <Button onClick={onBack} data-testid="button-back">
              {t(`${tPrefix}.actions.back`)}
            </Button>
            <Button
              variant="primary-solid"
              disabled={buttonDisabled}
              onClick={handleSubmit}
              data-testid="button-submit"
            >
              {t(`${tPrefix}.actions.submit`)}
            </Button>
          </div>
        </Box>
      </div>
    </>
  )
}

TotalPermissionsCard.propTypes = {
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default TotalPermissionsCard
