"use strict";

function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _omit2 = _interopRequireDefault(require("ramda/src/omit"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _when2 = _interopRequireDefault(require("ramda/src/when"));
var _bluebird = _interopRequireDefault(require("bluebird"));
var _result = _interopRequireDefault(require("./result"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : String(i);
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var settleProps = function settleProps(object) {
  return _bluebird["default"].props(Object.keys(object).reduce(function (newObject, key) {
    return _extends({}, newObject, _defineProperty({}, key, _bluebird["default"].resolve(object[key]).reflect()));
  }, {}));
};
var settlePropsHasError = function settlePropsHasError(props) {
  return Object.values(props).some(function (prop) {
    return !prop.isFulfilled();
  });
};
var formatSettledProps = function formatSettledProps(props) {
  return Object.keys(props).reduce(function (aggr, propKey) {
    return _extends({}, aggr, _defineProperty({}, propKey, props[propKey].isFulfilled() ? props[propKey].value() : null));
  }, {
    hasError: settlePropsHasError(props)
  });
};
var getValidStatus = (0, _when2["default"])(_isNil2["default"], (0, _always2["default"])('available'));
var ignoredProps = ['status', 'recipientId'];
var data = function data(client) {
  return function (recipientId) {
    var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return settleProps({
      balance: client.balance.find({
        recipientId: recipientId
      }),
      pending_requests: _bluebird["default"].all([client.bulkAnticipations.find({
        recipientId: recipientId,
        status: 'pending'
      }), client.transfers.all({
        recipient_id: recipientId
      })]),
      recipient: client.recipients.find({
        id: recipientId
      }),
      withdrawal: client.transfers.limits({
        recipient_id: recipientId
      })
    }).then(formatSettledProps).then((0, _result["default"])(_extends({}, (0, _omit2["default"])(ignoredProps, query), {
      status: getValidStatus(query.status)
    })));
  };
};
var _default = exports["default"] = data;
module.exports = exports["default"];