import React, { useState, useCallback } from 'react'
import pagarme from 'pagarme'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Proptypes from 'prop-types'
import IconClose from 'emblematic-icons/svg/ClearClose32.svg'
import {
  Button,
  Modal,
  ModalActions,
  ModalTitle,
  ModalContent,
  Row,
  Col,
  FormInput,
  Spacing,
} from 'former-kit'

import { getErrorMessage } from '../../formatters/error'
import Loader from '../../components/Loader'
import { requestLogin } from '../Account/actions/actions'

import environment, {
  apiAdminUrl,
} from '../../environment'

const ChangeImpersonatedCompanyModal = ({ isOpen, onCancel, t }) => {
  const [newCompanyId, onChangeNewCompanyId] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const { company, user } = useSelector(state => state.account)
  const history = useHistory()
  const accessToken = localStorage.getItem('accessToken')

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()
    setError('')
    setLoading(true)
    try {
      const params = {
        company_id: newCompanyId,
        environment,
        impersonationKey: newCompanyId,
        jwt: accessToken,
        options: {
          baseURL: apiAdminUrl,
        },
      }

      await pagarme.client.connect(params)

      dispatch(requestLogin(params))
      history.push('/')
    } catch (err) {
      setLoading(false)
      if (getErrorMessage(err) === 'Company not found.') {
        setError('Verifique o company Id')
        return
      }
      setError(getErrorMessage(err))
    }
  }, [history, dispatch, setLoading, newCompanyId, accessToken])

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle
        title={t('pages.change_impersonated_company_modal.title')}
        onClose={onCancel}
        closeIcon={<IconClose width={16} height={16} />}
      />
      <form onSubmit={onSubmit}>
        <ModalContent>
          {loading && <Loader visible opaqueBackground />}
          <Row>
            <Col palm={12} tablet={12} desk={12} tv={12}>
              {t('pages.change_impersonated_company_modal.hello')}
              {user.name}
            </Col>
          </Row>
          <Row>
            <Spacing size="large" />
          </Row>
          <Row>
            <Col palm={12} tablet={12} desk={12} tv={12}>
              {t('pages.change_impersonated_company_modal.accessing_with')}
              <strong> {company.name}</strong>
            </Col>
            <Col palm={12} tablet={12} desk={12} tv={12}>
              {t('pages.change_impersonated_company_modal.company_id')}
              <strong>{company.id}</strong>
            </Col>
          </Row>
          <Row>
            <Spacing size="large" />
          </Row>
          <Row>
            <Col palm={8} tablet={8} desk={8} tv={8}>
              <FormInput
                placeholder="Ex: 5bv71b8d959fdac1234ebaba4"
                label={t('pages.change_impersonated_company_modal.change_company')}
                id="companyId"
                name="companyId"
                value={newCompanyId}
                onChange={v => onChangeNewCompanyId(v.target.value)}
                error={error}
              />
            </Col>
          </Row>
        </ModalContent>
        <ModalActions>
          <Button
            type="submit"
            disabled={newCompanyId === ''}
          >
            {t('pages.change_impersonated_company_modal.change_company')}
          </Button>
        </ModalActions>
      </form>
    </Modal>
  )
}

ChangeImpersonatedCompanyModal.propTypes = {
  isOpen: Proptypes.bool.isRequired,
  onCancel: Proptypes.func.isRequired,
  t: Proptypes.func.isRequired,
}

export default ChangeImpersonatedCompanyModal
