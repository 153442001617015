"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _isBusinessDay = _interopRequireDefault(require("./isBusinessDay"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var nextBusinessDay = function nextBusinessDay() {
  var calendar = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var date = arguments.length > 1 ? arguments[1] : undefined;
  date.add(1, 'days');
  while (!(0, _isBusinessDay["default"])(calendar, date)) {
    date.add(1, 'days');
  }
  return date;
};
var _default = exports["default"] = nextBusinessDay;
module.exports = exports["default"];