"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var _default = exports["default"] = (0, _applySpec2["default"])({
  logo: (0, _pathOr2["default"])('', ['branding', 'logo']),
  primaryColor: (0, _pathOr2["default"])('', ['branding', 'primary_color']),
  useOnPaymentLink: (0, _pathOr2["default"])(false, ['branding', 'use_on_payment_link'])
});
module.exports = exports["default"];