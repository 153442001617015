"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _length2 = _interopRequireDefault(require("ramda/src/length"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _unless2 = _interopRequireDefault(require("ramda/src/unless"));
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var stringToMoment = (0, _unless2["default"])(_isNil2["default"], _moment["default"]);
var plansSpec = {
  amount: (0, _prop2["default"])('amount'),
  charges: (0, _prop2["default"])('charges'),
  color: (0, _prop2["default"])('color'),
  date_created: (0, _pipe2["default"])((0, _prop2["default"])('date_created'), stringToMoment),
  days: (0, _prop2["default"])('days'),
  id: (0, _prop2["default"])('id'),
  installments: (0, _prop2["default"])('installments'),
  invoice_reminder: (0, _prop2["default"])('invoice_reminder'),
  name: (0, _prop2["default"])('name'),
  payment_deadline_charges_interval: (0, _prop2["default"])('payment_deadline_charges_interval'),
  payment_methods: (0, _prop2["default"])('payment_methods'),
  trial_days: (0, _prop2["default"])('trial_days')
};
var mapSourceToTableRows = (0, _applySpec2["default"])(plansSpec);
var buildTableRows = function buildTableRows() {
  return function (plans) {
    var formatted = (0, _map2["default"])((0, _pipe2["default"])((0, _prop2["default"])('_source'), mapSourceToTableRows), plans);
    return formatted;
  };
};
var buildResult = function buildResult(query) {
  return (0, _applySpec2["default"])({
    offset: (0, _always2["default"])(query.offset),
    count: (0, _pipe2["default"])((0, _path2["default"])(['hits', 'hits']), _length2["default"]),
    rows: (0, _pipe2["default"])((0, _path2["default"])(['hits', 'hits']), buildTableRows(query)),
    total: (0, _path2["default"])(['hits', 'total'])
  });
};
var _default = exports["default"] = buildResult;
module.exports = exports["default"];