"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _query = _interopRequireDefault(require("../search/query"));
var _result = _interopRequireDefault(require("./result"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var exportData = function exportData(client) {
  return function (clientFilter, exportType, columns, limiter) {
    var filter = clientFilter || {};
    var query = (0, _query["default"])(filter);
    return client.search({
      type: 'subscription',
      query: JSON.stringify(query)
    }).then((0, _result["default"])(exportType, columns, limiter));
  };
};
var _default = exports["default"] = exportData;
module.exports = exports["default"];