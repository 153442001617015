"use strict";

function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _multiply2 = _interopRequireDefault(require("ramda/src/multiply"));
var _apply2 = _interopRequireDefault(require("ramda/src/apply"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _dec2 = _interopRequireDefault(require("ramda/src/dec"));
var _props2 = _interopRequireDefault(require("ramda/src/props"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _defaultTo2 = _interopRequireDefault(require("ramda/src/defaultTo"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _is2 = _interopRequireDefault(require("ramda/src/is"));
var _allPass2 = _interopRequireDefault(require("ramda/src/allPass"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _propSatisfies2 = _interopRequireDefault(require("ramda/src/propSatisfies"));
var _T2 = _interopRequireDefault(require("ramda/src/T"));
var _cond2 = _interopRequireDefault(require("ramda/src/cond"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _anyPass2 = _interopRequireDefault(require("ramda/src/anyPass"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _has2 = _interopRequireDefault(require("ramda/src/has"));
var _when2 = _interopRequireDefault(require("ramda/src/when"));
var _join2 = _interopRequireDefault(require("ramda/src/join"));
var _assoc2 = _interopRequireDefault(require("ramda/src/assoc"));
var _reduce2 = _interopRequireDefault(require("ramda/src/reduce"));
var _flatten2 = _interopRequireDefault(require("ramda/src/flatten"));
var _toPairs2 = _interopRequireDefault(require("ramda/src/toPairs"));
var _append2 = _interopRequireDefault(require("ramda/src/append"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _nth2 = _interopRequireDefault(require("ramda/src/nth"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _assocPath2 = _interopRequireDefault(require("ramda/src/assocPath"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : String(i);
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];
  return arr2;
}
var termsKeys = ['id', 'amount', 'days', 'name', 'trial_days', 'color'];
var defaultTerm = {
  terms: {}
};
var mapDashboardPropsToES = {
  created_at: 'date_created'
};
var buildFilterTerm = function buildFilterTerm(acc, value) {
  var key = (0, _head2["default"])(value);
  var buildTermsValues = (0, _assocPath2["default"])(['terms', key], _2["default"], defaultTerm);
  var buildTerm = (0, _pipe2["default"])((0, _nth2["default"])(1), (0, _ifElse2["default"])(_isEmpty2["default"], (0, _always2["default"])([]), buildTermsValues), (0, _append2["default"])(_2["default"], acc));
  return buildTerm(value);
};
var parseFilters = (0, _pipe2["default"])(_toPairs2["default"], (0, _reduce2["default"])(buildFilterTerm, []), _flatten2["default"]);
var parseSearch = function parseSearch(keys) {
  return function (search) {
    if (!search) {
      return [];
    }
    var terms = (0, _reduce2["default"])(function (acc, key) {
      var arrayNumbers = ['id', 'amount', 'days', 'trial_days'];
      if (arrayNumbers.includes(key)) {
        var value = Number(search);
        if (!Number.isNaN(value)) {
          return [].concat(_toConsumableArray(acc), [{
            term: _defineProperty({}, key, value)
          }]);
        }
        return acc;
      }
      return [].concat(_toConsumableArray(acc), [{
        query: {
          match_phrase: _defineProperty({}, key, search)
        }
      }]);
    }, [], keys);
    return (0, _assoc2["default"])('or', terms, {});
  };
};
var buildSortField = function buildSortField(_ref) {
  var field = _ref.field,
    order = _ref.order;
  var formatField = (0, _pipe2["default"])((0, _join2["default"])('.'), (0, _when2["default"])((0, _has2["default"])(_2["default"], mapDashboardPropsToES), (0, _prop2["default"])(_2["default"], mapDashboardPropsToES)));
  var ascNames = (0, _anyPass2["default"])([(0, _equals2["default"])('asc'), (0, _equals2["default"])('ascending')]);
  var descNames = (0, _anyPass2["default"])([(0, _equals2["default"])('desc'), (0, _equals2["default"])('descending')]);
  var formatOrder = (0, _cond2["default"])([[ascNames, (0, _always2["default"])('asc')], [descNames, (0, _always2["default"])('desc')], [_T2["default"], (0, _always2["default"])('asc')]]);
  return {
    fieldName: formatField(field),
    order: formatOrder(order)
  };
};
var buildSortItems = function buildSortItems(_ref2) {
  var fieldName = _ref2.fieldName,
    order = _ref2.order;
  return [_defineProperty({}, fieldName, {
    order: order
  })];
};
var areSortPropsEmpty = (0, _anyPass2["default"])([(0, _propSatisfies2["default"])(_isNil2["default"], 'order'), (0, _propSatisfies2["default"])(_isNil2["default"], 'field')]);
var buildSort = (0, _pipe2["default"])((0, _prop2["default"])('sort'), (0, _ifElse2["default"])((0, _anyPass2["default"])([_isNil2["default"], _isEmpty2["default"], areSortPropsEmpty]), (0, _always2["default"])({}), (0, _pipe2["default"])(buildSortField, buildSortItems)));
var buildCondition = function buildCondition(propName, parseFunction) {
  var additionalCondition = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _T2["default"];
  return (0, _ifElse2["default"])((0, _allPass2["default"])([(0, _has2["default"])(propName), (0, _pipe2["default"])((0, _prop2["default"])(propName), additionalCondition)]), (0, _pipe2["default"])((0, _prop2["default"])(propName), parseFunction), (0, _always2["default"])([]));
};
var buildFilters = buildCondition('filters', parseFilters);
var buildSearch = buildCondition('search', parseSearch(termsKeys), (0, _allPass2["default"])([(0, _is2["default"])(String), (0, _complement2["default"])(_isEmpty2["default"])]));
var buildAndCondition = (0, _pipe2["default"])((0, _juxt2["default"])([buildFilters, buildSearch]), _flatten2["default"]);
var defaultFrom = (0, _defaultTo2["default"])(1);
var defaultSize = (0, _defaultTo2["default"])(15);
var defaultFiltered = {
  query: {
    match_all: {}
  }
};
var buildFiltered = (0, _pipe2["default"])(buildAndCondition, (0, _ifElse2["default"])(_isEmpty2["default"], (0, _always2["default"])(defaultFiltered), (0, _assocPath2["default"])(['filter', 'and'], _2["default"], defaultFiltered)));
var buildQuery = (0, _applySpec2["default"])({
  from: (0, _pipe2["default"])((0, _props2["default"])(['offset', 'count']), (0, _juxt2["default"])([(0, _pipe2["default"])(_head2["default"], (0, _when2["default"])(_isNil2["default"], defaultFrom), _dec2["default"]), (0, _pipe2["default"])(_last2["default"], (0, _when2["default"])(_isNil2["default"], defaultSize))]), (0, _apply2["default"])(_multiply2["default"])),
  size: (0, _pipe2["default"])((0, _prop2["default"])('count'), defaultSize),
  sort: buildSort,
  query: {
    filtered: buildFiltered
  }
});
var _default = exports["default"] = buildQuery;
module.exports = exports["default"];