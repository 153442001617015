import SearchPaymentLinks from "./search";
import ExportPaymentLinks from "./export";
import DetailsPaymentLinks from "./details";
import ShopCreatePaymentLinks from "./shopCreate";
export const baseUrl = "/company/:company_id/payment-links";
export default ((axiosConfig, options) => ({
  export: ExportPaymentLinks(axiosConfig, options),
  search: SearchPaymentLinks(axiosConfig, options),
  details: DetailsPaymentLinks(axiosConfig, options),
  shopCreate: ShopCreatePaymentLinks(axiosConfig, options)
}));