const getKycStep = (statusReason, skipOnboarding) => {
  const stepsByStatusReason = {
    additional_documents_required: 'qrcode',
    fully_denied: 'denied',
    in_analysis: 'analysis',
    ok: skipOnboarding ? 'conclusion' : 'customization',
    rejected_by_risk: 'denied',
    waiting_analysis: 'analysis',
  }

  return stepsByStatusReason[statusReason]
}

export default getKycStep
