"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _recipientBuilders = require("./recipientBuilders");
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var AddRecipient = function AddRecipient(client) {
  return function (data, options) {
    var recipient = (0, _recipientBuilders.formatToRecipient)(data, options);
    return client.recipients.create(_extends({}, recipient, {
      otp: data.otp
    }));
  };
};
var _default = exports["default"] = AddRecipient;
module.exports = exports["default"];