"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _uncurryN2 = _interopRequireDefault(require("ramda/src/uncurryN"));
var _curry2 = _interopRequireDefault(require("ramda/src/curry"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _payables = _interopRequireDefault(require("./payables"));
var _operations = _interopRequireDefault(require("./operations"));
var _excluded = ["dates"],
  _excluded2 = ["dates"];
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}
var buildBaseQuery = function buildBaseQuery(_ref) {
  var count = _ref.count,
    page = _ref.page,
    recipientId = _ref.recipientId;
  return {
    count: count,
    page: page,
    recipient_id: recipientId
  };
};
var buildOperationsQuery = function buildOperationsQuery(_ref2) {
  var _ref2$dates = _ref2.dates,
    end = _ref2$dates.end,
    start = _ref2$dates.start,
    query = _objectWithoutProperties(_ref2, _excluded);
  return _extends({}, buildBaseQuery(query), {
    end_date: (0, _momentTimezone["default"])(end).endOf('day').valueOf(),
    start_date: (0, _momentTimezone["default"])(start).startOf('day').valueOf()
  });
};
var buildPayablesQuery = function buildPayablesQuery(_ref3) {
  var _ref3$dates = _ref3.dates,
    end = _ref3$dates.end,
    start = _ref3$dates.start,
    query = _objectWithoutProperties(_ref3, _excluded2);
  return _extends({}, buildBaseQuery(query), {
    payment_date: [">=".concat((0, _momentTimezone["default"])(start).startOf('day').valueOf()), "<=".concat((0, _momentTimezone["default"])(end).endOf('day').valueOf())],
    status: ['waiting_funds', 'prepaid']
  });
};
var buildPayablesRequest = (0, _curry2["default"])(function (client, query) {
  var payablesQuery = buildPayablesQuery(query);
  return client.payables.all(payablesQuery).then(_payables["default"]);
});
var buildOperationsRequest = (0, _curry2["default"])(function (client, query) {
  var operationsQuery = buildOperationsQuery(query);
  return client.balanceOperations.find(operationsQuery).then(_operations["default"]);
});
var buildRequestPromise = (0, _uncurryN2["default"])(2, function (client) {
  return (0, _ifElse2["default"])((0, _propEq2["default"])('timeframe', 'future'), buildPayablesRequest(client), buildOperationsRequest(client));
});
var _default = exports["default"] = buildRequestPromise;
module.exports = exports["default"];