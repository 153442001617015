"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "getChargebackReason", {
  enumerable: true,
  get: function get() {
    return _getChargebackReason["default"];
  }
});
Object.defineProperty(exports, "getFeatures", {
  enumerable: true,
  get: function get() {
    return _getFeatures["default"];
  }
});
var _getFeatures = _interopRequireDefault(require("./getFeatures"));
var _getChargebackReason = _interopRequireDefault(require("./getChargebackReason"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var ganon = function ganon() {
  return {
    getFeatures: _getFeatures["default"],
    getChargebackReason: _getChargebackReason["default"]
  };
};
var _default = exports["default"] = ganon;