"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _uncurryN2 = _interopRequireDefault(require("ramda/src/uncurryN"));
var _curry2 = _interopRequireDefault(require("ramda/src/curry"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _when2 = _interopRequireDefault(require("ramda/src/when"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _payables = _interopRequireDefault(require("./payables"));
var _operations = _interopRequireDefault(require("./operations"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var buildBaseQuery = function buildBaseQuery(_ref) {
  var _ref$dates = _ref.dates,
    end = _ref$dates.end,
    start = _ref$dates.start,
    recipientId = _ref.recipientId;
  return {
    recipient_id: recipientId,
    end_date: (0, _momentTimezone["default"])(end).endOf('day').valueOf(),
    start_date: (0, _momentTimezone["default"])(start).startOf('day').valueOf()
  };
};
var getValidStatus = (0, _when2["default"])(_isNil2["default"], (0, _always2["default"])('available'));
var buildOperationsQuery = function buildOperationsQuery(query) {
  return _extends({}, buildBaseQuery(query), {
    status: getValidStatus(query.status)
  });
};
var buildPayablesQuery = function buildPayablesQuery(query) {
  return _extends({}, buildBaseQuery(query), {
    status: query.onlyPaids ? ['paid'] : ['waiting_funds', 'prepaid', 'paid']
  });
};
var buildPayablesRequest = (0, _curry2["default"])(function (client, query) {
  var payablesQuery = buildPayablesQuery(query);
  return client.payables.days(payablesQuery).then(function (data) {
    return (0, _payables["default"])(data, query.onlyPaids);
  });
});
var buildOperationsRequest = (0, _curry2["default"])(function (client, query) {
  var operationsQuery = buildOperationsQuery(query);
  return client.balanceOperations.days(operationsQuery).then(_operations["default"]);
});
var buildRequestPromise = (0, _uncurryN2["default"])(2, function (client) {
  return (0, _ifElse2["default"])((0, _propEq2["default"])('timeframe', 'future'), buildPayablesRequest(client), buildOperationsRequest(client));
});
var _default = exports["default"] = buildRequestPromise;
module.exports = exports["default"];