"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _negate2 = _interopRequireDefault(require("ramda/src/negate"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _sum2 = _interopRequireDefault(require("ramda/src/sum"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var formatDateWithMoment = function formatDateWithMoment(date) {
  return (0, _moment["default"])(date).format('DD/MM/YYYY HH:mm');
};
var formatDate = (0, _ifElse2["default"])(_isNil2["default"], (0, _always2["default"])(null), formatDateWithMoment);
var calculateAnticipationAmount = (0, _pipe2["default"])((0, _juxt2["default"])([(0, _prop2["default"])('amount'), (0, _pipe2["default"])((0, _prop2["default"])('anticipation_fee'), _negate2["default"]), (0, _pipe2["default"])((0, _prop2["default"])('fee'), _negate2["default"])]), _sum2["default"]);
var bulkAnticipations = {
  amount: calculateAnticipationAmount,
  date_created: (0, _pipe2["default"])((0, _prop2["default"])('date_created'), formatDate),
  id: (0, _prop2["default"])('id'),
  payment_date: (0, _pipe2["default"])((0, _prop2["default"])('payment_date'), formatDate),
  status: (0, _prop2["default"])('status')
};
var buildResult = (0, _map2["default"])((0, _applySpec2["default"])(bulkAnticipations));
var _default = exports["default"] = buildResult;
module.exports = exports["default"];