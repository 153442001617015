import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { profileUrl } from '../../../../environment'
import Header from './components/Header'
import Loader from '../../../../components/Loader'
import styles from './style.css'
import OldKeysPage from './oldKeysPage'

const CheckApiOldKeys = ({ onDeleteKey, outdatedKeys }) => {
  const [company, setCompany] = useState(null)
  const [loading, setLoading] = useState(true)
  const {
    client,
    company: { capabilities, type },
    defaultRecipient,
  } = useSelector(state => state.account)

  useEffect(() => {
    const fetchCompany = async () => {
      setLoading(true)

      const currentCompany = await client.company.info()
      setCompany(currentCompany)
      setLoading(false)
    }
    fetchCompany()
  }, [capabilities, client, defaultRecipient, type])

  const handleClose = () => {
    window.location.href = profileUrl
  }

  if (loading) {
    return <Loader visible />
  }

  return (
    <div className={styles.pageWrapper}>
      <Header onClose={handleClose} />
      <div>
        <OldKeysPage
          company={company}
          outdatedKeys={outdatedKeys}
          onDeleteKey={onDeleteKey}
        />
      </div>
    </div>
  )
}

CheckApiOldKeys.propTypes = {
  onDeleteKey: PropTypes.func.isRequired,
  outdatedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default (CheckApiOldKeys)
