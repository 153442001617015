"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var getAllUsers = function getAllUsers(client) {
  return function () {
    return client.user.all();
  };
};
var _default = exports["default"] = getAllUsers;
module.exports = exports["default"];