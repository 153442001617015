import React from 'react'
import PropTypes from 'prop-types'
import { SecondaryLinearProgress } from 'former-kit'
import styles from './styles.css'

const ProgressBar = ({
  progressPercent,
}) => (
  <div
    className={styles.onboardingProgress}
  >
    <SecondaryLinearProgress
      value={progressPercent}
      max={100}
    />
  </div>
)

ProgressBar.propTypes = {
  progressPercent: PropTypes.number.isRequired,
}

export default ProgressBar
