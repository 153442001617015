"use strict";

function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _isomorphicFetch = _interopRequireDefault(require("isomorphic-fetch"));
var _qs = _interopRequireDefault(require("qs"));
var _environment = _interopRequireWildcard(require("../environment"));
function _getRequireWildcardCache(e) {
  if ("function" != typeof WeakMap) return null;
  var r = new WeakMap(),
    t = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(e) {
    return e ? t : r;
  })(e);
}
function _interopRequireWildcard(e, r) {
  if (!r && e && e.__esModule) return e;
  if (null === e || "object" != _typeof(e) && "function" != typeof e) return {
    "default": e
  };
  var t = _getRequireWildcardCache(r);
  if (t && t.has(e)) return t.get(e);
  var n = {
      __proto__: null
    },
    a = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) {
    var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
    i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
  }
  return n["default"] = e, t && t.set(e, n), n;
}
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var sendLetterOfAttorney = function sendLetterOfAttorney(client) {
  return function (body) {
    var queryString = _qs["default"].stringify(client.authentication);
    var file = body.file,
      recipientId = body.recipientId;
    var headers = {
      'X-Live': _environment["default"] === 'live' ? '1' : '0'
    };
    return (0, _isomorphicFetch["default"])("".concat(_environment.apiUrl, "recipients/").concat(recipientId, "/letter_of_attorney?").concat(queryString), {
      method: 'POST',
      body: file,
      headers: headers
    });
  };
};
var _default = exports["default"] = {
  sendLetterOfAttorney: sendLetterOfAttorney
};
module.exports = exports["default"];