import React from 'react'
import { CardContent } from 'former-kit'
import PropTypes from 'prop-types'
import EmptyStateIcon from '../../assets/EmptyStateIcon.svg'
import style from './style.css'

const EmptyCard = ({ description, title }) => (
  <CardContent>
    <div className={style.emptyState}>
      <div>
        <EmptyStateIcon />
      </div>
      <div>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  </CardContent>
)

EmptyCard.propTypes = {
  description: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
}

export default EmptyCard
