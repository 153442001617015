"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _buildRequest = _interopRequireDefault(require("./buildRequest"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _extends() {
  _extends = Object.assign ? Object.assign.bind() : function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
var total = function total(client) {
  return function (recipientId, query) {
    return (0, _buildRequest["default"])(client, _extends({
      recipientId: recipientId
    }, query));
  };
};
var _default = exports["default"] = total;
module.exports = exports["default"];