import {
  mergeMap,
} from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'
import {
  ANTICIPATION_OPTIONS_REQUEST,
  DESTROY_ANSWER,
  POST_ANSWER,
  PUT_COMPANY_ANTICIPATION,
  QUESTION_REQUEST,
  receiveOnboardingQuestion,
  failOnboardingRequest,
  successOnboardingRequest,
} from './actions'
import { fromAnticipationOptionsToQuestion } from '../../formatters/onboarding'

const loadQuestionEpic = (action$, state$) => action$
  .pipe(
    ofType(QUESTION_REQUEST),
    mergeMap(({ payload: questionId }) => {
      const state = state$.value
      const { account: { client } } = state

      return client.onboardingQuestions.find({ id: questionId })
        .then(receiveOnboardingQuestion)
        .catch(failOnboardingRequest)
    })
  )

const postAnswerEpic = (action$, state$) => action$
  .pipe(
    ofType(POST_ANSWER),
    mergeMap(({ payload }) => {
      const state = state$.value
      const { account: { client } } = state
      const { lastAnswer, ...answer } = payload

      return client.onboardingAnswers.create(answer)
        .then(() => successOnboardingRequest(payload))
        .catch(failOnboardingRequest)
    })
  )

const destroyAnswerEpic = (action$, state$) => action$
  .pipe(
    ofType(DESTROY_ANSWER),
    mergeMap(({ payload: questionId }) => {
      const state = state$.value
      const { account: { client } } = state

      return client.onboardingAnswers.destroy({ question_id: questionId })
        .then(successOnboardingRequest)
        .catch(failOnboardingRequest)
    })
  )

const anticipationOptionsEpic = (action$, state$) => action$
  .pipe(
    ofType(ANTICIPATION_OPTIONS_REQUEST),
    mergeMap(() => {
      const state = state$.value
      const { account: { client } } = state

      return client.company.anticipationOptions()
        .then(opts => receiveOnboardingQuestion(
          fromAnticipationOptionsToQuestion(opts)
        ))
        .catch(failOnboardingRequest)
    })
  )

const companyAnticipationEpic = (action$, state$) => action$
  .pipe(
    ofType(PUT_COMPANY_ANTICIPATION),
    mergeMap(({ payload }) => {
      const state = state$.value
      const { account: { client } } = state

      return client.company.updateAnticipation(payload)
        .then(successOnboardingRequest)
        .catch(failOnboardingRequest)
    })
  )

export default combineEpics(
  destroyAnswerEpic,
  loadQuestionEpic,
  postAnswerEpic,
  anticipationOptionsEpic,
  companyAnticipationEpic
)
