"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _nextBusinessDay = _interopRequireDefault(require("./nextBusinessDay"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var nextAnticipableBusinessDay = function nextAnticipableBusinessDay(calendar, cutTime, date) {
  var cutDate = date.clone().set(cutTime);
  if (date.isAfter(cutDate)) {
    return (0, _nextBusinessDay["default"])(calendar, date);
  }
  return date;
};
var _default = exports["default"] = nextAnticipableBusinessDay;
module.exports = exports["default"];