"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var createInvite = function createInvite(client) {
  return function (data) {
    return client.invites.create(data);
  };
};
var _default = exports["default"] = createInvite;
module.exports = exports["default"];