"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _create = _interopRequireDefault(require("./create"));
var _delete = _interopRequireDefault(require("./delete"));
var _updatePassword = _interopRequireDefault(require("./updatePassword"));
var _all = _interopRequireDefault(require("./all"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var _default = exports["default"] = {
  updatePassword: _updatePassword["default"],
  create: _create["default"],
  "delete": _delete["default"],
  getAllUsers: _all["default"]
};
module.exports = exports["default"];