import { useEffect, useState } from 'react'
import ApiClientSingleton from '../../utils/helpers/ApiClientSingleton'

const useGetKycStatus = ({ runOnMount = false } = {}) => {
  const [isLoading, setIsLoading] = useState(!!runOnMount)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)

  const getItems = async () => {
    setIsLoading(true)
    const apiClient = ApiClientSingleton.get()

    try {
      const { data } = await apiClient.kyc.status()

      setResult(data)
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (runOnMount) {
      getItems()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runOnMount])

  return {
    error,
    isLoading,
    request: getItems,
    result,
  }
}

export default useGetKycStatus
