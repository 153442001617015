"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _join2 = _interopRequireDefault(require("ramda/src/join"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _path2 = _interopRequireDefault(require("ramda/src/path"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _bulkAnticipationAndTransfersRequests = require("../../bulkAnticipationAndTransfersRequests");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var getWithDv = function getWithDv(propName) {
  return (0, _pipe2["default"])((0, _juxt2["default"])([(0, _path2["default"])(['bank_account', propName]), (0, _path2["default"])(['bank_account', "".concat(propName, "_dv")])]), (0, _ifElse2["default"])((0, _pipe2["default"])(_last2["default"], _isNil2["default"]), _head2["default"], (0, _join2["default"])('-')));
};
var buildRecipient = (0, _ifElse2["default"])((0, _propEq2["default"])('recipient', null), (0, _always2["default"])(null), (0, _pipe2["default"])((0, _prop2["default"])('recipient'), (0, _applySpec2["default"])({
  id: (0, _prop2["default"])('id'),
  name: (0, _path2["default"])(['bank_account', 'legal_name']),
  bank_account: {
    account: getWithDv('conta'),
    agency: getWithDv('agencia'),
    bank_code: (0, _path2["default"])(['bank_account', 'bank_code']),
    id: (0, _path2["default"])(['bank_account', 'id']),
    type: (0, _path2["default"])(['bank_account', 'type'])
  }
})));
var buildBalance = (0, _applySpec2["default"])({
  amount: (0, _pathOr2["default"])(null, ['balance', 'available', 'amount']),
  available: {
    withdrawal: (0, _pathOr2["default"])(null, ['withdrawal', 'maximum'])
  },
  outcoming: (0, _pathOr2["default"])(null, ['balance', 'waiting_funds', 'amount'])
});
var buildRequests = (0, _ifElse2["default"])((0, _propEq2["default"])('pending_requests', null), (0, _always2["default"])(null), (0, _pipe2["default"])((0, _prop2["default"])('pending_requests'), _bulkAnticipationAndTransfersRequests.concatAndSortBulkAnticipationsAndTransfers, _bulkAnticipationAndTransfersRequests.filterPendingRequest, _bulkAnticipationAndTransfersRequests.buildPendingRequest));
var buildResult = function buildResult(query) {
  return (0, _applySpec2["default"])({
    query: (0, _always2["default"])(query),
    result: {
      balance: buildBalance,
      recipient: buildRecipient,
      requests: buildRequests
    },
    hasError: (0, _prop2["default"])('hasError')
  });
};
var _default = exports["default"] = buildResult;
module.exports = exports["default"];