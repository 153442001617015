import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { compose } from 'ramda'
import { withError } from '../../../ErrorBoundary'
import CardDefault from '../../components/CardDefault'
import DeniedSVG from '../../assets/denied.svg'

import styles from './styles.css'

const enhanced = compose(
  translate(),
  withError
)

const Denied = ({
  t,
}) => {
  const tPrefix = 'pages.kyc.pages.denied'

  return (
    <div className={styles.pageWrapper}>
      <CardDefault
        title={t(`${tPrefix}.title`)}
        subTitle={t(`${tPrefix}.subtitle`)}
        icon={<DeniedSVG />}
      />
    </div>
  )
}

Denied.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(Denied)
