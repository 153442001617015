import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@stone-payments/jade'

import styles from './styles.css'
import Card from '../../../../components/Card'

const CardDefault = ({
  children, icon, subTitle, title,
}) => (
  <Card>
    <div className={styles.cardContainer}>
      <div className={styles.cardImage}>
        {icon}
      </div>
      <div className={styles.cardText}>
        <Text variant="heading-small">{title}</Text>
        <Text color="medium">{subTitle}</Text>
        {children}
      </div>
    </div>
  </Card>
)

CardDefault.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node.isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

CardDefault.defaultProps = {
  children: null,
}

export default CardDefault
