import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, InputField } from '@stone-payments/jade'

const tPrefix = 'pages.settings.company.add_key.modal_add_key'

const ModalAddKey = ({
  hasError, isLoading, isOpen, onClose, onSubmit, t,
}) => {
  const [inputType, setInputType] = useState('password')
  const [password, setPassword] = useState('password')

  const handleToggleInputType = () => {
    const newType = inputType === 'password' ? 'text' : 'password'

    setInputType(newType)
  }

  return (
    <Modal
      open={isOpen}
      toggle={onClose}
      title={t(`${tPrefix}.title`)}
      onClose={onClose}
      actions={[
        {
          'data-testid': 'button-submit',
          loading: isLoading,
          onClick: () => onSubmit(password),
          text: t(`${tPrefix}.button_submit`),
          variant: 'primary-solid',
        },
        {
          'data-testid': 'button-back',
          disabled: isLoading,
          onClick: onClose,
          text: t(`${tPrefix}.button_back`),
          variant: 'neutral',
        },
      ]}
    >
      <div>
        <InputField
          type={inputType}
          label={t(`${tPrefix}.input_label`)}
          error={hasError}
          supportText={hasError ? t(`${tPrefix}.input_error`) : ''}
          trailingItem={{
            content: inputType === 'password' ? 'eye-open' : 'eye-close',
            type: 'icon',
          }}
          onChange={e => setPassword(e.target.value)}
          onTrailingItemClick={handleToggleInputType}
          data-testid="input-password"
        />
      </div>
    </Modal>
  )
}

ModalAddKey.propTypes = {
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

ModalAddKey.defaultProps = {
  hasError: false,
  isLoading: false,
  isOpen: false,
}

export default ModalAddKey
