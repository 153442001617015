import { defaultTo } from 'ramda'

const {
  CLIENT_ID,
  CLIENT_ID_PRD,
  REACT_APP_AMPLITUDE_API_KEY,
  REACT_APP_API_ADMIN_URL,
  REACT_APP_API_ENVIRONMENT,
  REACT_APP_BALANCE_MFE_URL,
  REACT_APP_ID_SIGN_IN_URL,
  REACT_APP_IMPERSONATE,
  REACT_APP_KYC_MFE_URL,
  REACT_APP_LIVE_URL,
  REACT_APP_RECAPTCHA_KEY,
  REACT_APP_SETTLEMENTS_MFE_URL,
  REACT_APP_TEST_URL,
  REACT_APP_TRANSFERS_MFE_URL,
  TENANT_ID,
} = process.env

const getLiveUrl = defaultTo('https://dashboard-stg.pagar.me/latest/index.html')
const getTestUrl = defaultTo('https://dashboard-sandbox-stg.pagar.me/latest/index.html')
const getRecaptchaKey = defaultTo('6LeiGq0ZAAAAAHwTqiKldmUBdmgH8qvXAv6-ivdF')
const getApiAdminUrl = defaultTo('https://hodor.network.pagarme.net/api-admin')
const getCepApiUrl = () => 'https://brasilapi.com.br/api/cep/v2'
const getBanksApiUrl = () => 'https://brasilapi.com.br/api/banks/v1'
const getProfileUrl = () => 'https://profile.pagar.me'
const getApiBffUrl = () => 'https://api.mundipagg.com/'
const getBalanceMFEUrl = defaultTo('https://microfrontend.pagar.me/balance/versions/v0.126.2')
const getSettlementsMFEUrl = defaultTo('https://microfrontend.pagar.me/settlements/versions/v0.126.2')
const getTransfersMFEUrl = defaultTo('https://microfrontend.pagar.me/settlements/versions/v0.141.0')
const getKycMFEUrl = defaultTo('')
const getIdSignInUrl = defaultTo('https://id.pagar.me/signin')
const getClientId = defaultTo('0274da01-d84a-45b4-9572-6989a504af58')
const getClientIdPrd = defaultTo('607324d7-344b-4cc8-8d4a-d037d4c180e6')
const getTenantId = defaultTo('1236ea7e-8bbc-43a5-a5ee-189a1954e314')
const getAmplitudeKey = defaultTo('6b7a94ea0ee04587c94bef246ea605d3')

const getPublicImgUrl = () => {
  const { origin, pathname } = window.location

  if (pathname.includes('index.html')) {
    const [path] = pathname.split('index.html')
    return `${origin}${path}img`
  }

  return `${origin}/img`
}

const env = REACT_APP_API_ENVIRONMENT === 'live'
  ? 'live'
  : 'test'
const liveUrl = getLiveUrl(REACT_APP_LIVE_URL)
const testUrl = getTestUrl(REACT_APP_TEST_URL)
const recaptchaKey = getRecaptchaKey(REACT_APP_RECAPTCHA_KEY)
const impersonate = REACT_APP_IMPERSONATE === 'true'
const apiAdminUrl = getApiAdminUrl(REACT_APP_API_ADMIN_URL)
const publicImgUrl = getPublicImgUrl()
const cepApiUrl = getCepApiUrl()
const banksApiUrl = getBanksApiUrl()
const profileUrl = getProfileUrl()
const apiBffUrl = getApiBffUrl()
const balanceMFEUrl = getBalanceMFEUrl(REACT_APP_BALANCE_MFE_URL)
const settlementsMFEUrl = getSettlementsMFEUrl(REACT_APP_SETTLEMENTS_MFE_URL)
const transfersMFEUrl = getTransfersMFEUrl(REACT_APP_TRANSFERS_MFE_URL)
const kycMFEUrl = getKycMFEUrl(REACT_APP_KYC_MFE_URL)
const idSignInUrl = getIdSignInUrl(REACT_APP_ID_SIGN_IN_URL)
const chargebackUrl = 'https://contestacao.risco.pagar.me/disputas/'
const clientId = getClientId(CLIENT_ID)
const clientIdPrd = getClientIdPrd(CLIENT_ID_PRD)
const tenantId = getTenantId(TENANT_ID)
const amplitudeKey = getAmplitudeKey(REACT_APP_AMPLITUDE_API_KEY)

const maxNumOfItemsAllowedToBeExported = 10000

export {
  liveUrl,
  testUrl,
  recaptchaKey,
  impersonate,
  apiAdminUrl,
  publicImgUrl,
  cepApiUrl,
  banksApiUrl,
  profileUrl,
  balanceMFEUrl,
  settlementsMFEUrl,
  transfersMFEUrl,
  kycMFEUrl,
  idSignInUrl,
  apiBffUrl,
  maxNumOfItemsAllowedToBeExported,
  chargebackUrl,
  clientId,
  clientIdPrd,
  tenantId,
  amplitudeKey,
}

export default env
