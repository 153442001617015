"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _environment = _interopRequireDefault(require("../../../environment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var getKeyByPath = function getKeyByPath(path) {
  return function (env) {
    return (0, _pathOr2["default"])('', [path, env]);
  };
};
var encryptionKey = getKeyByPath('encryption_key');
var getEncryptionKey = function getEncryptionKey(company) {
  return {
    title: _environment["default"],
    key: encryptionKey(_environment["default"])(company)
  };
};
var _default = exports["default"] = getEncryptionKey;
module.exports = exports["default"];