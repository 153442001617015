import React, {
  createContext, useContext, useEffect, useState,
} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useGetKycStatus from '../../../hooks/useGetKycStatus'
import Loader from '../../../components/Loader'
import getKycStatus from '../helpers/getKycStatus'
import getKycStep from '../helpers/getKycStep'
import getKycLastUpdate from '../helpers/getKycLastUpdate'

const KycContext = createContext()

function useKyc () {
  return useContext(KycContext)
}

const KycProvider = ({ children, skipOnboarding }) => {
  const [isFirstLoading, setIsFirstLoading] = useState(true)

  const { user } = useSelector(state => state.account)

  const {
    error, isLoading, request, result,
  } = useGetKycStatus({
    runOnMount: true,
  })

  const status = getKycStatus(result, error)
  const step = getKycStep(status?.statusReason, skipOnboarding)
  const lastUpdate = getKycLastUpdate(result)

  const isAdmin = result && result.length > 0
    ? result.find(kyc => kyc.account_manager_email === user.email)
    : true

  const providerData = {
    isLoading,
    lastUpdate,
    requestStatus: request,
    skipOnboarding,
    status: status?.status,
    statusReason: status?.statusReason,
    step: isAdmin ? step : 'unauthorized',
  }

  useEffect(() => {
    if (result && isFirstLoading) {
      setIsFirstLoading(false)
    }
  }, [isFirstLoading, result])

  if (isLoading && isFirstLoading) {
    return (
      <div data-testid="loader">
        <Loader position="absolute" visible />
      </div>
    )
  }

  return (
    <KycContext.Provider value={providerData}>{children}</KycContext.Provider>
  )
}

KycProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  skipOnboarding: PropTypes.bool,
}

KycProvider.defaultProps = {
  skipOnboarding: true,
}

export { KycContext, KycProvider, useKyc }
