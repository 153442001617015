"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _times2 = _interopRequireDefault(require("ramda/src/times"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var calculateInstallmentsAmount = function calculateInstallmentsAmount(amount, installments) {
  var amountSign = amount < 0 ? -1 : 1;
  var absAmount = Math.abs(amount);
  var installmentRoundedAmount = Math.floor(absAmount / installments);
  var installmentModuleAmount = absAmount % installments;
  if (installmentModuleAmount <= 1) {
    var _calculateInstallmentAmount = function _calculateInstallmentAmount(index) {
      return installmentRoundedAmount + (index === 0 ? installmentModuleAmount : 0);
    };
    return (0, _times2["default"])(_calculateInstallmentAmount, installments).map(function (installmentAmount) {
      return amountSign * installmentAmount;
    });
  }
  var missingCentsToCompleteDivision = installmentModuleAmount % (installments - 1);
  if (missingCentsToCompleteDivision < installments - 1) {
    missingCentsToCompleteDivision = installments - 1 - missingCentsToCompleteDivision;
  }
  var amountToAddToOtherInstallments = (missingCentsToCompleteDivision + installmentModuleAmount) / (installments - 1);
  var calculateInstallmentAmount = function calculateInstallmentAmount(index) {
    return installmentRoundedAmount + (index === 0 ? -1 * missingCentsToCompleteDivision : amountToAddToOtherInstallments);
  };
  return (0, _times2["default"])(calculateInstallmentAmount, installments).map(function (installmentAmount) {
    return amountSign * installmentAmount;
  });
};
var _default = exports["default"] = calculateInstallmentsAmount;
module.exports = exports["default"];