"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var formatMangingPartner = (0, _applySpec2["default"])({
  name: (0, _propOr2["default"])('', 'name'),
  cpf: (0, _propOr2["default"])('', 'cpf'),
  phone_number: (0, _propOr2["default"])('', 'phone_number')
});
var _default = exports["default"] = (0, _pipe2["default"])((0, _propOr2["default"])({}, 'managing_partner'), formatMangingPartner);
module.exports = exports["default"];