"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatBankAccount = exports["default"] = void 0;
var formatBankAccount = exports.formatBankAccount = function formatBankAccount(account) {
  return {
    documentNumber: account.document_number,
    name: account.legal_name,
    number: account.conta,
    number_digit: account.conta_dv,
    type: account.type,
    agency: account.agencia,
    agency_digit: account.agencia_dv || '',
    bank: account.bank_code,
    id: account.id.toString()
  };
};
var formatBankAccounts = function formatBankAccounts(foundBankAccounts) {
  var accounts = foundBankAccounts.map(formatBankAccount);
  return {
    accounts: accounts
  };
};
var _default = exports["default"] = formatBankAccounts;