"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _includes2 = _interopRequireDefault(require("ramda/src/includes"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _shared = require("../shared");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var wasSuccessfullyPaid = function wasSuccessfullyPaid(status) {
  return (0, _includes2["default"])(status, ['analyzing', 'pending_review', 'authorized', 'paid', 'pending_refund', 'refunded', 'chargedback']);
};
var canReprocess = function canReprocess(transaction) {
  var reprocessed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (transaction.status !== 'refused') {
    return false;
  }
  if (transaction.subscription_id) {
    return false;
  }
  if (transaction.payment_method !== 'credit_card') {
    return false;
  }
  if ((0, _momentTimezone["default"])(transaction.date_created).isBefore((0, _momentTimezone["default"])().subtract(5, 'days'))) {
    return false;
  }
  var metadata = (0, _shared.getMetadataProp)(transaction);
  if (typeof metadata.pagarme_original_transaction_id !== 'undefined') {
    return false;
  }
  if (reprocessed.original_transaction_id && reprocessed.original_transaction_id !== transaction.id) {
    return false;
  }
  var paidReprocessing = wasSuccessfullyPaid(reprocessed.status);
  return !paidReprocessing;
};
var isReprocessable = function isReprocessable(transaction, data) {
  var canReprocessTransaction = canReprocess(transaction, data);
  return canReprocessTransaction && transaction.status !== 'processing';
};
var _default = exports["default"] = isReprocessable;
module.exports = exports["default"];