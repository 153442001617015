"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "isBusinessDay", {
  enumerable: true,
  get: function get() {
    return _isBusinessDay["default"];
  }
});
Object.defineProperty(exports, "nextAnticipableBusinessDay", {
  enumerable: true,
  get: function get() {
    return _nextAnticipableBusinessDay["default"];
  }
});
Object.defineProperty(exports, "nextBusinessDay", {
  enumerable: true,
  get: function get() {
    return _nextBusinessDay["default"];
  }
});
Object.defineProperty(exports, "requestBusinessCalendar", {
  enumerable: true,
  get: function get() {
    return _requestBusinessCalendar["default"];
  }
});
var _isBusinessDay = _interopRequireDefault(require("./isBusinessDay"));
var _nextAnticipableBusinessDay = _interopRequireDefault(require("./nextAnticipableBusinessDay"));
var _nextBusinessDay = _interopRequireDefault(require("./nextBusinessDay"));
var _requestBusinessCalendar = _interopRequireDefault(require("./requestBusinessCalendar"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var business = function business() {
  return {
    isBusinessDay: _isBusinessDay["default"],
    nextAnticipableBusinessDay: _nextAnticipableBusinessDay["default"],
    nextBusinessDay: _nextBusinessDay["default"],
    requestBusinessCalendar: _requestBusinessCalendar["default"]
  };
};
var _default = exports["default"] = business;