/* eslint-disable sort-keys */
import CalendarIcon from 'emblematic-icons/svg/Calendar32.svg'
import FoodIcon from 'emblematic-icons/svg/Food32.svg'
import HomeIcon from 'emblematic-icons/svg/Home32.svg'
import MedalIcon from 'emblematic-icons/svg/Medal32.svg'
import PhoneIcon from 'emblematic-icons/svg/Phone32.svg'
import PlusIcon from 'emblematic-icons/svg/Plus32.svg'
import ShortsIcon from 'emblematic-icons/svg/Shorts32.svg'
import WatchIcon from 'emblematic-icons/svg/Watch32.svg'
import WrenchIcon from 'emblematic-icons/svg/Wrench32.svg'
import env from '../../environment'

const isDevelopmentStage = env !== 'live'

const defaultSettingsByQuestion = [
  {
    questionId: isDevelopmentStage ? 'oq_clgnt6r8e00000188on580f29' : 'oq_clgmed19u000001882zoliyun',
    label: 'integrationType',
    type: 'card',
  },
  {
    questionId: isDevelopmentStage ? 'oq_clgnt6r9s00010188izrednrs' : 'oq_clgmed1cj00010188au0nh1r9',
    label: 'companyStatus',
    type: 'card',
  },
  {
    notFoundText: 'pages.onboarding.not_found_segment',
    questionId: isDevelopmentStage ? 'oq_clgnt6r9z0002018872dktmcq' : 'oq_clgmed1ct00020188cpsgm0aa',
    label: 'segment',
    type: 'segments',
    images: [
      FoodIcon,
      WatchIcon,
      HomeIcon,
      PhoneIcon,
      WrenchIcon,
      ShortsIcon,
      CalendarIcon,
      MedalIcon,
      PlusIcon,
    ],
  },
  {
    placeholder: 'pages.onboarding.placeholders.platform',
    questionId: isDevelopmentStage ? 'oq_clgnt6ra5000301881s7uz1xe' : 'oq_clgmed1d700030188i0w810kz',
    label: 'platform',
    type: 'drop-down',
  },
  {
    questionId: isDevelopmentStage ? 'oq_clgnt6raj00050188l2tglwab' : 'oq_clgmed1dv00050188i9pov2lk',
    label: 'firstSale',
    type: 'card',
  },
  {
    placeholder: 'pages.onboarding.placeholders.expected_revenue',
    questionId: isDevelopmentStage ? 'oq_clgnt6rae00040188e9nmauuc' : 'oq_clgmed1dl000401887wu02d1a',
    label: 'expectedRevenue',
    type: 'card',
  },
]

export const isFinalStep = (currentQuestion) => {
  const stepLength = (defaultSettingsByQuestion.length - 1)
  const finalStep = currentQuestion === stepLength

  return finalStep
}

export default defaultSettingsByQuestion
