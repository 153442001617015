"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _groupBy2 = _interopRequireDefault(require("ramda/src/groupBy"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _values2 = _interopRequireDefault(require("ramda/src/values"));
var _identity2 = _interopRequireDefault(require("ramda/src/identity"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
var _mergeAll2 = _interopRequireDefault(require("ramda/src/mergeAll"));
var _lt2 = _interopRequireDefault(require("ramda/src/lt"));
var _filter2 = _interopRequireDefault(require("ramda/src/filter"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _negate2 = _interopRequireDefault(require("ramda/src/negate"));
var _unless2 = _interopRequireDefault(require("ramda/src/unless"));
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _pluck2 = _interopRequireDefault(require("ramda/src/pluck"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _sum2 = _interopRequireDefault(require("ramda/src/sum"));
var _has2 = _interopRequireDefault(require("ramda/src/has"));
var _sort2 = _interopRequireDefault(require("ramda/src/sort"));
var _T2 = _interopRequireDefault(require("ramda/src/T"));
var _cond2 = _interopRequireDefault(require("ramda/src/cond"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _gt2 = _interopRequireDefault(require("ramda/src/gt"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var installmentIsWaitingForFunds = (0, _either2["default"])((0, _propEq2["default"])('status', 'waiting_funds'), (0, _propEq2["default"])('status', 'prepaid'));
var handleCredit = (0, _ifElse2["default"])(installmentIsWaitingForFunds, (0, _always2["default"])('waiting_funds'), (0, _always2["default"])('paid'));
var handleChargeback = (0, _ifElse2["default"])((0, _pipe2["default"])((0, _prop2["default"])('net_amount'), (0, _gt2["default"])(_2["default"], 0)), (0, _always2["default"])('partial_chargeback'), (0, _always2["default"])('chargeback'));
var handleRefund = (0, _ifElse2["default"])((0, _pipe2["default"])((0, _prop2["default"])('net_amount'), (0, _gt2["default"])(_2["default"], 0)), (0, _always2["default"])('partial_refund'), (0, _always2["default"])('refund'));
var getStatusLegend = (0, _pipe2["default"])((0, _applySpec2["default"])({
  amount: (0, _prop2["default"])('amount'),
  net_amount: (0, _prop2["default"])('net_amount'),
  status: (0, _prop2["default"])('status'),
  type: (0, _prop2["default"])('type')
}), (0, _cond2["default"])([[(0, _propEq2["default"])('type', 'credit'), handleCredit], [(0, _propEq2["default"])('type', 'chargeback'), handleChargeback], [(0, _propEq2["default"])('type', 'refund'), handleRefund], [(0, _propEq2["default"])('type', 'chargeback_refund'), (0, _always2["default"])('chargeback_refund')], [(0, _propEq2["default"])('type', 'refund_reversal'), (0, _always2["default"])('refund_reversal')], [_T2["default"], (0, _always2["default"])(null)]]));
var sortByCreatedAt = (0, _sort2["default"])(function (left, right) {
  if ((0, _has2["default"])('created_at', right)) {
    return (0, _momentTimezone["default"])(right.created_at).diff((0, _momentTimezone["default"])(left.created_at), 'milliseconds');
  }
  return (0, _momentTimezone["default"])(right.date_created).diff((0, _momentTimezone["default"])(left.date_created), 'milliseconds');
});
var calculateFees = (0, _pipe2["default"])((0, _juxt2["default"])([(0, _pluck2["default"])('fee'), (0, _pluck2["default"])('fraud_coverage_fee'), (0, _pluck2["default"])('anticipation_fee')]), (0, _map2["default"])(_sum2["default"]), _sum2["default"], (0, _unless2["default"])((0, _equals2["default"])(0), _negate2["default"]));
var calculateOutcomingAmount = (0, _pipe2["default"])((0, _filter2["default"])((0, _pipe2["default"])((0, _prop2["default"])('amount'), (0, _gt2["default"])(_2["default"], 0))), (0, _pluck2["default"])('amount'), _sum2["default"]);
var calculateOutgoingAmount = (0, _pipe2["default"])((0, _filter2["default"])((0, _pipe2["default"])((0, _prop2["default"])('amount'), (0, _lt2["default"])(_2["default"], 0))), (0, _pluck2["default"])('amount'), _sum2["default"]);
var agreggatePayablesByInstallment = (0, _pipe2["default"])((0, _applySpec2["default"])({
  amount: (0, _pipe2["default"])((0, _filter2["default"])((0, _pipe2["default"])((0, _prop2["default"])('type'), (0, _equals2["default"])('credit'))), (0, _pluck2["default"])('amount'), _sum2["default"]),
  fees: calculateFees,
  outcoming_amount: calculateOutcomingAmount,
  outgoing_amount: calculateOutgoingAmount,
  net_amount: (0, _pipe2["default"])((0, _juxt2["default"])([calculateOutcomingAmount, calculateOutgoingAmount, calculateFees]), _sum2["default"]),
  number: (0, _pipe2["default"])(_head2["default"], (0, _prop2["default"])('number')),
  original_payment_date: (0, _pipe2["default"])(_head2["default"], (0, _prop2["default"])('original_payment_date'), (0, _unless2["default"])(_isNil2["default"], function (date) {
    return date.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  })),
  payment_date: (0, _pipe2["default"])(_head2["default"], (0, _prop2["default"])('payment_date'), (0, _unless2["default"])(_isNil2["default"], function (date) {
    return date.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  })),
  status: (0, _pipe2["default"])(_head2["default"], (0, _prop2["default"])('status')),
  type: (0, _pipe2["default"])(_head2["default"], (0, _prop2["default"])('type'))
}), (0, _juxt2["default"])([_identity2["default"], (0, _applySpec2["default"])({
  status: getStatusLegend
})]), _mergeAll2["default"]);
var groupPayablesByInstallments = (0, _pipe2["default"])((0, _map2["default"])((0, _applySpec2["default"])({
  amount: (0, _prop2["default"])('amount'),
  anticipation_fee: (0, _prop2["default"])('anticipation_fee'),
  created_at: (0, _pipe2["default"])((0, _prop2["default"])('date_created'), (0, _unless2["default"])(_isNil2["default"], _momentTimezone["default"])),
  fee: (0, _prop2["default"])('fee'),
  fraud_coverage_fee: (0, _prop2["default"])('fraud_coverage_fee'),
  number: (0, _propOr2["default"])(1, 'installment'),
  original_payment_date: (0, _pipe2["default"])((0, _prop2["default"])('original_payment_date'), (0, _unless2["default"])(_isNil2["default"], _momentTimezone["default"])),
  payment_date: (0, _pipe2["default"])((0, _prop2["default"])('payment_date'), (0, _unless2["default"])(_isNil2["default"], _momentTimezone["default"])),
  status: (0, _prop2["default"])('status'),
  type: (0, _prop2["default"])('type')
})), (0, _groupBy2["default"])((0, _prop2["default"])('number')), (0, _map2["default"])(sortByCreatedAt), _values2["default"], (0, _map2["default"])(agreggatePayablesByInstallment));
var _default = exports["default"] = groupPayablesByInstallments;
module.exports = exports["default"];