import React from 'react'
import PropTypes from 'prop-types'
import { NavigationBar } from '@stone-payments/jade'

const Header = ({ onClose, t }) => (
  <NavigationBar
    background="body"
    onNavigate={onClose}
    navigateType="close"
    label={t('pages.kyc.header.title')}
  />
)

Header.propTypes = {
  onClose: PropTypes.func,
  t: PropTypes.func.isRequired,
}

Header.defaultProps = {
  onClose: null,
}

export default Header
