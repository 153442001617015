import React from 'react'
import PropTypes from 'prop-types'
import {
  Flexbox,
  Popover,
} from 'former-kit'
import EllipsisIcon from 'emblematic-icons/svg/Ellipsis24.svg'

import styles from './style.css'

const MenuFooter = ({
  options, userEmail, userName,
}) => (
  <div className={styles.container}>
    <Flexbox className={styles.companyInformations} direction="column">
      <span className={styles.userName}>{userName}</span>
      <span
        className={styles.userEmail}
        title={userEmail}
      >{userEmail}
      </span>
    </Flexbox>
    <Popover
      content={(
        <div className={styles.footerContainer}>
          {options.map(option => (
            <button key={option.title} type="button" onClick={option.action} className={styles.popoverButton} data-testid={option.testId || ''}>
              {option.title}
            </button>
          ))}
        </div>
      )}
      placement="rightEnd"
    >
      <button className={styles.iconContainer} type="button" aria-label="more" data-testid="user-popover">
        <EllipsisIcon />
      </button>
    </Popover>

  </div>
)

MenuFooter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    action: PropTypes.func.isRequired,
    testId: PropTypes.string,
    title: PropTypes.string.isRequired,
  })),
  userEmail: PropTypes.string,
  userName: PropTypes.string,
}

MenuFooter.defaultProps = {
  options: [],
  userEmail: '',
  userName: '',
}

export default MenuFooter
