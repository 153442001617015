"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var getCompulsoryFee = function getCompulsoryFee(amount, anticipationTax, anticipationDelay, mdr, numberOfInstallments) {
  if (amount > 0) {
    if (numberOfInstallments > 1) {
      var _anticipationValue = amount * (anticipationTax / 100 * numberOfInstallments);
      var _anticipationPercentage = 100 * _anticipationValue / amount;
      return Math.round((mdr + _anticipationPercentage + Number.EPSILON) * 100) / 100;
    }
    var transactionFee = Math.abs(amount * (mdr / 100));
    var anticipationRate = Math.abs((40 - anticipationDelay) / 100);
    var anticipationValue = transactionFee * anticipationRate;
    var feeDiscountingAnticipationFee = Math.abs(transactionFee - anticipationValue);
    var mdrPercentage = 100 * feeDiscountingAnticipationFee / amount;
    var anticipationPercentage = 100 * anticipationValue / amount;
    return Math.round((mdrPercentage + anticipationPercentage + Number.EPSILON) * 100) / 100;
  }
  return 0;
};
var _default = exports["default"] = getCompulsoryFee;
module.exports = exports["default"];