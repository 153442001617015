import axios from "axios";
import { clone } from "ramda";
export default ((axiosConfig, options) => {
  return params => {
    const config = clone(axiosConfig);
    config.method = "POST";
    config.url = "https://api.mundipagg.com/shop/v2/merchants/".concat(params.merchantId, "/accounts/").concat(params.accountId, "/paymentlinks");
    config.data = params;
    return axios(config);
  };
});