"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var antifraudStatusNames = {
  high: 'alto',
  low: 'baixo',
  moderated: 'moderado',
  very_high: 'muito alto',
  very_low: 'muito baixo',
  unknown: '-'
};
var _default = exports["default"] = antifraudStatusNames;
module.exports = exports["default"];