"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _map2 = _interopRequireDefault(require("ramda/src/map"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _propOr2 = _interopRequireDefault(require("ramda/src/propOr"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var formatNotification = (0, _applySpec2["default"])({
  enabled: (0, _propOr2["default"])(false, 'enabled'),
  events: (0, _propOr2["default"])([], 'events'),
  target: (0, _propOr2["default"])('', 'target'),
  type: (0, _propOr2["default"])('', 'type')
});
var _default = exports["default"] = (0, _pipe2["default"])((0, _pathOr2["default"])([], ['notifications', 'live']), (0, _map2["default"])(formatNotification));
module.exports = exports["default"];