
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React, { Fragment, useEffect, useState } from 'react'
import { setupClient } from '@pagarme/http-client'
import { setupRouters } from '@pagarme/dash-router'
import { getAnaliticaInstance, setupAnalitica } from '@pagarme/plugins'
import env, { amplitudeKey } from '../environment'
import Loader from '../components/Loader'
import analiticaInstance from '../plugins/analitica.config'

const PlatformProvider = ({ children }) => {
  const {
    accountId,
    company,
    environment,
    impersonationEnv,
    impersonationKey,
    jwt,
    merchantId,
    sessionId,
  } = useSelector(state => state.account)

  const history = useHistory()

  const [setupedClient, setSetupedClient] = useState(false)
  const [setupedRouter, setSetupedRouter] = useState(false)
  const [setupKey, setSetupKey] = useState(0)
  const [setupedAnalitica, setSetupedAnalitica] = useState(false)

  if (!setupedRouter) {
    setupRouters({
      baseName: '#',
      history,
      useRouterAndBrowserRouter: true,
    })

    setSetupedRouter(true)
  }

  if (!setupedAnalitica) {
    const isProduction = process.env.NODE_ENV === 'production' && !impersonationKey && (environment === 'live' || env === 'live')

    setupAnalitica({
      apiKey: amplitudeKey,
      dryRun: !isProduction,
      projectName: 'Pilot',
    })

    setSetupedAnalitica(true)
  }

  const analiticaInstancePlugin = getAnaliticaInstance()

  useEffect(() => {
    if (merchantId) {
      setupClient({
        accountId,
        authentication: {
          jwt,
          session_id: sessionId,
        },
        environment: environment || impersonationEnv || env,
        merchantId,
      })
    } else {
      setupClient({
        authentication: {
          jwt,
          session_id: sessionId,
        },
        companyId: company.id,
        environment: environment || impersonationEnv || env,
        isImpersonate: !!impersonationKey,
      })
    }

    if ((merchantId || company.id) && !!setupedAnalitica) {
      analiticaInstance.updateMetadata({ user: { id: company.id } })
      analiticaInstancePlugin.updateMetadata({ user: { id: company.id } })
    }

    setSetupedClient(true)

    setSetupKey(id => id + 1)
  }, [
    merchantId,
    accountId,
    company,
    environment,
    impersonationEnv,
    impersonationKey,
    jwt,
    sessionId,
    analiticaInstancePlugin,
    setupedAnalitica,
  ])

  if ((!setupedClient && !setupRouters) || !setupedAnalitica) {
    return (
      <div data-testid="loader">
        <Loader
          position="absolute"
          visible
        />
      </div>
    )
  }

  return <Fragment key={setupKey}>{children}</Fragment>
}

PlatformProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PlatformProvider
