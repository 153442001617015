import React from 'react'
import PropTypes from 'prop-types'
import styles from './style.css'

const Account = ({
  primaryContent,
}) => (
  <div className={styles.accountWrapper}>
    {primaryContent}
  </div>
)

Account.propTypes = {
  primaryContent: PropTypes.node.isRequired,
}

export default Account
