import { Icon, Tooltip } from '@stone-payments/jade'
import { format } from 'date-fns'
import IconTrash from 'emblematic-icons/svg/Trash24.svg'
import { Button } from 'former-kit'
import { pipe, prop } from 'ramda'
import React from 'react'

import style from './style.css'

const tPrefix = 'pages.company_settings.api_data'

const renderOptions = ({ isAdmin, onClick, t }) => id => (
  <div className={style.buttonDelete}>
    {isAdmin && (
      <Button
        onClick={() => onClick(id)}
        fill="outline"
        relevance="high"
        icon={<IconTrash width={12} height={12} />}
      >
        {t(`${tPrefix}.delete_key`)}
      </Button>
    )}
  </div>
)

const renderDate = date => format(new Date(date), 'dd/MM/yyyy')

const renderLastSession = ({ last_session_at: lastSessionAt }) => (lastSessionAt ? renderDate(lastSessionAt) : '-')

const renderScope = t => (data) => {
  const { scope } = data

  const isLegacy = !scope || scope.length === 0
  const isTotal = isLegacy || !!scope.find(sc => ['Total: ', 'all'].some(word => sc.includes(word)))
  const isCustom = isLegacy || !!scope.find(sc => [
    'financial',
    'recipients',
    'transactional',
    'Financeiro',
    'Recebedores',
    'Transacional',
  ].some(word => sc.includes(word)))
  const legacyText = t('pages.company_settings.api_data.table_row_total_access')

  function getTooltipText (scopeItems) {
    const tooltips = {
      'Financeiro: Leitura e escrita': 'Financeiro: Leitura e escrita',
      'payments.u2s/all:r': 'Total: Leitura',
      'payments.u2s/all:rw': 'Total: Leitura e escrita',
      'payments.u2s/financial:r': 'Financeiro: Leitura',
      'payments.u2s/financial:rw': 'Financeiro: Leitura e escrita',
      'payments.u2s/recipients:r': 'Recebedores: Leitura',
      'payments.u2s/recipients:rw': 'Recebedores: Leitura e escrita',
      'payments.u2s/transactional:r': 'Transacional: Leitura',
      'payments.u2s/transactional:rw': 'Transacional: Leitura e escrita',
      'Recebedores: Leitura': 'Recebedores: Leitura',
      'Total: Leitura': 'Total: Leitura',
      'Total: Leitura e escrita': 'Total: Leitura e escrita',
    }

    const tooltipText = scopeItems
      .map(item => tooltips[item] || item)
      .join('\n')

    return tooltipText
  }

  return (
    <div className={style.tooltip}>
      {isTotal && t('pages.company_settings.api_data.table_row_total')}
      {isCustom && t('pages.company_settings.api_data.table_row_custom')}
      <Tooltip
        placement="bottom-start"
        text={isLegacy ? legacyText : getTooltipText(scope)}
        trigger={<Icon size="small" use="circle-alert" color="brand" />}
        style={{ zIndex: 1 }}
      />
    </div>
  )
}

const getDefaultColumns = ({ isAdmin, onClick, t }) => [
  {
    accessor: ['key'],
    orderable: false,
    renderer: prop('masked_key'),
    title: t(`${tPrefix}.table_header_key`),
  },
  {
    accessor: ['name'],
    orderable: false,
    renderer: prop('name'),
    title: t(`${tPrefix}.table_header_name`),
  },
  {
    accessor: ['createdAt'],
    orderable: false,
    renderer: pipe(prop('created_at'), renderDate),
    title: t(`${tPrefix}.table_header_created_at`),
  },
  {
    accessor: ['updatedAt'],
    orderable: false,
    renderer: renderLastSession,
    title: t(`${tPrefix}.table_header_updated_at`),
  },
  {
    accessor: ['scope'],
    orderable: false,
    renderer: renderScope(t),
    title: t(`${tPrefix}.table_header_permission`),
  },
  {
    accessor: ['options'],
    orderable: false,
    renderer: pipe(prop('id'), renderOptions({ isAdmin, onClick, t })),
    title: '',
  },
]

export default getDefaultColumns
