import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box, IconShape, ListItem, Button, Divider,
} from '@stone-payments/jade'

import { isEmpty } from 'ramda'
import styles from './style.css'
import ScopedPermissionToogle from '../ScopedPermissionToggle'

const tPrefix = 'pages.settings.company.add_key'
const tPrefixFull = 'pages.settings.company.add_key.custom_permissions_card'

const CustomPermissionsCard = ({ onBack, onSubmit, t }) => {
  const [scopesSelected, setScopesSelected] = useState({
    financial: false,
    recipients: false,
    transactional: false,
  })

  const [scopesPermissions, setScopesPermissions] = useState({
    financial: '',
    recipients: '',
    transactional: '',
  })

  const scopes = [
    {
      description: t(`${tPrefixFull}.scope_transactions_desc`),
      id: 'transactional',
      permission: scopesPermissions.transactional,
      selected: scopesSelected.transactional,
      title: t(`${tPrefixFull}.scope_transactions_title`),
    },
    {
      description: t(`${tPrefixFull}.scope_financial_desc`),
      id: 'financial',
      permission: scopesPermissions.financial,
      selected: scopesSelected.financial,
      title: t(`${tPrefixFull}.scope_financial_title`),
    },
    {
      description: t(`${tPrefixFull}.scope_recipients_desc`),
      id: 'recipients',
      permission: scopesPermissions.recipients,
      selected: scopesSelected.recipients,
      title: t(`${tPrefixFull}.scope_recipients_title`),
    },
  ]

  const selecteds = scopes.filter(({ selected }) => selected)

  const anySelectedHasntPermission = selecteds.filter(({
    permission,
  }) => isEmpty(permission)).length > 0

  const buttonDisabled = isEmpty(selecteds) || anySelectedHasntPermission

  const handleChangePermission = (scope, permission) => {
    setScopesPermissions(old => ({
      ...old,
      [scope]: permission,
    }))
  }

  const handleChangeSelect = (scope) => {
    const selected = !scopesSelected[scope]

    setScopesSelected(old => ({
      ...old,
      [scope]: selected,
    }))

    if (!selected) {
      handleChangePermission(scope, '')
    }
  }

  const handleSubmit = () => {
    const listPermissions = selecteds.map(
      ({ id, permission }) => `payments.u2s/${id}:${permission}`
    )

    onSubmit(listPermissions)
  }

  return (
    <>
      <Box
        className={styles.paddingBottom}
        title={t(`${tPrefixFull}.title`)}
        data-testid="box-total-permissions-card"
      >
        <ListItem
          className={styles.paddingBottom}
          content={(
            <ListItem.Content
              label={t(`${tPrefixFull}.item_select_title`)}
              description={t(`${tPrefixFull}.item_select_description`)}
            />
          )}
          leading={<IconShape size="small" use="menu-grid" variant="brand" />}
        />
        {scopes.map((scope, index) => (
          <div key={scope.id}>
            <ScopedPermissionToogle
              onChangeSelect={() => handleChangeSelect(scope.id)}
              onChangePermission={pm => handleChangePermission(scope.id, pm)}
              scope={scope}
              t={t}
            />
            {index + 1 < scopes.length && (
              <div className={styles.cardWrapper}>
                <Divider />
              </div>
            )}
          </div>
        ))}
      </Box>
      <div className={styles.boxActions}>
        <Box hasPadding>
          <div className={styles.buttonWrapper}>
            <Button onClick={onBack} data-testid="button-back">
              {t(`${tPrefix}.actions.back`)}
            </Button>
            <Button
              variant="primary-solid"
              disabled={buttonDisabled}
              onClick={handleSubmit}
              data-testid="button-submit"
            >
              {t(`${tPrefix}.actions.submit`)}
            </Button>
          </div>
        </Box>
      </div>
    </>
  )
}

CustomPermissionsCard.propTypes = {
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default CustomPermissionsCard
