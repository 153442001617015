import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { compose } from 'ramda'
import { format } from 'date-fns'
import { Icon, Button, Text } from '@stone-payments/jade'
import CardDefault from '../../components/CardDefault'
import KycValidationIcon from '../../assets/kycValidationIcon.svg'

import styles from './styles.css'
import { useKyc } from '../../context/KycContext'

const enhanced = compose(translate())

const ValidationAnalyze = ({ t }) => {
  const { isLoading, lastUpdate, requestStatus } = useKyc()
  const tPrefix = 'pages.kyc.pages.validation'

  return (
    <div className={styles.pageWrapper}>
      <CardDefault
        title={t(`${tPrefix}.title`)}
        subTitle={t(`${tPrefix}.subtitle`)}
        icon={<KycValidationIcon />}
      >
        <span className={styles.updateInformation}>
          <Icon size="medium" use="action-refresh" color="brand" />
          <Text color="brand">
            {`${t(`${tPrefix}.updated_at`)} ${format(
              lastUpdate,
              "dd/MM/yyyy 'às' HH:mm"
            )}`}
          </Text>
        </span>
      </CardDefault>
      <div className={styles.checkUpdateButton}>
        <Button
          onClick={requestStatus}
          size="large"
          icon="action-refresh"
          loading={isLoading}
        >
          {t(`${tPrefix}.button`)}
        </Button>
      </div>
    </div>
  )
}

ValidationAnalyze.propTypes = {
  t: PropTypes.func.isRequired,
}

export default enhanced(ValidationAnalyze)
