"use strict";

function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _multiply2 = _interopRequireDefault(require("ramda/src/multiply"));
var _apply2 = _interopRequireDefault(require("ramda/src/apply"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _dec2 = _interopRequireDefault(require("ramda/src/dec"));
var _props2 = _interopRequireDefault(require("ramda/src/props"));
var _defaultTo2 = _interopRequireDefault(require("ramda/src/defaultTo"));
var _anyPass2 = _interopRequireDefault(require("ramda/src/anyPass"));
var _when2 = _interopRequireDefault(require("ramda/src/when"));
var _join2 = _interopRequireDefault(require("ramda/src/join"));
var _is2 = _interopRequireDefault(require("ramda/src/is"));
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _mergeAll2 = _interopRequireDefault(require("ramda/src/mergeAll"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _isNil2 = _interopRequireDefault(require("ramda/src/isNil"));
var _propSatisfies2 = _interopRequireDefault(require("ramda/src/propSatisfies"));
var _has2 = _interopRequireDefault(require("ramda/src/has"));
var _allPass2 = _interopRequireDefault(require("ramda/src/allPass"));
var _assoc2 = _interopRequireDefault(require("ramda/src/assoc"));
var _T2 = _interopRequireDefault(require("ramda/src/T"));
var _cond2 = _interopRequireDefault(require("ramda/src/cond"));
var _concat2 = _interopRequireDefault(require("ramda/src/concat"));
var _length2 = _interopRequireDefault(require("ramda/src/length"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _replace2 = _interopRequireDefault(require("ramda/src/replace"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _reduce2 = _interopRequireDefault(require("ramda/src/reduce"));
var _flatten2 = _interopRequireDefault(require("ramda/src/flatten"));
var _toPairs2 = _interopRequireDefault(require("ramda/src/toPairs"));
var _append2 = _interopRequireDefault(require("ramda/src/append"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _isEmpty2 = _interopRequireDefault(require("ramda/src/isEmpty"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _nth2 = _interopRequireDefault(require("ramda/src/nth"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _assocPath2 = _interopRequireDefault(require("ramda/src/assocPath"));
var _head2 = _interopRequireDefault(require("ramda/src/head"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : String(i);
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
var termsKeys = ['id', 'customer.documents.number', 'customer.document_number', 'customer.email', 'customer.name', 'amount', 'card_last_digits', 'card_first_digits'];
var mapDashboardPropsToES = {
  'payment.paid_amount': 'paid_amount',
  'payment.cost_amount': 'cost',
  'payment.method': 'payment_method',
  created_at: 'date_created',
  updated_at: 'date_updated',
  'card.number': 'card_number',
  'card.funding_source': 'card_funding_source'
};
var defaultTerm = {
  terms: {}
};
var aggregations = {
  total_amount: {
    sum: {
      field: 'paid_amount'
    }
  },
  total_cost: {
    sum: {
      field: 'cost'
    }
  },
  total_per_day: {
    date_histogram: {
      field: 'date_created',
      interval: 'day',
      format: 'MM/dd/yyyy',
      time_zone: 'America/Sao_Paulo'
    },
    aggregations: {
      per_status: {
        terms: {
          field: 'status'
        },
        aggregations: {
          amount: {
            sum: {
              field: 'amount'
            }
          }
        }
      }
    }
  }
};
var buildFilterTerm = function buildFilterTerm(acc, value) {
  var key = (0, _head2["default"])(value);
  var buildTermsValues = (0, _assocPath2["default"])(['terms', key], _2["default"], defaultTerm);
  var buildTerm = (0, _pipe2["default"])((0, _nth2["default"])(1), (0, _ifElse2["default"])(_isEmpty2["default"], (0, _always2["default"])([]), buildTermsValues), (0, _append2["default"])(_2["default"], acc));
  return buildTerm(value);
};
var parseFilters = (0, _pipe2["default"])(_toPairs2["default"], (0, _reduce2["default"])(buildFilterTerm, []), _flatten2["default"]);
var compareDocuments = (0, _either2["default"])((0, _equals2["default"])('customer.documents.number'), (0, _equals2["default"])('customer.document_number'));
var term = function term(key, value) {
  return {
    term: _defineProperty({}, key, value)
  };
};
var query = function query(key, value) {
  return {
    query: {
      match_phrase: _defineProperty({}, key, value)
    }
  };
};
var onlyNumbers = function onlyNumbers(value) {
  return (0, _replace2["default"])(/\.|-|\//gi, '', value);
};
var isNumber = function isNumber(value) {
  return (0, _complement2["default"])((0, _always2["default"])(Number.isNaN(Number(value))));
};
var inArray = function inArray(obj) {
  return [obj];
};
var lenght4 = (0, _pipe2["default"])(_length2["default"], (0, _equals2["default"])(4));
var lenght6 = (0, _pipe2["default"])(_length2["default"], (0, _equals2["default"])(6));
var parseSearch = function parseSearch(keys) {
  return function (search) {
    if (!search) {
      return [];
    }
    var terms = (0, _reduce2["default"])(function (acc, key) {
      var concatId = (0, _ifElse2["default"])(isNumber(search), (0, _always2["default"])((0, _concat2["default"])(acc, inArray(term('id', Number(search))))), (0, _always2["default"])(acc));
      var concatAmount = (0, _ifElse2["default"])(isNumber(search), (0, _always2["default"])((0, _concat2["default"])(acc, inArray(query('amount', Number(search))))), (0, _always2["default"])(acc));
      var removeSpaces = search.replace(/\s/g, '');
      var isCardLastDigits = function isCardLastDigits() {
        return isNumber(removeSpaces)() && lenght4(removeSpaces);
      };
      var concatLastDigits = (0, _ifElse2["default"])(isCardLastDigits, (0, _always2["default"])((0, _concat2["default"])(acc, inArray(term('card_last_digits', removeSpaces)))), (0, _always2["default"])(acc));
      var isCardFirstDigits = function isCardFirstDigits() {
        return isNumber(removeSpaces)() && lenght6(removeSpaces);
      };
      var concatFirstDigits = (0, _ifElse2["default"])(isCardFirstDigits, (0, _always2["default"])((0, _concat2["default"])(acc, inArray(term('card_first_digits', removeSpaces)))), (0, _always2["default"])(acc));
      var concatDocument = (0, _always2["default"])((0, _concat2["default"])(acc, inArray(term(key, onlyNumbers(search)))));
      var concatQuery = (0, _always2["default"])((0, _concat2["default"])(acc, inArray(query(key, search))));
      return (0, _cond2["default"])([[(0, _equals2["default"])('id'), concatId], [(0, _equals2["default"])('amount'), concatAmount], [(0, _equals2["default"])('card_last_digits'), concatLastDigits], [(0, _equals2["default"])('card_first_digits'), concatFirstDigits], [compareDocuments, concatDocument], [_T2["default"], concatQuery]])(key);
    }, [], keys);
    return (0, _assoc2["default"])('or', terms, {});
  };
};
var toISOString = function toISOString(obj) {
  return obj.toISOString();
};
var buildRangeFormatter = function buildRangeFormatter(period, comparator) {
  return (0, _ifElse2["default"])((0, _allPass2["default"])([(0, _has2["default"])(period), (0, _propSatisfies2["default"])((0, _complement2["default"])(_isNil2["default"]), period)]), (0, _pipe2["default"])((0, _prop2["default"])(period), toISOString, (0, _assoc2["default"])(comparator, _2["default"], {})), (0, _always2["default"])({}));
};
var formatDates = (0, _juxt2["default"])([buildRangeFormatter('start', 'gte'), buildRangeFormatter('end', 'lte')]);
var buildRange = (0, _pipe2["default"])(formatDates, _mergeAll2["default"]);
var parseDates = (0, _ifElse2["default"])((0, _allPass2["default"])([(0, _propSatisfies2["default"])(_isNil2["default"], 'start'), (0, _propSatisfies2["default"])(_isNil2["default"], 'end')]), (0, _always2["default"])([]), (0, _applySpec2["default"])({
  range: {
    date_created: buildRange
  }
}));
var parseLastUpdate = (0, _ifElse2["default"])((0, _allPass2["default"])([(0, _propSatisfies2["default"])(_isNil2["default"], 'start'), (0, _propSatisfies2["default"])(_isNil2["default"], 'end')]), (0, _always2["default"])([]), (0, _applySpec2["default"])({
  range: {
    date_updated: buildRange
  }
}));
var buildCondition = function buildCondition(propName, parseFunction) {
  var additionalCondition = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _T2["default"];
  return (0, _ifElse2["default"])((0, _allPass2["default"])([(0, _has2["default"])(propName), (0, _pipe2["default"])((0, _prop2["default"])(propName), additionalCondition)]), (0, _pipe2["default"])((0, _prop2["default"])(propName), parseFunction), (0, _always2["default"])([]));
};
var buildFilters = buildCondition('filters', parseFilters);
var buildSearch = buildCondition('search', parseSearch(termsKeys), (0, _allPass2["default"])([(0, _is2["default"])(String), (0, _complement2["default"])(_isEmpty2["default"])]));
var buildDates = buildCondition('dates', parseDates, (0, _is2["default"])(Object));
var buildLastUpdate = buildCondition('lastUpdate', parseLastUpdate, (0, _is2["default"])(Object));
var buildAndCondition = (0, _pipe2["default"])((0, _juxt2["default"])([buildFilters, buildSearch, buildDates, buildLastUpdate]), _flatten2["default"]);
var buildSortField = function buildSortField(_ref) {
  var field = _ref.field,
    order = _ref.order;
  var formatField = (0, _pipe2["default"])((0, _join2["default"])('.'), (0, _when2["default"])((0, _has2["default"])(_2["default"], mapDashboardPropsToES), (0, _prop2["default"])(_2["default"], mapDashboardPropsToES)));
  var ascNames = (0, _anyPass2["default"])([(0, _equals2["default"])('asc'), (0, _equals2["default"])('ascending')]);
  var descNames = (0, _anyPass2["default"])([(0, _equals2["default"])('desc'), (0, _equals2["default"])('descending')]);
  var formatOrder = (0, _cond2["default"])([[ascNames, (0, _always2["default"])('asc')], [descNames, (0, _always2["default"])('desc')], [_T2["default"], (0, _always2["default"])('asc')]]);
  return {
    fieldName: formatField(field),
    order: formatOrder(order)
  };
};
var buildSortItems = function buildSortItems(_ref2) {
  var fieldName = _ref2.fieldName,
    order = _ref2.order;
  return [_defineProperty({}, fieldName, {
    order: order
  })];
};
var areSortPropsEmpty = (0, _anyPass2["default"])([(0, _propSatisfies2["default"])(_isNil2["default"], 'order'), (0, _propSatisfies2["default"])(_isNil2["default"], 'field')]);
var buildSort = (0, _pipe2["default"])((0, _prop2["default"])('sort'), (0, _ifElse2["default"])((0, _anyPass2["default"])([_isNil2["default"], _isEmpty2["default"], areSortPropsEmpty]), (0, _always2["default"])({}), (0, _pipe2["default"])(buildSortField, buildSortItems)));
var defaultFrom = (0, _defaultTo2["default"])(1);
var defaultSize = (0, _defaultTo2["default"])(15);
var defaultFiltered = {
  query: {
    match_all: {}
  }
};
var buildFiltered = (0, _pipe2["default"])(buildAndCondition, (0, _ifElse2["default"])(_isEmpty2["default"], (0, _always2["default"])(defaultFiltered), (0, _assocPath2["default"])(['filter', 'and'], _2["default"], defaultFiltered)));
var buildAggregations = (0, _pipe2["default"])((0, _prop2["default"])('exportData'), (0, _ifElse2["default"])(_isNil2["default"], (0, _always2["default"])(aggregations), (0, _always2["default"])({})));
var buildQuery = (0, _applySpec2["default"])({
  from: (0, _pipe2["default"])((0, _props2["default"])(['offset', 'count']), (0, _juxt2["default"])([(0, _pipe2["default"])(_head2["default"], (0, _when2["default"])(_isNil2["default"], defaultFrom), _dec2["default"]), (0, _pipe2["default"])(_last2["default"], (0, _when2["default"])(_isNil2["default"], defaultSize))]), (0, _apply2["default"])(_multiply2["default"])),
  size: (0, _pipe2["default"])((0, _prop2["default"])('count'), defaultSize),
  sort: buildSort,
  query: {
    filtered: buildFiltered
  },
  aggregations: buildAggregations
});
var _default = exports["default"] = buildQuery;
module.exports = exports["default"];