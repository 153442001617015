import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import useCountDown from 'react-countdown-hook'
import { translate } from 'react-i18next'
import { Alert, Button, FormInput } from 'former-kit'

import { createEvents } from '../../../utils/helpers/actionsEvents.utils'
import actionsEvents from '../../../enums/actionsEvents.enum'
import style from '../../OtpConfirmation/style.css'
import Modal from '../../Modal'

import { convertTime } from './helpers'
import STATUS_RESPONSE from './status-response.const'
import Icon from './Icon'

const GlobalOtpModal = ({ t }) => {
  const [code, setCode] = useState('')
  const [isResendCode, setResendCode] = useState(false)
  const [args, setArgs] = useState({
    loading: false,
    onConfirm: () => {},
    onResend: () => {},
    status: STATUS_RESPONSE.success,
    statusMessage: '',
  })

  const [otpTimer, { reset, start: startOtpTimer }] = useCountDown()

  const modalRef = useRef()

  const validateCode = code.replace('_', '').toString().length >= 6
  const disabledOnResend = otpTimer > 0 || args.loading
  const showAlert = isResendCode || args.status === STATUS_RESPONSE.error

  const labelOnResend = () => {
    if (otpTimer === 0) return t('components.otp.resend_code')
    return `${t('components.otp.resend_code_in')} ${convertTime(otpTimer)}`
  }

  const onOpen = ({ detail }) => {
    modalRef.current.open()
    setArgs(detail)
  }

  const onClose = () => modalRef.current.close()
  const onConfirm = () => args?.onConfirm(code)
  const onResend = () => {
    if (otpTimer !== 0) return

    args.onResend()

    setResendCode(true)

    reset()
    startOtpTimer()
  }

  useEffect(() => {
    startOtpTimer()
  }, [startOtpTimer])

  useEffect(() => (
    createEvents([
      { eventName: actionsEvents.ON_OTP_CLOSE, handleEvent: onClose },
      { eventName: actionsEvents.ON_OTP_OPEN, handleEvent: onOpen },
    ])
  ), [modalRef])

  return (
    <Modal ref={modalRef}>
      <Modal.Header
        className={style.title}
        title={t('components.otp.title')}
      />
      <Modal.Description>
        <div className={style.message}>
          {t('components.otp.message')}

          {showAlert && (
            <div className={style.alert}>
              <Alert icon={Icon(args.status)} type={args.status}>
                <span>{args.statusMessage}</span>
              </Alert>
            </div>
          )}
        </div>
        <FormInput
          label={t('components.otp.input')}
          mask="111111"
          name="otp"
          type="text"
          inputMode="numeric"
          value={code}
          onChange={(event) => { setCode(event.target.value) }}
        />
        <button
          disabled={disabledOnResend}
          onClick={onResend}
          type="button"
          className={style.resendCode}
        >
          {labelOnResend()}
        </button>
      </Modal.Description>
      <Modal.Actions>
        <div className={[style.footer, style.onBack]}>
          <Button
            disabled={!validateCode}
            onClick={onConfirm}
          >
            {t('components.otp.confirm')}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

GlobalOtpModal.propTypes = {
  t: PropTypes.func.isRequired,
}

export default translate()(GlobalOtpModal)
