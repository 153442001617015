import { propEq } from 'ramda'
import { impersonate } from '../environment'

function isAdminOrImpersonated (
  userOrPermission,
  isImpersonated = impersonate
) {
  if (typeof userOrPermission === 'object') {
    const isAdmin = propEq('permission', 'admin')

    return isAdmin(userOrPermission) || isImpersonated
  }

  const isAdmin = userOrPermission === 'admin'

  return isAdmin || isImpersonated
}

export default isAdminOrImpersonated
