"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _2 = _interopRequireDefault(require("ramda/src/__"));
var _gt2 = _interopRequireDefault(require("ramda/src/gt"));
var _prop2 = _interopRequireDefault(require("ramda/src/prop"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _both2 = _interopRequireDefault(require("ramda/src/both"));
var _anyPass2 = _interopRequireDefault(require("ramda/src/anyPass"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _F2 = _interopRequireDefault(require("ramda/src/F"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _T2 = _interopRequireDefault(require("ramda/src/T"));
var _allPass2 = _interopRequireDefault(require("ramda/src/allPass"));
var _cond2 = _interopRequireDefault(require("ramda/src/cond"));
var _either2 = _interopRequireDefault(require("ramda/src/either"));
var _propEq2 = _interopRequireDefault(require("ramda/src/propEq"));
var _complement2 = _interopRequireDefault(require("ramda/src/complement"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var propNotEq = (0, _complement2["default"])(_propEq2["default"]);
var isCreditOrDebit = (0, _either2["default"])((0, _propEq2["default"])('payment_method', 'credit_card'), (0, _propEq2["default"])('payment_method', 'debit_card'));
var isBoleto = (0, _propEq2["default"])('payment_method', 'boleto');
var isPix = (0, _propEq2["default"])('payment_method', 'pix');
var isPagarmeOrDevAcquirer = (0, _either2["default"])((0, _propEq2["default"])('acquirer_name', 'pagarme'), (0, _propEq2["default"])('acquirer_name', 'development'));
var isRedeAcquirer = (0, _propEq2["default"])('acquirer_name', 'rede');
var isRefundable = function isRefundable(transaction) {
  var now = (0, _momentTimezone["default"])();
  var checkPaymentMethodAndAcquirers = (0, _cond2["default"])([[(0, _allPass2["default"])([propNotEq('acquirer_name', 'rede'), isCreditOrDebit, propNotEq('status', 'waiting_payment')]), _T2["default"]], [(0, _allPass2["default"])([isBoleto, isPagarmeOrDevAcquirer, propNotEq('status', 'authorized')]), _T2["default"]], [(0, _allPass2["default"])([isPix, isPagarmeOrDevAcquirer, (0, _propEq2["default"])('status', 'paid')]), _T2["default"]], [(0, _allPass2["default"])([isCreditOrDebit, isRedeAcquirer, (0, _always2["default"])(now.isBefore((0, _momentTimezone["default"])(transaction.date_created).endOf('day'))), propNotEq('status', 'waiting_payment')]), _T2["default"]], [_T2["default"], _F2["default"]]]);
  var checkStatus = (0, _ifElse2["default"])((0, _anyPass2["default"])([(0, _propEq2["default"])('status', 'paid'), (0, _propEq2["default"])('status', 'authorized'), (0, _both2["default"])((0, _propEq2["default"])('status', 'waiting_payment'), (0, _pipe2["default"])((0, _prop2["default"])('paid_amount'), (0, _gt2["default"])(_2["default"], 0)))]), checkPaymentMethodAndAcquirers, _F2["default"]);
  return checkStatus(transaction);
};
var _default = exports["default"] = isRefundable;
module.exports = exports["default"];