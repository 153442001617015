"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _add = _interopRequireDefault(require("./add"));
var _anticipationLimits = _interopRequireDefault(require("./anticipationLimits"));
var _balance = _interopRequireDefault(require("./balance"));
var _account = _interopRequireDefault(require("./account"));
var _detail = require("./detail");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var _default = exports["default"] = {
  add: _add["default"],
  anticipationLimits: _anticipationLimits["default"],
  balance: _balance["default"],
  bankAccount: _account["default"],
  createNewAccount: _detail.createNewAccount,
  detail: _detail.detail,
  update: _detail.update
};
module.exports = exports["default"];