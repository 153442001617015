"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _applySpec2 = _interopRequireDefault(require("ramda/src/applySpec"));
var _always2 = _interopRequireDefault(require("ramda/src/always"));
var _ifElse2 = _interopRequireDefault(require("ramda/src/ifElse"));
var _juxt2 = _interopRequireDefault(require("ramda/src/juxt"));
var _equals2 = _interopRequireDefault(require("ramda/src/equals"));
var _last2 = _interopRequireDefault(require("ramda/src/last"));
var _multiply2 = _interopRequireDefault(require("ramda/src/multiply"));
var _divide2 = _interopRequireDefault(require("ramda/src/divide"));
var _apply2 = _interopRequireDefault(require("ramda/src/apply"));
var _pipe2 = _interopRequireDefault(require("ramda/src/pipe"));
var _pathOr2 = _interopRequireDefault(require("ramda/src/pathOr"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var total = (0, _pathOr2["default"])(0, ['aggregations', 'boleto', 'doc_count']);
var paid = (0, _pathOr2["default"])(0, ['aggregations', 'boleto', 'paid', 'doc_count']);
var getPercentage = (0, _pipe2["default"])((0, _apply2["default"])(_divide2["default"]), (0, _multiply2["default"])(100));
var lastEquals = function lastEquals(value) {
  return (0, _pipe2["default"])(_last2["default"], (0, _equals2["default"])(value));
};
var conversion = (0, _pipe2["default"])((0, _juxt2["default"])([paid, total]), (0, _ifElse2["default"])(lastEquals(0), (0, _always2["default"])(100), getPercentage), Math.round);
var buildResult = (0, _applySpec2["default"])({
  total: total,
  paid: paid,
  conversion: conversion
});
var _default = exports["default"] = buildResult;
module.exports = exports["default"];